/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text } from '@az/starc-ui';

export const UserDetails = () => {
  return (
    <View padding={6}>
      <Text> UserDetails Tab content</Text>
    </View>
  );
};
