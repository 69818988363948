/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export enum UserStatus {
  NOT_ACTIVE = `InboundTaskManager.UserStatus.NotActive`,
  ACTIVE = `InboundTaskManager.UserStatus.Active`,
  ALMOST_DONE = `InboundTaskManager.UserStatus.AlmostDone`,
}

export enum WidgetTypes {
  SHIFT_PROGRESS = 'ShiftProgress',
  PO_ARRIVAL = '72HoursPoArrival',
  Unassign_Replenishments = 'UnassignedReplenishments',
}

export enum PO_TYPES {
  REGULAR_PO = `ShiftProgress.POType.RegularPo`,
  DSD = `ShiftProgress.POType.DSD`,
  LTD = `ShiftProgress.POType.LTD`,
}

export enum TASK_TYPE_TAB {
  RECOMMENDED = `Recommended`,
  POS = `POs`,
  REPLENISHMENTS = `Replenishments`,
  INBOUND = `Inbound`,
  OUTBOUND = `Outbound`,
  INVENTORY_CONTROL = `InventoryControlTab`,
  RETURNS_RECALLS = `ReturnsRecallsTab`,
  GENERAL_TASKS = `GeneralTasksTab`,
}

export enum USER_DETAILS_TAB {
  OVERVIEW = `UserDetailsTab.Overview`,
  TRANSACTION_HISTORY = `UserDetailsTab.TransactionHistory`,
  USER_DETAILS = `UserDetailsTab.UserDetails`,
}

export const tabs = [
  {
    name: `UserDetailsTab.Overview`,
    value: `UserDetailsTab.Overview`,
    numberOfItems: 0,
    content: `UserDetailsTab.Overview`,
  },
  {
    name: `UserDetailsTab.TransactionHistory`,
    value: `UserDetailsTab.TransactionHistory`,
    numberOfItems: 0,
    content: `UserDetailsTab.TransactionHistory`,
  },
  {
    name: `UserDetailsTab.UserDetails`,
    value: `UserDetailsTab.UserDetails`,
    numberOfItems: 0,
    content: `UserDetailsTab.UserDetails`,
  },
];

export const InboundTaskTypeFilter = [
  {
    value: 'Recommended',
  },
  {
    value: 'POs',
  },
  {
    value: 'Replenishments',
  },
  {
    value: 'Outbound',
  },
  {
    value: 'InventoryControl',
  },
  {
    value: 'GeneralTasks',
  },
];

export const SHIFT_LIST = [
  {
    label: '1st-Shift',
    value: '1st-Shift',
    fullForm: '1st-Shift',
  },
  {
    label: '2nd-Shift',
    value: '2nd-Shift',
    fullForm: '2nd-Shift',
  },
  {
    label: '3rd-Shift',
    value: '3rd-Shift',
    fullForm: '3rd-Shift',
  },
  {
    label: 'Weekend shift',
    value: 'Weekend shift',
    fullForm: 'Weekend shift',
  },
  {
    label: 'Weekend 3rd-Shift',
    value: 'Weekend 3rd-Shift',
    fullForm: 'Weekend 3rd-Shift',
  },
];
