/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { PODashboardDividerRowType } from '@inbound/types/types';

const PO_DASHBOARD_ROWS = [
  {
    priority: true,
    arrivalTime: new Date('03/07/23 10:16:00'),
    vendorName: 'Ring & Pinion Service Inc.',
    poNumber: '20738877',
    trailerInId: 'c69a2a6bc85745168e213a91067f2141',
    door: '',
    location: 'Yard',
    position: 'inYard',
    carrierName: 'US Express',
    trailerNumber: '5241',
    locationType: 'Yard',
    receivingDoor: 'RC07',
    lines: 0,
    totalLines: 7,
    pieces: 0,
    totalPices: 14,
    commodity: 'OIL',
    poType: 'LTL',
    skusWithFPS: 870,
    totalSkusWithFPS: 1000,
    users: [],
    status: 'READY_FOR_SIGNATURE',
    actionDropdown: true,
  },
];

const LOCATION_TYPES = [
  {
    label: 'Yard',
    value: 'Yard',
  },
  {
    label: 'Door',
    value: 'Door',
  },
  {
    label: 'Lane',
    value: 'Lane',
  },
  {
    label: 'Aisle',
    value: 'Aisle',
  },
];

const PO_CATEGORIES = [
  {
    label: 'All POs',
    value: 'BOTH',
  },
  {
    label: 'Assigned POs',
    value: 'ASSIGNED',
  },
  {
    label: 'Unassigned POs',
    value: 'UNASSIGNED',
  },
];

const ARRIVAL_TIMES = [
  {
    label: 'Last 24 hours',
    value: 'last24Hours',
  },
  {
    label: '24-48 hours ago',
    value: '24-48HoursAgo',
  },
  {
    label: '48-72 hours ago',
    value: '48-72HoursAgo',
  },
  {
    label: '72+ hours ago',
    value: '72+HoursAgo',
  },
];

const SEARCH_PROPERTIES = {
  PO: {
    queryParam: 'po',
    search: 'search',
  },
};

const DIVIDER_LABELS: { [key: string]: PODashboardDividerRowType } = {
  '24 Hours': {
    dividerLabel: '24 Hours',
    trailerInId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '240000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
  '48 Hours': {
    dividerLabel: '48 Hours',
    trailerInId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '480000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
  '72 Hours': {
    dividerLabel: '72 Hours',
    trailerInId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '720000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
  '72+ Hours': {
    dividerLabel: '72+ Hours',
    trailerInId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '721000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
};

export {
  PO_DASHBOARD_ROWS,
  LOCATION_TYPES,
  ARRIVAL_TIMES,
  PO_CATEGORIES,
  SEARCH_PROPERTIES,
  DIVIDER_LABELS,
};
