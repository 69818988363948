/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

import { isNumeric } from '@inbound/utils/utils';
import { NOT_STARTED, Lengths } from '@inbound/constants/constants';

export const TrailerArrivalTrailerOrderSchema = z.object({
  trailerOrderKey: z.string().optional().nullable(),
  sourceOrderNbr: z
    .string()
    .min(Lengths.SOURCE_ORDER_NUMBER)
    .max(Lengths.SOURCE_ORDER_NUMBER)
    .refine((sourceOrderNbr) => isNumeric(sourceOrderNbr)),
  orderLocationTypeCd: z.string().optional().nullable(),
  orderLocationId: z.string().max(Lengths.RECEIVING_DOOR).optional().nullable(),
  commodityTypeCd: z.string().min(1),
  statusCd: z.string().default(NOT_STARTED),
  priority: z.number().optional().nullable(),
  users: z
    .array(
      z.object({
        trailerOrderUserKey: z.string().optional().nullable(),
        assignedUserId: z.string(),
        assignedUserName: z.string(),
        userStatusCd: z.string(),
        receivedLines: z
          .array(
            z.object({
              receiveOrderLineKey: z.string(),
              productId: z.string(),
              locationId: z.string(),
              locationTypeCd: z.string(),
              uomCd: z.string(),
              receivedQty: z.number(),
            })
          )
          .optional()
          .nullable(),
      })
    )
    .optional()
    .nullable(),
});

export const TrailerArrivalSchema = z.object({
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().optional(),
  trailerNbr: z.string().optional(),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string(),
  contactName: z.string().optional().default('').nullable(),
  contactPhone: z.string().optional().default('').nullable(),
  contactEmail: z.string().optional().default('').nullable(),
  trailerOrders: TrailerArrivalTrailerOrderSchema,
  priority: z.boolean().optional(),
  reason: z.string().max(350).optional(),
});

export const TrailerArrivalUpdateSchema = z.object({
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().min(1),
  trailerNbr: z.string().min(1),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string().min(1),
  contactName: z.string().optional().nullable(),
  contactPhone: z.string().optional().nullable(),
  contactEmail: z.string().optional().nullable(),
  trailerOrders: TrailerArrivalTrailerOrderSchema,
  priority: z.boolean().optional(),
});

export const TrailerArrivalRequestSchema = z.object({
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().optional(),
  trailerNbr: z.string().optional(),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string(),
  contactName: z.string().optional().nullable(),
  contactPhone: z.string().optional().nullable(),
  contactEmail: z.string().optional().nullable(),
  trailerOrders: z.array(TrailerArrivalTrailerOrderSchema),
});
