/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const ReceivedLineSchema = z.object({
  receiveOrderLineKey: z.string(),
  productId: z.string(),
  locationId: z.string(),
  locationTypeCd: z.string(),
  receivedQty: z.number(),
  uomCd: z.string().optional(),
  receivedTs: z.string().optional(),
});

export const InboundOrderLineSchema = z.object({
  productId: z.string(),
  totalOrderedQty: z.number(),
  totalReceivedQty: z.number(),
  productLocaleDescription: z.string().optional(),
  uomCd: z.string().optional(),
  statusCd: z.string().optional(),
  partNumberId: z.string().optional(),
  commodityTypeCd: z.string().optional(),
  receiveOrderLines: z.array(ReceivedLineSchema),
});

export const TrailerArrivalSchema = z.object({
  trailerArrivalKey: z.string(),
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().optional(),
  trailerNbr: z.string().optional(),
  trailerLocationTypeCd: z.string().optional(),
  trailerLocationId: z.string().optional(),
  contactName: z.string().optional().nullable(),
  contactPhone: z.string().optional().nullable(),
  contactEmail: z.string().optional().nullable(),
});

export const InboundUserSchema = z.object({
  trailerOrderUserKey: z.string(),
  assignedUserId: z.string(),
  assignedUserName: z.string().optional(),
  userStatusCd: z.string().optional(),
  receivedLines: z.array(ReceivedLineSchema).optional().nullable(),
});

export const TrailerWithoutInboundOrderSchema = z.object({
  trailerOrderKey: z.string(),
  sourceOrderNbr: z.string(),
  statusCd: z.string(),
  commodityTypeCd: z.string(),
  orderLocationId: z.string().optional(),
  totalFpsLineCount: z.number().optional(),
  priority: z.number().optional(),
  users: z.array(InboundUserSchema),
  trailerArrival: TrailerArrivalSchema,
});

export const InboundOrderListSchema = z.object({
  inboundOrder: z.object({
    sourceOrderNbr: z.string(),
    vendorId: z.string(),
    domainTypeCd: z.string(),
    orderTypeCd: z.string(),
    statusCd: z.string(),
    totalOrderedQty: z.number(),
    totalReceivedQty: z.number(),
    totalLineCount: z.number(),
    receivedLineCount: z.number(),
    totalWeight: z.number().optional(),
    weightUomCd: z.string().optional(),
    shipToOrgId: z.string().optional(),
    shipToFacilityId: z.string().optional(),
    sourceCreateTs: z.string(),
    vendorLocale: z
      .object({
        vendorId: z.string(),
        vendorName: z.string(),
      })
      .optional(),
    trailerOrders: z.array(TrailerWithoutInboundOrderSchema),
    inboundOrderLines: z.array(InboundOrderLineSchema),
  }),
});
