/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  LayoutListRowTypes,
  LocationListRowTypes,
  SubZoneListRowTypes,
  VehicleListRowTypes,
  ZoneAssociatedSubzoneListRowTypes,
  ZoneListRowTypes,
} from '@shared/components/Table/Table.types.ts';
import { Checkbox } from '@az/starc-ui';
import { t } from 'i18next';
import { ExternalLink } from '@az/starc-ui-icons';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { ThemeActionColor } from '@az/starc-ui/dist/types/global';
import {
  CONSOLIDATION_LOCATION_FIELD,
  LAYOUT_ENTITY_CODE,
  LOCATION_FIELD,
  SUBZONE_FIELD,
} from '@mdm/constants/constants.ts';
import { SelectOption } from '@mdm/types/types.ts';

export const mapZoneTableRows = (
  rows: ZoneListRowTypes[],
  onViewDetails: (row: ZoneListRowTypes) => void,
  onDeleteZone: (row: ZoneListRowTypes) => void
) => {
  return rows.map((zone) => {
    const actionMenus = [
      {
        label: t('ViewDetails'),
        clickHandler: () => onViewDetails(zone),
      },
      { label: t('Download'), clickHandler: () => ({}) },
      { label: t('Duplicate'), clickHandler: () => ({}) },
      {
        label: t('RemoveZone'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteZone(zone),
      },
    ];

    return {
      id: zone.layout.layoutDistinctName.toString(),
      cells: [
        { value: zone.layout.layoutName, sortValue: zone.layout.layoutName },
        {
          value: zone.layout.attributes
            ? zone.layout.attributes[
                `${getFormFieldIndexByKey(zone.layout.attributes, 'MAP_SEQUENCE')}`
              ]?.value
            : '-',
          sortValue: zone.layout.attributes
            ? zone.layout.attributes[
                `${getFormFieldIndexByKey(zone.layout.attributes, 'MAP_SEQUENCE')}`
              ]?.value
            : '',
        },
        { value: zone.children?.length, sortValue: zone.children?.length.toString() },
        { value: zone.layout.layoutNameDesc, sortValue: zone.layout.layoutNameDesc },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapZoneAssociatedSubzoneTableRows = (
  rows: ZoneAssociatedSubzoneListRowTypes[],
  onViewDetails: (row: ZoneAssociatedSubzoneListRowTypes) => void,
  onDeleteSubzone: (row: ZoneAssociatedSubzoneListRowTypes) => void,
  configData: {
    subzoneTypeOptions: SelectOption[];
  }
) => {
  return rows.map((subzone) => {
    const actionMenus = [
      {
        label: t('ManageSubzone'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(subzone),
      },
      { label: t('Duplicate'), clickHandler: () => ({}) },
      {
        label: t('RemoveSubzone'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteSubzone(subzone),
      },
    ];

    const locations = subzone?.children;
    let subzoneType = subzone.layout.attributes
      ? subzone.layout.attributes[
          `${getFormFieldIndexByKey(subzone.layout.attributes, SUBZONE_FIELD.SUBZONE_TYPE)}`
        ]?.value
      : '';

    if (subzoneType) {
      subzoneType = configData.subzoneTypeOptions.find(
        (option) => option.value === subzoneType
      )?.label;
    }

    return {
      id: subzone.layout.layoutDistinctName.toString(),
      cells: [
        { value: subzone.layout.layoutName, sortValue: subzone.layout.layoutName },
        { value: subzone.layout.layoutNameDesc, sortValue: subzone.layout.layoutNameDesc },
        {
          value: subzoneType ? subzoneType : '-',
          sortValue: subzoneType,
        },
        { value: locations?.length, sortValue: locations?.length.toString() },
        { value: 0, sortValue: '0' },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapSubzoneTableRows = (
  rows: SubZoneListRowTypes[],
  onViewDetails: (row: SubZoneListRowTypes) => void,
  onDeleteSubzone: (row: SubZoneListRowTypes) => void,
  configData: {
    subzoneTypeOptions: SelectOption[];
    pickMethodOptions: SelectOption[];
  }
) => {
  return rows.map((subzone) => {
    const actionMenus = [
      {
        label: t('ManageSubzone'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(subzone),
      },
      { label: t('Duplicate'), clickHandler: () => ({}) },
      {
        label: t('RemoveSubzone'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteSubzone(subzone),
      },
    ];

    let subzoneType = subzone.layout.attributes
      ? subzone.layout.attributes[
          `${getFormFieldIndexByKey(subzone.layout.attributes, SUBZONE_FIELD.SUBZONE_TYPE)}`
        ]?.value
      : '';

    if (subzoneType) {
      subzoneType = configData.subzoneTypeOptions.find(
        (option) => option.value === subzoneType
      )?.label;
    }

    let pickMethod = subzone.layout.attributes
      ? subzone.layout.attributes[
          `${getFormFieldIndexByKey(subzone.layout.attributes, SUBZONE_FIELD.PICK_METHOD)}`
        ]?.value
      : '';

    if (pickMethod) {
      pickMethod = configData.pickMethodOptions.find(
        (option) => option.value === pickMethod
      )?.label;
    }
    return {
      id: subzone.layout.layoutDistinctName,
      cells: [
        { value: subzone.layout.layoutName, sortValue: subzone.layout.layoutName },
        { value: subzone.parent?.layoutName, sortValue: subzone.parent?.layoutName },
        { value: subzone.layout.layoutNameDesc, sortValue: subzone.layout.layoutNameDesc },
        {
          value: subzoneType ? subzoneType : '-',
          sortValue: subzoneType,
        },
        {
          value: pickMethod ? pickMethod : '-',
          sortValue: pickMethod,
        },
        { value: subzone.children?.length, sortValue: subzone.children?.length.toString() },
        { value: 0, sortValue: '0' },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapSubzoneAssociatedVehicleTableRows = (rows: VehicleListRowTypes[]) => {
  return rows.map((vehicle, index: number) => {
    const actionMenus = [
      {
        label: t('ManageVehicle'),
        icon: ExternalLink,
        clickHandler: () => ({}),
      },
      {
        label: t('RemoveVehicle'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => ({}),
      },
    ];

    return {
      id: vehicle.id.toString(),
      cells: [
        { value: vehicle.vehicleId, sortValue: vehicle.vehicleId },
        { value: vehicle.type, sortValue: vehicle.type },
        { value: vehicle.description, sortValue: vehicle.description },
        {
          value: vehicle.isFullPallet ? 'Yes' : 'No',
          sortValue: vehicle.isFullPallet ? 'Yes' : 'No',
        },
        {
          value: vehicle.isPartPallet ? 'Yes' : 'No',
          sortValue: vehicle.isPartPallet ? 'Yes' : 'No',
        },
        {
          value: (
            <Checkbox.Group name="isPickingAllowed">
              <Checkbox
                name="isPickingAllowed"
                value={`isPickingAllowed:${index.toString()}`}
                checked={vehicle.isPickingAllowed}
                label={t('Table.VehicleList.PickingAllowed')}
              />
            </Checkbox.Group>
          ),
          sortValue: vehicle.isPickingAllowed ? 'Yes' : 'No',
        },
        {
          value: (
            <Checkbox.Group name="isPutAwayAllowed">
              <Checkbox
                name="isPutAwayAllowed"
                value={`isPutAwayAllowed:${index.toString()}`}
                checked={vehicle.isPutAwayAllowed}
                label={t('Table.VehicleList.PutawayAllowed')}
              />
            </Checkbox.Group>
          ),
          sortValue: vehicle.isPutAwayAllowed ? 'Yes' : 'No',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapSubzoneAssociatedLocationTableRows = (
  rows: LocationListRowTypes[],
  onViewDetails: (row: LocationListRowTypes) => void,
  onDeleteLocation: (row: LocationListRowTypes) => void,
  configData: {
    locationTypeOptions: SelectOption[];
  }
) => {
  return rows.map((location) => {
    const actionMenus = [
      {
        label: t('ManageLocation'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(location),
      },
      {
        label: t('RemoveLocation'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteLocation(location),
      },
    ];

    let locationType = location.layout.attributes
      ? location.layout.attributes[
          `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.LOCATION_TYPE)}`
        ]?.value
      : '';

    const foundLocationType = configData.locationTypeOptions.find(
      (type) => type.value === locationType
    );
    if (foundLocationType) {
      locationType = foundLocationType.label;
    }

    return {
      id: location.layout.layoutKey,
      cells: [
        { value: location.layout.layoutName, sortValue: location.layout.layoutName },
        {
          value: locationType,
          sortValue: locationType,
        },
        { value: '', sortValue: '' },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.SKU)}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.SKU)}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_UNITS
                )}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_UNITS
                )}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_RESERVED
                )}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_RESERVED
                )}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MINIMUM)}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MINIMUM)}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MAXIMUM)}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MAXIMUM)}`
              ]?.value
            : '',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapLocationTableRows = (
  rows: LocationListRowTypes[],
  onViewDetails: (row: LocationListRowTypes) => void,
  onDeleteLocation: (row: LocationListRowTypes) => void,
  configData: {
    locationTypeOptions: SelectOption[];
  }
) => {
  return rows.map((location) => {
    const actionMenus = [
      {
        label: t('ManageLocation'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(location),
      },
      {
        label: t('RemoveLocation'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteLocation(location),
      },
    ];

    let subzoneName = '';
    let zoneName = '';
    if (location.parent?.layoutDistinctName) {
      subzoneName =
        location.parent?.layoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.SUBZONE) > -1) || '';

      if (subzoneName) {
        subzoneName = subzoneName.split('=')[1];
      }
    }

    if (location.parent?.parentLayoutDistinctName) {
      zoneName =
        location.parent?.parentLayoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.ZONE) > -1) || '';

      if (zoneName) {
        zoneName = zoneName.split('=')[1];
      }
    }

    let locationType = location.layout.attributes
      ? location.layout.attributes[
          `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.LOCATION_TYPE)}`
        ]?.value
      : '';

    const foundLocationType = configData.locationTypeOptions.find(
      (type) => type.value === locationType
    );
    if (foundLocationType) {
      locationType = foundLocationType.label;
    }

    return {
      id: location.layout.layoutKey,
      cells: [
        { value: location.layout.layoutName, sortValue: location.layout.layoutName },
        {
          value: zoneName,
          sortValue: zoneName,
        },
        {
          value: subzoneName,
          sortValue: subzoneName,
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.SKU)}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.SKU)}`
              ]?.value
            : '',
        },
        {
          value: locationType,
          sortValue: locationType,
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapConsolidationLocationTableRows = (
  rows: LayoutListRowTypes[],
  onViewDetails: (row: LayoutListRowTypes) => void,
  onDeleteLocation: (row: LayoutListRowTypes) => void,
  configData: {
    locationTypeOptions: SelectOption[];
  }
) => {
  return rows.map((location) => {
    const actionMenus = [
      {
        label: t('ManageLocation'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(location),
      },
      {
        label: t('RemoveLocation'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteLocation(location),
      },
    ];

    let subzoneName = '';
    let zoneName = '';
    if (location.parent?.layoutDistinctName) {
      subzoneName =
        location.parent?.layoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.SUBZONE) > -1) || '';

      if (subzoneName) {
        subzoneName = subzoneName.split('=')[1];
      }
    }

    if (location.parent?.parentLayoutDistinctName) {
      zoneName =
        location.parent?.parentLayoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.ZONE) > -1) || '';

      if (zoneName) {
        zoneName = zoneName.split('=')[1];
      }
    }

    let locationType = location.layout.attributes
      ? location.layout.attributes[
          `${getFormFieldIndexByKey(
            location.layout.attributes,
            CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE
          )}`
        ]?.value
      : '';

    const foundLocationType = configData.locationTypeOptions.find(
      (type) => type.value === locationType
    );
    if (foundLocationType) {
      locationType = foundLocationType.label;
    }

    return {
      id: location.layout.layoutKey,
      cells: [
        { value: location.layout.layoutName, sortValue: location.layout.layoutName },
        { value: zoneName, sortValue: zoneName },
        { value: subzoneName, sortValue: subzoneName },
        {
          value: locationType || '',
          sortValue: locationType || '',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};
