/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { SearchAtomType } from '@ofm/atoms/search/searchInputAtom';

import { TASK_USER_DATA } from '@taskManagement/constants/dataConstants';
import { TaskUserDataType } from '@taskManagement/types/types';

export const useUserSearch = (searchTerm: SearchAtomType) => {
  const [invalidUser, setInvalidUser] = useState<string>('');
  const [searchData, setSearchData] = useState<TaskUserDataType[]>([]);
  const [matchSearchValue, setMatchSearchValue] = useState<TaskUserDataType[]>([]);
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);
  const [isLoadingSearchValue, setIsLoadingSearchValue] = useState<boolean>(true);

  // @todo : replace this while working with backend integration
  const UserSearchData = TASK_USER_DATA;

  useEffect(() => {
    if (UserSearchData && UserSearchData?.length > 0) {
      setSearchData(UserSearchData);
    }
  }, [UserSearchData]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingSearchValue(false); // fake the api delay
    }, 2500);
  }, []);

  useEffect(() => {
    switch (searchTerm) {
      case undefined:
        setMatchSearchValue([]);
        break;
      case '':
        setMatchSearchValue(searchData);
        setHasNoResults(false);
        break;
      default: {
        if (searchData.length) {
          const userToShow = searchData.filter(
            (userItem) =>
              userItem.id.toString().includes(searchTerm) ||
              userItem.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          if (userToShow.length) {
            setMatchSearchValue(userToShow);
            setHasNoResults(false);
          } else {
            setInvalidUser(searchTerm);
            setMatchSearchValue([]);
            setHasNoResults(true);
          }
        }
        break;
      }
    }
  }, [searchTerm, searchData]);

  return { matchSearchValue, hasNoResults, invalidUser, isLoadingSearchValue };
};
