/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import {
  Accordion,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  FormControl,
  Text,
  TextField,
  View,
} from '@az/starc-ui';
import { z } from 'zod';

import styles from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationDetail.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { defaultAttributeValues, LocationSchema } from '@mdm/schemas/locationSchema.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { getFormDefaults, getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { displayAttributeFieldValidationMessage } from '@mdm/utils/form/validationUtils';
import { useMutateLocation } from '@mdm/services/hooks/useMutateLocation.ts';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { LocationDetailForm } from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationDetailForm.tsx';
import { useGetLocationByLayoutKey } from '@mdm/services/hooks/useGetLocationByLayoutKey.tsx';
import { useDeleteLocation } from '@mdm/services/hooks/useDeleteLocation.ts';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';

export const LocationDetail = () => {
  /* State */
  const { locationId, selectedSubzoneId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(!locationId);

  /* Constants */
  type FormData = z.infer<typeof LocationSchema>;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb(locationId ? PAGE_URLS.LOCATION_CREATE : '');
  const { isLoading: isLocationUpdating, mutateLocation } = useMutateLocation();
  const { mutateDeleteLocation } = useDeleteLocation();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const hookFormMethods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: getFormDefaults(LocationSchema),
    resolver: zodResolver(LocationSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset: locationReset,
    getValues,
    watch,
    setValue,
  } = hookFormMethods;

  const locationName = watch('layoutName');

  /* Functions */

  const onSubmit = () => {
    const payload = getValues();
    if (payload.attributes) {
      payload.attributes = payload.attributes
        .filter((attribute) => attribute.value !== '')
        .map((attribute) => {
          return {
            ...attribute,
            value: attribute.value.toString(),
          };
        });
    }

    mutateLocation(
      {
        layoutKey: payload.layoutKey || '',
        layoutRequest: payload,
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries(['locations']);
            let successMessage = t('Success.Action.Location.Created');
            if (payload.layoutKey) {
              successMessage = t('Success.Action.Location.Updated', {
                locationName: payload.layoutName,
              });
            }
            handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            if (!locationId && data.layoutDistinctName) {
              navigate(PAGE_URLS.LOCATION_DETAILS(data.layoutDistinctName));
            }
          }
        },
      }
    );
  };

  const onDeleteLocation = () => {
    if (locationId) {
      const payload = getValues();
      mutateDeleteLocation(
        {
          layoutKey: payload.layoutKey || '',
        },
        {
          onSuccess: async () => {
            navigate(PAGE_URLS.LOCATION_LIST);
          },
        }
      );
    }
  };

  /* Hooks */
  const { locationData } = useGetLocationByLayoutKey(
    {
      layoutKey: locationId || '',
    },
    !!locationId
  );

  useEffect(() => {
    if (locationData && locationData.content[0]) {
      const attributes = locationData.content[0].layout.attributes || [];
      for (const defaultAttributeValue of defaultAttributeValues) {
        if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
          attributes.push(defaultAttributeValue);
        }
      }

      locationReset({
        ...locationData.content[0].layout,
        parentLayoutDistinctName: locationData.content[0].parent?.layoutDistinctName || '',
        attributes,
      });

      setIsDataLoaded(true);
    }
  }, [locationData, locationReset]);

  useEffect(() => {
    if (selectedSubzoneId) {
      setValue('parentLayoutDistinctName', selectedSubzoneId);
    }
  }, [selectedSubzoneId, setValue]);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={10} />;
  } else {
    return (
      <View className={styles['location-detail']} backgroundColor="secondary" direction="column">
        <View
          attributes={{
            'data-testid': 'location-detail-header',
          }}
        >
          <MasterTitle
            title={`${t('Location')}: ${locationName ? locationName : t('Untitled')}`}
            breadcrumbProps={breadcrumbs}
          >
            <View
              attributes={{
                'data-testid': 'location-detail-action',
              }}
              direction="row"
              justify="end"
              align="center"
              gap={4}
            >
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      variant="secondary"
                      size="large"
                      onClick={() => navigate(PAGE_URLS.LOCATION_LIST)}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('Cancel')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      size="large"
                      loading={isLocationUpdating}
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('SaveEdits')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              {locationId ? (
                <ActionMenu
                  menus={[
                    {
                      label: t('RemoveLocation'),
                      clickHandler: onDeleteLocation,
                      labelColor: 'error',
                    },
                  ]}
                />
              ) : null}
            </View>
          </MasterTitle>
        </View>

        <View padding={[4, 6]}>
          <Accordion
            className={styles['location-detail__accordion-wrapper']}
            defaultOpen={true}
            headerOptions={{
              headerElement: (
                <View direction="row">
                  <View.Item grow>
                    <Text color="primary" size="125" weight="bold">
                      {t('LocationDetails.Title')}
                    </Text>
                  </View.Item>
                </View>
              ),
            }}
          >
            <View backgroundColor="secondary" height="100%">
              <View className={styles['location-detail__content-section']}>
                <View>
                  <FormProvider {...hookFormMethods}>
                    <LocationDetailForm
                      locationId={locationId}
                      selectedSubzoneId={selectedSubzoneId}
                    />
                  </FormProvider>
                </View>
              </View>
            </View>
          </Accordion>
        </View>

        <View padding={[4, 6]}>
          <Divider />
        </View>

        <View padding={[4, 6]}>
          <Accordion
            className={styles['location-detail__accordion-wrapper']}
            headerOptions={{
              headerElement: (
                <View direction="row">
                  <View.Item grow>
                    <Text color="primary" size="125" weight="bold">
                      {t('LocationDetails.CycleCount.Title')}
                    </Text>
                    <Text
                      className={styles['location-detail__accordion-subtitle']}
                      color="400"
                      weight="regular"
                    >
                      {t('LocationDetails.CycleCount.Subtitle')}
                    </Text>
                  </View.Item>
                </View>
              ),
            }}
          >
            <View backgroundColor="secondary" height="100%">
              <View className={styles['location-detail__content-section']}>
                <View>
                  <form>
                    <View direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleCc.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleLot.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleDate.Label')} />
                          <DatePicker
                            id="cycle-date"
                            defaultValue=""
                            className={styles['location-detail__date-picker']}
                            disabled={true}
                          />
                        </View>
                      </View.Item>
                    </View>
                    <View className={[styles['form__field-row']]} direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleSqty.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleQuantity.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleCost.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleUser.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                    </View>

                    <View className={[styles['form__field-row']]} direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.CycleCount.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                    </View>

                    <View className={[styles['form__field-row']]} direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.InvQuantity1.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.InvQuantity2.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.InvQuantity3.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel text={t('LocationDetails.CycleCount.InvQuantity4.Label')} />
                          <TextField defaultValue="" disabled={true} />
                        </View>
                      </View.Item>
                    </View>
                  </form>
                </View>
              </View>
            </View>
          </Accordion>
        </View>

        <View padding={[4, 6]}>
          <Divider />
        </View>

        <View padding={[0, 6, 4, 6]}>
          <Accordion
            className={styles['location-detail__accordion-wrapper']}
            headerOptions={{
              headerElement: (
                <View direction="row">
                  <View.Item grow>
                    <Text color="primary" size="125" weight="bold">
                      {t('LocationDetails.OtherOption.Title')}
                    </Text>
                  </View.Item>
                </View>
              ),
            }}
          >
            <View backgroundColor="secondary" height="100%">
              <View className={styles['location-detail__content-section']}>
                <View>
                  <form>
                    <View direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.RecordType.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <TextField defaultValue="" />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.MaterialCode.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <TextField defaultValue="" />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.SlotType.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <TextField defaultValue="" />
                        </View>
                      </View.Item>
                    </View>
                    <View className={styles['form__field-row']} direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.ReceivingDate.Label')} (${t(
                              'Optional'
                            )})`}
                          />

                          <DatePicker
                            className={styles['location-detail__date-picker']}
                            defaultValue=""
                            dateFormat={t('DateFormat.Long')}
                          />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.ExpirationDate.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <DatePicker
                            className={styles['location-detail__date-picker']}
                            defaultValue=""
                          />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.CrossReference.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <TextField defaultValue="" />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View className={styles['form__field-row']} gap={2}>
                          <FormControl>
                            <Checkbox
                              value="true"
                              label={t('LocationDetails.OtherOption.FullReserve.Label')}
                            />
                          </FormControl>
                        </View>
                      </View.Item>
                    </View>
                    <View className={styles['form__field-row']} direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.PickingDropArea.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <TextField defaultValue="" />
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.PutawayDropArea.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <TextField defaultValue="" />
                        </View>
                      </View.Item>
                    </View>
                    <View className={styles['form__field-row']} direction="row" gap={6}>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.Volume.Label')} (${t(
                              'Optional'
                            )})`}
                          />

                          <FormControl
                            hasError={
                              !!(
                                errors.attributes &&
                                displayAttributeFieldValidationMessage(
                                  errors,
                                  getFormFieldIndexByKey(getValues()['attributes'], 'volume')
                                )
                              )
                            }
                          >
                            <TextField defaultValue="" />
                            <FormControl.Error>
                              {errors.attributes &&
                                displayAttributeFieldValidationMessage(
                                  errors,
                                  getFormFieldIndexByKey(getValues()['attributes'], 'volume')
                                )}
                            </FormControl.Error>
                          </FormControl>
                        </View>
                      </View.Item>
                      <View.Item columns={{ s: 12, l: 3 }}>
                        <View gap={2}>
                          <FormLabel
                            text={`${t('LocationDetails.OtherOption.PutawayVolume.Label')} (${t(
                              'Optional'
                            )})`}
                          />
                          <FormControl
                            hasError={
                              !!(
                                errors.attributes &&
                                displayAttributeFieldValidationMessage(
                                  errors,
                                  getFormFieldIndexByKey(getValues()['attributes'], 'putawayVolume')
                                )
                              )
                            }
                          >
                            <TextField defaultValue="" />
                            <FormControl.Error>
                              {errors.attributes &&
                                displayAttributeFieldValidationMessage(
                                  errors,
                                  getFormFieldIndexByKey(getValues()['attributes'], 'putawayVolume')
                                )}
                            </FormControl.Error>
                          </FormControl>
                        </View>
                      </View.Item>
                    </View>
                  </form>
                </View>
              </View>
            </View>
          </Accordion>
        </View>
      </View>
    );
  }
};
