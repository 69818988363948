/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { INBOUND_RECEIVING_ENDPOINTS } from '@shared/constants/routes.ts';

import {
  ASC,
  DEFAULT_PAGE,
  PAGE_SIZE,
  RECEIVED_LINES,
  SOURCE_ORDER_NUMBER,
  TRAILER_ORDERS,
} from '@inbound/constants/constants';
import { apiService } from '@inbound/services/apiService.ts';
import { InboundOrderListSchema } from '@inbound/schemas/inboundOrderSchema';
import { InboundTrailerOrderSchema } from '@inbound/schemas/inboundTrailerOrderSchema';
import { paginatedParseAndLog, parseAndLog } from '@inbound/utils/service/serviceUtils';

export const inboundOrderService = {
  getInboundOrderBySourceOrderNumber: async (sourceOrderNumber: string) => {
    const payload = {
      searchCriteria: {
        entityAssociations: [TRAILER_ORDERS],
        sourceOrderNbr: sourceOrderNumber,
      },
      searchPage: {
        page: DEFAULT_PAGE,
        size: PAGE_SIZE,
        sortBy: SOURCE_ORDER_NUMBER,
        direction: ASC,
      },
    };

    const response = await apiService.post(INBOUND_RECEIVING_ENDPOINTS.GET_INBOUND_ORDERS, payload);

    return paginatedParseAndLog(InboundOrderListSchema, response.data);
  },

  getInboundOrderBySourceOrderNumberAndTrailerInId: async (
    sourceOrderNumber: string,
    trailerInId: string
  ) => {
    const payload = {
      params: {
        entityAssociations: RECEIVED_LINES,
      },
    };

    const response = await apiService.get(
      INBOUND_RECEIVING_ENDPOINTS.GET_INBOUND_ORDERS_BY_SOURCE_ORDER_AND_TRAILER(
        sourceOrderNumber,
        trailerInId
      ),
      payload
    );

    return parseAndLog(InboundTrailerOrderSchema, response.data);
  },
};
