/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Text, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { z } from 'zod';
import {
  ConsolidationLocationSchema,
  defaultAttributeValues,
} from '@mdm/schemas/consolidationLocationSchema.ts';

import styles from './ConsolidationLocationDetail.module.scss';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { getFormDefaults } from '@mdm/utils/form/formUtils.tsx';
import { ConsolidationLocationDetailForm } from '@mdm/pages/RDM/LocationManager/ConsolidationLocation/Detail/ConsolidationLocationDetailForm.tsx';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useMutateConsolidationLocation } from '@mdm/services/hooks/useMutateConsolidationLocation.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useGetConsolidationLocationById } from '@mdm/services/hooks/useGetConsolidationLocationById.tsx';
import { useDeleteConsolidationLocation } from '@mdm/services/hooks/useDeleteConsolidationLocation.ts';

export const ConsolidationLocationDetail = () => {
  /* State */
  const { locationId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(!locationId);

  /* Constants */
  type FormData = z.infer<typeof ConsolidationLocationSchema>;
  const { mutateDeleteLocation } = useDeleteConsolidationLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading: isLocationUpdating, mutateLocation } = useMutateConsolidationLocation();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const hookFormMethods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: getFormDefaults(ConsolidationLocationSchema),
    resolver: zodResolver(ConsolidationLocationSchema),
  });

  const { getValues, handleSubmit, reset: locationReset, watch } = hookFormMethods;

  const locationName = watch('layoutName');

  const breadcrumbs = useBreadcrumb(locationId ? PAGE_URLS.CONSOLIDATION_LOCATION_CREATE : '');

  /* Functions */
  const onSubmit = () => {
    const payload = getValues();
    if (payload.attributes) {
      payload.attributes = payload.attributes
        .filter((attribute) => attribute.value !== '')
        .map((attribute) => {
          return {
            ...attribute,
            value: attribute.value.toString(),
          };
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete payload.zoneName; // remove zoneName from payload as it is not required for mutation

    mutateLocation(
      {
        layoutKey: payload.layoutKey || '',
        layoutRequest: payload,
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries(['consolidationLocations']);
            let successMessage = t('Success.Action.ConsolidationLocation.Created');
            if (payload.layoutKey) {
              successMessage = t('Success.Action.ConsolidationLocation.Updated', {
                locationName: payload.layoutName,
              });
            }
            handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            if (!locationId && data.layoutDistinctName) {
              navigate(PAGE_URLS.CONSOLIDATION_LOCATION_DETAILS(data.layoutDistinctName));
            }
          }
        },
      }
    );
  };

  const onDeleteLocation = () => {
    if (locationId) {
      const payload = getValues();
      mutateDeleteLocation(
        {
          layoutKey: payload.layoutKey || '',
        },
        {
          onSuccess: async () => {
            navigate(PAGE_URLS.LOCATION_LIST);
          },
        }
      );
    }
  };

  /* Hooks */
  const { locationData } = useGetConsolidationLocationById(
    {
      layoutKey: locationId || '',
    },
    !!locationId
  );

  useEffect(() => {
    if (locationData && locationData.content[0]) {
      let attributes = locationData.content[0].layout.attributes || [];
      for (const defaultAttributeValue of defaultAttributeValues) {
        if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
          attributes.push(defaultAttributeValue);
        }
      }

      attributes = attributes.filter((attribute) =>
        defaultAttributeValues.find(
          (defaultAttributeValue) => attribute.name === defaultAttributeValue.name
        )
      );

      locationReset({
        ...locationData.content[0].layout,
        zoneName: locationData.content[0].parent?.parentLayoutDistinctName || '',
        parentLayoutDistinctName: locationData.content[0].parent?.layoutDistinctName || '',
        attributes,
      });

      setIsDataLoaded(true);
    }
  }, [locationData, locationReset]);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={10} />;
  } else {
    return (
      <>
        <View
          className={styles['consolidation-location-detail']}
          backgroundColor="secondary"
          height="100%"
          direction="column"
        >
          <View
            attributes={{
              'data-testid': 'consolidation-location-details-header',
            }}
          >
            <MasterTitle
              title={`${t('ConsolidationLocationDetails.Title')}: ${
                locationName ? locationName : t('Untitled')
              }`}
              breadcrumbProps={breadcrumbs}
            >
              <View
                attributes={{
                  'data-testid': 'consolidation-location-details-action',
                }}
                direction="row"
                justify="end"
                align="center"
                gap={4}
              >
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        variant="secondary"
                        size="large"
                        onClick={() => navigate(PAGE_URLS.CONSOLIDATION_LOCATION_LIST)}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('Cancel')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        size="large"
                        loading={isLocationUpdating}
                        onClick={() => handleSubmit(onSubmit)()}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('SaveEdits')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                {locationId ? (
                  <ActionMenu
                    menus={[
                      {
                        label: t('RemoveLocation'),
                        clickHandler: onDeleteLocation,
                        labelColor: 'error',
                      },
                    ]}
                  />
                ) : null}
              </View>
            </MasterTitle>
          </View>
          <View padding={6}>
            <View>
              <View.Item>
                <Text size="125" weight="bold">
                  {t('ConsolidationLocationDetails.SubTitle')}
                </Text>
              </View.Item>
            </View>

            <View
              backgroundColor="secondary"
              className={styles['consolidation-location-detail__form-field-section']}
            >
              <View>
                <FormProvider {...hookFormMethods}>
                  <ConsolidationLocationDetailForm locationId={locationId} />
                </FormProvider>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
};
