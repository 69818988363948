/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

import {
  addCustomAlphaNumericAttributeFieldValidation,
  addCustomMaxLengthAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  MAX_LENGTH_FIELD,
  SUBZONE_FIELD,
} from '@mdm/constants/constants.ts';

export const defaultAttributeValues = [
  {
    name: SUBZONE_FIELD.LABEL_SORT,
    value: '',
  },
  {
    name: SUBZONE_FIELD.PICK_PRIORITY,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.SUBZONE_TYPE,
    value: '',
  },
  {
    name: SUBZONE_FIELD.MEZZANINE,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.CONVEYOR_FLAG,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.MULT_PART_ALLOW_LOC,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.PICK_DROP_LOCATION,
    value: '',
  },
  {
    name: SUBZONE_FIELD.PUT_DROP_LOCATION,
    value: '',
  },
  {
    name: SUBZONE_FIELD.SKU_PICK_CLASS,
    value: '',
  },
  {
    name: SUBZONE_FIELD.ITEM_DESC_PROMPT,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.PUT_PRIORITY,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.CATEGORY_LABEL,
    value: '',
  },
  {
    name: SUBZONE_FIELD.BIDIRECTIONAL_PICK,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.PICK_METHOD,
    value: '',
  },
  {
    name: SUBZONE_FIELD.ITEM_VERIFY,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.CONTAINER_TYPE,
    value: '',
  },
  {
    name: SUBZONE_FIELD.PACK_METHOD,
    value: '',
  },
];

export const SubzoneSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z.string().min(1),
  layoutNameDesc: z.string().nullable().default('').optional(),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().min(1),
  active: z.boolean().default(true),
  entityCd: z.string().default(LAYOUT_ENTITY_CODE.SUBZONE),
  entityTypeCd: z.string().default(LAYOUT_ENTITY_TYPE_CODE.DEFAULT),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [
        SUBZONE_FIELD.CATEGORY_LABEL,
        SUBZONE_FIELD.LABEL_SORT,
        SUBZONE_FIELD.MEZZANINE,
        SUBZONE_FIELD.MULT_PART_ALLOW_LOC,
        SUBZONE_FIELD.PICK_METHOD,
        SUBZONE_FIELD.PICK_PRIORITY,
        SUBZONE_FIELD.PICK_DROP_LOCATION,
        SUBZONE_FIELD.PUT_DROP_LOCATION,
        SUBZONE_FIELD.PUT_PRIORITY,
        SUBZONE_FIELD.SUBZONE_TYPE,
        SUBZONE_FIELD.SKU_PICK_CLASS,
      ];
      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const alphaNumericFields = [SUBZONE_FIELD.CATEGORY_LABEL];
      addCustomAlphaNumericAttributeFieldValidation(data, alphaNumericFields, ctx);

      const maxLengthFields = [
        {
          name: SUBZONE_FIELD.CATEGORY_LABEL,
          maxLength: MAX_LENGTH_FIELD.SUB_ZONE_CATEGORY_LABEL,
        },
      ];
      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);
    })
    .nullable()
    .default(defaultAttributeValues),
});
