/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { View } from '@az/starc-ui';
import { Box } from '@az/starc-ui-icons';

import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { DetailsTableSkeleton } from '@shared/components/Skeletons/DetailsTableSkeleton';
import { Table } from '@shared/components/Table/Table';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { PAGE_SIZE, DEFAULT_PAGE } from '@shared/constants/constants';

import { QUANTITY_UNMATCHED } from '@inbound/constants/constants';
import { PO_DETAILS_PRODUCT_TABLE_COLUMNS } from '@inbound/constants/tableConstants';
import { DIRECTION, TableSorting } from '@shared/components/Table/Table.types';
import { InboundTrailerLineType } from '@inbound/types/types';

import { mapPODetailProductTabelRows } from '@inbound/utils/table/tableUtils';

import * as T from './ProductTable.types';

export const ProductTable = ({ data, isLoading, type, searchValue }: T.Props) => {
  /* State variables */
  const [productData, setProductData] = useState<InboundTrailerLineType[]>(data);

  /* Constants */
  const { t } = useTranslation();
  const emptySubTitle =
    type === QUANTITY_UNMATCHED
      ? t('PODashboard.Empty.QuantityUnmatched.Subtitle')
      : t('PODashboard.Empty.QuantityMatched.Subtitle');
  const emptyText =
    type === QUANTITY_UNMATCHED
      ? t('PODashboard.Empty.QuantityUnmatched.Text')
      : t('PODashboard.Empty.QuantityMatched.Text');

  /* Functions */
  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);

    if (foundColumn) {
      const sortedProductData = [...productData].sort(
        (firstItem: InboundTrailerLineType, secondItem: InboundTrailerLineType) => {
          const firstValue = firstItem[foundColumn.id as keyof InboundTrailerLineType];
          const secondValue = secondItem[foundColumn.id as keyof InboundTrailerLineType];

          if (firstValue && secondValue) {
            return (
              (firstValue < secondValue ? -1 : 1) *
              (foundColumn.direction === DIRECTION.ASCENDING ? 1 : -1)
            );
          } else if (firstValue) {
            return 1;
          } else if (secondValue) {
            return 1;
          } else {
            return -1;
          }
        }
      );

      setProductData(sortedProductData);
    }
  };

  /* Hooks */
  useEffect(() => {
    if (searchValue) {
      const productsToShow = data.filter(
        (productItem) =>
          productItem?.uomCd?.toLowerCase().includes(searchValue.toLowerCase()) ||
          (productItem.partNumberId &&
            productItem.partNumberId.toLowerCase().includes(searchValue.toLowerCase()))
      );

      setProductData(productsToShow);
    } else {
      setProductData(data);
    }
  }, [data, searchValue]);

  return isLoading ? (
    <DetailsTableSkeleton />
  ) : (
    <View padding={6}>
      {data.length > 0 ? (
        <Table
          columns={PO_DETAILS_PRODUCT_TABLE_COLUMNS}
          rows={mapPODetailProductTabelRows(productData)}
          isPaginated={true}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={Math.ceil(productData.length / PAGE_SIZE)}
          onSort={onSort}
        />
      ) : (
        <View justify="center" align="center">
          <View width="360px">
            <EmptyState svg={Box} subtitle={emptySubTitle} text={emptyText} />
          </View>
        </View>
      )}
    </View>
  );
};
