/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

import {
  addCustomAlphaNumericAttributeFieldValidation,
  addCustomMaxLengthAttributeFieldValidation,
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  LOCATION_FIELD,
  LOCATION_NAME_REGEX,
  MAX_LENGTH_FIELD,
  VALID_LOCATION_SKU,
} from '@mdm/constants/constants.ts';
import { t } from 'i18next';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';

export const defaultAttributeValues = [
  {
    name: LOCATION_FIELD.LOCATION_TYPE,
    value: '',
  },
  {
    name: LOCATION_FIELD.CHECK_DIGIT,
    value: '0',
  },
  {
    name: LOCATION_FIELD.SKU,
    value: '',
  },
  {
    name: LOCATION_FIELD.LOT,
    value: '',
  },
  {
    name: LOCATION_FIELD.QUANTITY_UNITS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.QUANTITY_RESERVED,
    value: '0',
  },
  {
    name: LOCATION_FIELD.MINIMUM,
    value: '0',
  },
  {
    name: LOCATION_FIELD.MAXIMUM,
    value: '0',
  },
  {
    name: LOCATION_FIELD.PACK_SIZE,
    value: '0',
  },
  {
    name: LOCATION_FIELD.LOCATION_FLAG,
    value: '',
  },
  {
    name: LOCATION_FIELD.LOCATION_LOCK,
    value: 'false',
  },
  {
    name: LOCATION_FIELD.SLOT_FRONTS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.SLOT_LAYERS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.SLOT_DEEPS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.SLOT_WIDTH,
    value: '0',
  },
  {
    name: LOCATION_FIELD.FULL_PALLET,
    value: 'false',
  },
];

export const LocationSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z
    .string()
    .max(MAX_LENGTH_FIELD.LOCATION_NAME)
    .superRefine((data, ctx) => {
      if (data === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.RequiredField'),
        });
      }

      if (data.length > MAX_LENGTH_FIELD.LOCATION_NAME) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.LOCATION_NAME }),
        });
      }

      if (!isAlphaNumeric(data)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.AlphaNumericError'),
        });
      }

      if (data && !LOCATION_NAME_REGEX.test(data)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('ValidationError.Location.Name'),
        });
      }
    }),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().min(1),
  entityCd: z.string().default(LAYOUT_ENTITY_CODE.LOCATION),
  entityTypeCd: z.string().default(LAYOUT_ENTITY_TYPE_CODE.DEFAULT),
  active: z.boolean().default(true),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [
        LOCATION_FIELD.CHECK_DIGIT,
        LOCATION_FIELD.LOCATION_TYPE,
        LOCATION_FIELD.SKU,
        LOCATION_FIELD.MINIMUM,
        LOCATION_FIELD.MAXIMUM,
        LOCATION_FIELD.QUANTITY_RESERVED,
      ];

      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const numericFields = [
        {
          key: LOCATION_FIELD.CHECK_DIGIT,
        },
        {
          key: LOCATION_FIELD.SLOT_FRONTS,
        },
        {
          key: LOCATION_FIELD.SLOT_DEEPS,
        },
        {
          key: LOCATION_FIELD.SLOT_LAYERS,
        },
        {
          key: LOCATION_FIELD.MINIMUM,
        },
        {
          key: LOCATION_FIELD.MAXIMUM,
        },
        {
          key: LOCATION_FIELD.QUANTITY_RESERVED,
        },
        {
          key: LOCATION_FIELD.SLOT_WIDTH,
        },
        {
          key: LOCATION_FIELD.PACK_SIZE,
        },
      ];

      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);

      const maxLengthFields = [
        {
          name: LOCATION_FIELD.CHECK_DIGIT,
          maxLength: MAX_LENGTH_FIELD.CHECK_DIGIT,
        },
        {
          name: LOCATION_FIELD.SKU,
          maxLength: MAX_LENGTH_FIELD.LOCATION_SKU,
        },
        {
          name: LOCATION_FIELD.LOT,
          maxLength: MAX_LENGTH_FIELD.LOCATION_LOT,
        },
        {
          name: LOCATION_FIELD.MINIMUM,
          maxLength: MAX_LENGTH_FIELD.LOCATION_MINIMUM,
        },
        {
          name: LOCATION_FIELD.MAXIMUM,
          maxLength: MAX_LENGTH_FIELD.LOCATION_MAXIMUM,
        },
        {
          name: LOCATION_FIELD.SLOT_FRONTS,
          maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_FRONT,
        },
        {
          name: LOCATION_FIELD.SLOT_DEEPS,
          maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_DEEP,
        },
        {
          name: LOCATION_FIELD.SLOT_LAYERS,
          maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_LAYER,
        },
        {
          name: LOCATION_FIELD.SLOT_WIDTH,
          maxLength: MAX_LENGTH_FIELD.LOCATION_CURRENT_WIDTH,
        },
      ];
      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);

      const alphaNumericFields = ['LOT'];
      addCustomAlphaNumericAttributeFieldValidation(data, alphaNumericFields, ctx);

      const skuFieldIndex = data.findIndex((field) => field.name === 'SKU');
      if (skuFieldIndex !== -1) {
        if (!VALID_LOCATION_SKU.includes(data[skuFieldIndex].value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`attribute-${skuFieldIndex}`],
            message: t('ValidationError.Location.SKU'),
          });
        }
      }
    })
    .nullable()
    .default(defaultAttributeValues),
});
