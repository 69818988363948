/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { subzoneService } from '@mdm/services/subzoneService.ts';

export const useGetSubzoneById = (params: { layoutId: string }, isEnabled = true) => {
  const {
    data: subzoneData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['subzone', params],
    queryFn: () => subzoneService.getSubzoneById(params.layoutId),
    enabled: isEnabled,
    cacheTime: 0,
  });

  return { subzoneData, isLoading, isError };
};
