/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text, View } from '@az/starc-ui';

import { Avatar } from '@shared/components/Avatar/Avatar';
import { USER_STATUS_CD } from '@shared/constants/constants';
import { UserType } from '@shared/types/schema.type';

import { TrailerUserType } from '@inbound/types/types';

import * as T from './AssigneesSuggestion.types';
import styles from './AssigneesSuggestion.module.scss';

export const AssigneesSuggestion = ({
  data,
  selectedAssignees,
  recommendedAssignees,
  onItemClick,
}: T.Props) => {
  /* State variables */
  const [, setIsLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<TrailerUserType[]>([]);

  /* Constants */
  const { t } = useTranslation();

  /* Functions */
  const isSuggested = (userId: string) => {
    return recommendedAssignees.filter((item) => item.userId === userId).length > 0;
  };

  const isSelected = (userId: string) => {
    if (selectedUsers) {
      return (
        selectedUsers.filter(
          (item) => item.assignedUserId === userId && item.userStatusCd !== USER_STATUS_CD.CANCEL
        ).length > 0
      );
    }

    return false;
  };

  const handleSuggestionClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    user: UserType | TrailerUserType
  ) => {
    setIsLoading(true);

    if ('userId' in user) {
      onItemClick?.(
        {
          assignedUserId: user.userId,
          assignedUserName: `${user.firstName.trim()} ${user.lastName.trim()}`,
          userStatusCd: e.target.checked ? USER_STATUS_CD.ASSIGNED : USER_STATUS_CD.CANCEL,
        },
        e.target.checked
      );
    } else {
      onItemClick?.(
        {
          ...user,
          userStatusCd: e.target.checked ? USER_STATUS_CD.ASSIGNED : USER_STATUS_CD.CANCEL,
        },
        e.target.checked
      );
    }

    setTimeout(() => {
      setIsLoading(false); // fake the delay to reset state
    }, 1);
  };

  /* Hooks */
  useEffect(() => {
    if (selectedAssignees) {
      setSelectedUsers(selectedAssignees);
    }
  }, [selectedAssignees]);

  return (
    <View direction="column" padding={[2, 0]} className={styles['assignees-suggestion__container']}>
      {selectedUsers && selectedUsers.length > 0 && (
        <View.Item>
          <View direction="column" className={styles['assignees-suggestion__section']}>
            <View.Item>
              <View className={styles['assignees-suggestion__section__title']}>
                <Text>
                  {t('PODashboard.Assignees.Selected')} (
                  {selectedUsers?.filter((assignee) => isSelected(assignee.assignedUserId)).length})
                </Text>
              </View>
            </View.Item>
            <View.Item>
              <View className={styles['assignees-suggestion__section__items']}>
                {selectedUsers
                  ?.filter((assignee) => isSelected(assignee.assignedUserId))
                  .map((assigneeItem) => (
                    <View
                      key={t('PODashboard.ItemKey', {
                        item: 'selected',
                        key: assigneeItem.assignedUserId,
                      })}
                      direction="row"
                      className={styles['assignees-suggestion__item']}
                    >
                      <Checkbox
                        label={
                          <View
                            direction="row"
                            justify="center"
                            align="center"
                            className={styles['assignees-suggestion__item__checkbox__label']}
                          >
                            <View.Item grow>
                              <Avatar
                                name={assigneeItem.assignedUserName.trim()}
                                size="large"
                                showText={true}
                              />
                            </View.Item>

                            {isSuggested(assigneeItem.assignedUserId) && (
                              <View.Item>
                                <View
                                  direction="row"
                                  justify="center"
                                  align="center"
                                  min-height="var(--st-unit-6)"
                                  width="fit-content"
                                  borderRadius="small"
                                  className={styles[`assignees-suggestion__item__badge`]}
                                >
                                  <Text
                                    size="075"
                                    weight="bold"
                                    className={styles[`assignees-suggestion__item__badge--text`]}
                                  >
                                    {t('PODashboard.Assignees.Suggested')}
                                  </Text>
                                </View>
                              </View.Item>
                            )}
                          </View>
                        }
                        name="selected-assignees"
                        value="all"
                        checked={isSelected(assigneeItem.assignedUserId)}
                        onChange={(e) => handleSuggestionClick(e, assigneeItem)}
                        className={styles[`assignees-suggestion__item__checkbox`]}
                      />
                    </View>
                  ))}
              </View>
            </View.Item>
          </View>
        </View.Item>
      )}

      <View.Item>
        <View direction="column" className={styles['assignees-suggestion__section']}>
          <View.Item>
            <View className={styles['assignees-suggestion__section__title']}>
              <Text size="087" weight="medium" lineHeight="140">
                {t('PODashboard.Assignees.AllAutoZoners')} (
                {data?.filter((assignee) => !isSelected(assignee.userId))?.length})
              </Text>
            </View>
          </View.Item>
          <View.Item>
            <View className={styles['assignees-suggestion__section__items']}>
              {data
                ?.filter((assignee) => !isSelected(assignee.userId))
                .map((dataItem: UserType) => (
                  <View
                    key={t('PODashboard.ItemKey', {
                      item: 'assignees',
                      key: dataItem.userId,
                    })}
                    direction="row"
                    className={styles['assignees-suggestion__item']}
                  >
                    <Checkbox
                      label={
                        <View
                          direction="row"
                          justify="center"
                          align="center"
                          className={styles['assignees-suggestion__item__checkbox__label']}
                        >
                          <View.Item grow>
                            <Avatar
                              name={`${dataItem.firstName} ${dataItem.lastName}`}
                              size="large"
                              showText={true}
                            />
                          </View.Item>

                          {isSuggested(dataItem.userId) && (
                            <View.Item>
                              <View
                                direction="row"
                                justify="center"
                                align="center"
                                min-height="var(--st-unit-6)"
                                width="fit-content"
                                borderRadius="small"
                                className={styles[`assignees-suggestion__item__badge`]}
                              >
                                <Text
                                  size="075"
                                  weight="bold"
                                  className={styles[`assignees-suggestion__item__badge--text`]}
                                >
                                  {t('PODashboard.Assignees.Suggested')}
                                </Text>
                              </View>
                            </View.Item>
                          )}
                        </View>
                      }
                      name="selected-assignees"
                      value="all"
                      defaultChecked={isSelected(dataItem.userId)}
                      onChange={(e) => handleSuggestionClick(e, dataItem)}
                      className={styles[`assignees-suggestion__item__checkbox`]}
                    />
                  </View>
                ))}
            </View>
          </View.Item>
        </View>
      </View.Item>
    </View>
  );
};
