/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Accordion,
  Actionable,
  Button,
  Icon,
  Link,
  Notification,
  SearchBar,
  Text,
  View,
} from '@az/starc-ui';
import { ActionDelete, Add, ArrowRight, Chat, ChevronDown, IconError } from '@az/starc-ui-icons';

import { Ellipses } from '@shared/assets/icons';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { WMSInlineNotification } from '@shared/components/Notification/Notification';
import { Stat } from '@shared/components/Stat/Stat';
import { Tag } from '@shared/components/Tag/Tag';
import {
  ACTIONS,
  DEPARTMENTS,
  EMPTY_VALUE,
  NOTIFICATION_TYPES,
  USER_STATUS_CD,
} from '@shared/constants/constants';

import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useMutateTrailerArrival } from '@inbound/services/hooks/useMutateTrailerArrival';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { UserType } from '@shared/types/schema.type';
import { EmptyPage } from '@shared/components/EmptyPage/EmptyPage';

import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';
import { EditPOModal } from '@inbound/components/EditPOModal/EditPOModal';
import { POStatusModal } from '@inbound/components/POStatusModal/POStatusModal';
import { PriorityToggle } from '@inbound/components/PriorityToggle/PriorityToggle';
import { RemovePOModal } from '@inbound/components/RemovePOModal/RemovePOModal';
import { RecommendedAssignees } from '@inbound/components/RecommendedAssignees/RecommendedAssignees';
import { AddEditAssigneesModal } from '@inbound/components/AddEditAssigneesModal/AddEditAssigneesModal';
import {
  ASC,
  DEFAULT_PAGE,
  PAGE_SIZE,
  QUANTITY_MATCHED,
  QUANTITY_UNMATCHED,
  READY_FOR_FINALIZATION,
  READY_FOR_SIGNATURE,
  SOURCE_ORDER_NUMBER,
  TRAILER_ORDER_STATUS,
} from '@inbound/constants/constants';
import { useGetInboundOrderBySourceAndTrailerInId } from '@inbound/services/hooks/useGetInboundOrderBySourceOrderAndTrailerInId';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import {
  InboundTrailerLineType,
  InboundTrailerOrderType,
  TrailerArrivalRequestType,
  TrailerArrivalUpdateType,
  TrailerOrderType,
} from '@inbound/types/types';
import { calculatePercentageWithSign, statusToBadgeVariant } from '@inbound/utils/utils';

import { PODetailSkeleton } from './PODetailSkeleton';
import { ProductTabs } from './ProductTabs/ProductTabs';
import { ProductTable } from './ProductTabs/ProductTable';

import { PODetailRow } from './PODetailsSection/PODetailsSection.types';
import { PODetailsSection } from './PODetailsSection/PODetailsSection';

import styles from './PODetail.module.scss';

export const PODetail = () => {
  /* State variables */
  const [isHighPriority, setIsHighPriority] = useState<boolean | undefined>(false);
  const [trailerOrderData, setTrailerOrderData] = useState<TrailerOrderType>();
  const [searchValue, setSearchValue] = useState<string>();
  const [quantityMatchedData, setQuantityMatchedData] = useState<InboundTrailerLineType[]>([]);
  const [quantityUnmatchedData, setQuantityUnmatchedData] = useState<InboundTrailerLineType[]>([]);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [trailerArrivalEditData, setTrailerArrivalEditData] = useState<TrailerArrivalUpdateType>();
  const [showPOEditModal, setShowPOEditModal] = useState<boolean>(false);
  const [isShowOnHold, setIsShowOnHold] = useState<boolean>(false);
  const [showPOHoldModal, setShowPOHoldModal] = useState<boolean>(false);
  const [showPORemoveModal, setShowPORemoveModal] = useState<boolean>(false);
  const [showAddEditAssigneesModal, setShowAddEditAssigneesModal] = useState<boolean>(false);
  const [openAssigneeActionMenu] = useState<boolean | null>();

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const { sourceOrderId: sourceOrderId, trailerInId: trailerInId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const maxRecommendedAssignee = 5;

  const tabTitles = {
    QUANTITY_UNMATCHED: t('CombinedTabs.PODetails.QuantityUnmatched'),
    QUANTITY_MATCHED: t('CombinedTabs.PODetails.QuantityMatched'),
  };

  const breadcrumbs = {
    data: [
      {
        label: t('PODashboard.Title'),
        url: PAGE_URLS.PO_DASHBOARD,
      },
      {
        label: t('PODashboard.PoWithNumber', {
          poNumber: sourceOrderId,
        }),
        url: '',
      },
    ],
  };

  const tabs = [
    {
      name: tabTitles.QUANTITY_UNMATCHED,
      numberOfItems: 5,
      value: QUANTITY_UNMATCHED,
      content: (
        <ProductTable
          data={quantityUnmatchedData}
          isLoading={isLoadingData}
          type={QUANTITY_UNMATCHED}
          searchValue={searchValue}
        />
      ),
    },
    {
      name: tabTitles.QUANTITY_MATCHED,
      numberOfItems: 5,
      value: QUANTITY_MATCHED,
      content: (
        <ProductTable
          data={quantityMatchedData}
          isLoading={isLoadingData}
          type={QUANTITY_MATCHED}
          searchValue={searchValue}
        />
      ),
    },
  ];

  /* Queries */
  const { isLoading: isLoadingUpdate, mutateTrailerArrival } = useMutateTrailerArrival();

  const {
    inboundOrderData,
    isLoading: isLoadingInboundOrder,
    isError: isErrorInboundOrder,
  } = useGetInboundOrderBySourceAndTrailerInId(
    {
      sourceOrderNumber: sourceOrderId || '',
      trailerInId: trailerInId || '',
    },
    true
  );

  const {
    trailerOrdersData,
    isLoading: isLoadingTrailerOrders,
    isError: isErrorTrailerOrders,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: DEFAULT_PAGE,
        pageSize: PAGE_SIZE * 20,
        sortBy: SOURCE_ORDER_NUMBER,
        direction: ASC,
      },
      searchCriteria: {
        sourceOrderNbr: sourceOrderId,
      },
    },
    true
  );

  const { usersSearchData: recommendedAssigneeData } = useUsersSearch({
    deptId: DEPARTMENTS.INBOUND,
  });

  /* Functions */
  const updateTrailerArrival = (payload: TrailerArrivalRequestType) => {
    mutateTrailerArrival(
      { trailerInId: trailerInId, trailerArrivalRequest: payload },
      {
        onSuccess: () => {
          handleNotification(
            NOTIFICATION_TYPES.SUCCESS,
            t('PODashboard.Notification.UpdateTrilerArrival.Success', {
              poNumber: sourceOrderId,
            })
          );
        },
      }
    );
  };

  const renderMaterTitleSubTitle = (inboundOrderData: InboundTrailerOrderType) => {
    return (
      <View align="start">
        <View.Item>
          <View
            direction="row"
            align="center"
            justify="center"
            className={styles['po-detail__master-title__sub-title']}
          >
            <View.Item>
              <View
                direction="row"
                align="center"
                justify="center"
                className={styles['po-detail__master-title__sub-title__right-items']}
              >
                {isHighPriority && (
                  <View.Item>
                    <PriorityTag />
                  </View.Item>
                )}

                {inboundOrderData.trailerOrder.inboundOrder.orderTypeCd && (
                  <View.Item>
                    <View justify="center">
                      <Tag
                        variant="order"
                        text={inboundOrderData.trailerOrder.inboundOrder.orderTypeCd}
                        className={styles['po-detail__master-title__sub-title__tag']}
                      />
                    </View>
                  </View.Item>
                )}
              </View>
            </View.Item>

            <View.Item>
              <Text color="600">
                {t('PODashboard.PoWithNumber', {
                  poNumber: sourceOrderId,
                })}
              </Text>
            </View.Item>

            {inboundOrderData.trailerOrder.orderLocationId && (
              <View.Item>
                <Text color="600">
                  {t('PODashboard.DoorWithName', {
                    door: inboundOrderData.trailerOrder.orderLocationId,
                  })}
                </Text>
              </View.Item>
            )}
          </View>
        </View.Item>
      </View>
    );
  };

  const getPODetails = (trailerOrder: TrailerOrderType): PODetailRow[] => {
    return [
      {
        label: t('PODashboard.Item.ArrivalTime'),
        text:
          new Date(trailerOrder.trailerArrival.trailerArrivalTs)
            .toLocaleString('en-Us', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
            .replace(',', '') || EMPTY_VALUE,
      },
      { label: t('PODashboard.Item.Shift'), text: '1st' },
      {
        label: t('PODashboard.Item.GoalHours'),
        text: '5',
      },
      {
        label: t('PODashboard.Item.ReceivingDoor'),
        text: trailerOrder.orderLocationId || EMPTY_VALUE,
      },
      {
        label: t('PODashboard.Item.Vendor'),
        text: trailerOrder.inboundOrder.vendorLocale?.vendorName || EMPTY_VALUE,
      },
      {
        label: t('PODashboard.Item.Carrier'),
        text: trailerOrder.trailerArrival.trailerCarrierName || EMPTY_VALUE,
      },
      {
        label: t('PODashboard.Item.Trailer'),
        text: trailerOrder.trailerArrival.trailerNbr || EMPTY_VALUE,
      },
      {
        label: t('PODashboard.Item.Location'),
        text:
          `${trailerOrder.trailerArrival.trailerLocationTypeCd} ${trailerOrder.trailerArrival.trailerLocationId}` ||
          EMPTY_VALUE,
      },
      {
        label: t('PODashboard.Item.Commodity'),
        text: trailerOrder.commodityTypeCd ? '' : EMPTY_VALUE,
        children: trailerOrder.commodityTypeCd && (
          <Tag
            variant="order"
            text={trailerOrder.commodityTypeCd}
            className={styles['po-detail__master-title__sub-title__tag']}
          />
        ),
      },
      {
        label: t('PODashboard.Priority'),
        text: '',
        children: (
          <PriorityToggle
            name="priority"
            checked={isHighPriority}
            onValueChange={(priority) => onChangePriority(priority)}
            disabled={isLoadingUpdate}
          />
        ),
      },
      {
        label: t('PODashboard.Item.Finalization'),
        text:
          trailerOrder.statusCd === READY_FOR_SIGNATURE ||
          trailerOrder.statusCd === READY_FOR_FINALIZATION
            ? '03/04/24 15:00'
            : EMPTY_VALUE,
      },
    ];
  };

  const renderPOStatistic = (title: string, primaryText: string, secondaryText?: string) => {
    return (
      <View.Item grow backgroundColor="secondary">
        {secondaryText ? (
          <Stat
            title={title}
            primaryText={primaryText}
            width="100%"
            secondaryTextProps={{
              secondaryText: secondaryText,
            }}
          />
        ) : (
          <Stat title={title} primaryText={primaryText} width="100%" />
        )}
      </View.Item>
    );
  };

  const onCloseEdit = () => {
    setShowPOEditModal(false);

    if (location.pathname.indexOf(ROUTES.EDIT) !== -1) {
      navigate(PAGE_URLS.PO_DASHBOARD);
    }
  };

  const isPOOnHold = () => {
    if (inboundOrderData && inboundOrderData.trailerOrder.statusCd === TRAILER_ORDER_STATUS.HOLD) {
      return true;
    } else {
      return false;
    }
  };

  const onClosePutPOHold = (status: string | null) => {
    setShowPOHoldModal(false);

    if (status === ACTIONS.CLOSE && !isPOOnHold()) {
      navigate(PAGE_URLS.PO_DASHBOARD);
    }
  };

  const onCloseRemove = () => {
    setShowPORemoveModal(false);
    navigate(PAGE_URLS.PO_DASHBOARD);
  };

  const onOpenAddEditAssignees = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setShowAddEditAssigneesModal(true);
  };

  const onAssigneeSelect = (user: UserType) => {
    if (trailerArrivalEditData && trailerInId && user) {
      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            commodityTypeCd: trailerArrivalEditData.trailerOrders.commodityTypeCd,
            sourceOrderNbr: trailerArrivalEditData.trailerOrders.sourceOrderNbr,
            statusCd: trailerArrivalEditData.trailerOrders.statusCd,
            trailerOrderKey: trailerArrivalEditData.trailerOrders.trailerOrderKey,
            priority: trailerArrivalEditData.trailerOrders.priority,
            users: [
              {
                assignedUserId: user.userId,
                assignedUserName: `${user.firstName.trim()} ${user.lastName.trim()}`,
                userStatusCd: USER_STATUS_CD.ASSIGNED,
              },
            ],
          },
        ],
      };

      updateTrailerArrival(payload);
    }
  };

  const onCloseAddEditAssignees = () => {
    setShowAddEditAssigneesModal(false);
  };

  const onSubmitAssignees = () => {
    onCloseAddEditAssignees();
  };

  const onRemoveAssignee = (userId: string) => {
    if (trailerArrivalEditData && userId) {
      const assignedUsersData = trailerArrivalEditData.trailerOrders.users;

      if (assignedUsersData) {
        const i = assignedUsersData.findIndex((assignee) => assignee.assignedUserId === userId);

        if (i > -1) {
          assignedUsersData[i].userStatusCd = USER_STATUS_CD.CANCEL;
        }
      }

      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            commodityTypeCd: trailerArrivalEditData.trailerOrders.commodityTypeCd,
            sourceOrderNbr: trailerArrivalEditData.trailerOrders.sourceOrderNbr,
            statusCd: trailerArrivalEditData.trailerOrders.statusCd,
            trailerOrderKey: trailerArrivalEditData.trailerOrders.trailerOrderKey,
            priority: trailerArrivalEditData.trailerOrders.priority,
            users: assignedUsersData,
          },
        ],
      };

      updateTrailerArrival(payload);
    }
  };

  const onChangePriority = (priority: boolean) => {
    if (trailerArrivalEditData && trailerInId) {
      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            commodityTypeCd: trailerArrivalEditData.trailerOrders.commodityTypeCd,
            sourceOrderNbr: trailerArrivalEditData.trailerOrders.sourceOrderNbr,
            statusCd: trailerArrivalEditData.trailerOrders.statusCd,
            trailerOrderKey: trailerArrivalEditData.trailerOrders.trailerOrderKey,
            priority: priority ? 1 : 0,
            users: trailerArrivalEditData.trailerOrders.users,
          },
        ],
      };

      updateTrailerArrival(payload);
    }
  };

  /* Hooks */
  useEffect(() => {
    if (location.pathname.indexOf(ROUTES.EDIT) !== -1) {
      setShowPOEditModal(true);
    } else if (location.pathname.indexOf(ROUTES.PUT_ON_HOLD) !== -1) {
      setIsShowOnHold(true);
    } else if (location.pathname.indexOf(ROUTES.REMOVE) !== -1) {
      setShowPORemoveModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (inboundOrderData) {
      setQuantityMatchedData(
        inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines.filter(
          (inboundOrderLine) =>
            inboundOrderLine.totalOrderedQty === inboundOrderLine.totalReceivedQty
        )
      );

      setQuantityUnmatchedData(
        inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines.filter(
          (inboundOrderLine) =>
            inboundOrderLine.totalOrderedQty !== inboundOrderLine.totalReceivedQty
        )
      );

      setIsHighPriority(inboundOrderData.trailerOrder.priority ? true : false);

      setIsLoadingData(false);
    }
  }, [inboundOrderData]);

  useEffect(() => {
    if (trailerOrdersData) {
      const trailerOrder = trailerOrdersData.content.filter(
        (orderItem) => orderItem.trailerOrder.trailerInId === trailerInId
      );

      if (trailerOrder.length > 0) {
        setTrailerOrderData(trailerOrder[0].trailerOrder);
      }
    }
  }, [trailerInId, trailerOrdersData]);

  useEffect(() => {
    if (inboundOrderData && trailerOrderData) {
      setTrailerArrivalEditData({
        trailerArrivalTs: trailerOrderData.trailerArrival.trailerArrivalTs,
        trailerCarrierName: trailerOrderData.trailerArrival.trailerCarrierName || '',
        trailerNbr: trailerOrderData.trailerArrival.trailerNbr || '',
        trailerLocationTypeCd: trailerOrderData.trailerArrival.trailerLocationTypeCd,
        trailerLocationId: trailerOrderData.trailerArrival.trailerLocationId,
        trailerOrders: {
          trailerOrderKey: inboundOrderData.trailerOrder.trailerOrderKey,
          sourceOrderNbr: inboundOrderData.trailerOrder.sourceOrderNbr,
          orderLocationTypeCd: inboundOrderData.trailerOrder.orderLocationTypeCd,
          orderLocationId: inboundOrderData.trailerOrder.orderLocationId,
          commodityTypeCd: inboundOrderData.trailerOrder.commodityTypeCd,
          statusCd: inboundOrderData.trailerOrder.statusCd,
          priority: inboundOrderData.trailerOrder.priority,
          users: trailerOrderData.users,
        },
        priority: inboundOrderData.trailerOrder.priority === 1 ? true : false,
      });
    }
  }, [inboundOrderData, trailerOrderData]);

  if (isLoadingInboundOrder || isLoadingTrailerOrders) {
    return <PODetailSkeleton />;
  } else if (isErrorTrailerOrders || isErrorInboundOrder) {
    return (
      <EmptyPage
        title={t('Errors.Page.Title')}
        description={t('Errors.Page.Description', { service: t('Services.Order') })}
        buttonText={t('404.ButtonText')}
        onClick={() => navigate(PAGE_URLS.PO_DASHBOARD)}
        icon={IconError}
      />
    );
  } else {
    return (
      <View direction="column" height="100%" className={styles['po-detail']}>
        {inboundOrderData && trailerOrderData && (
          <>
            <MasterTitle
              title={
                inboundOrderData?.trailerOrder.inboundOrder.vendorLocale?.vendorName || EMPTY_VALUE
              }
              breadcrumbProps={breadcrumbs}
              statusBadgeProps={{
                variant: statusToBadgeVariant(inboundOrderData.trailerOrder.statusCd),
                text: inboundOrderData.trailerOrder.statusCd,
              }}
              subtitle={renderMaterTitleSubTitle(inboundOrderData)}
            >
              <View direction="row" justify="end" align="center" gap={4}>
                <View.Item>
                  <Link
                    onClick={() => {
                      return;
                    }}
                    startIcon={Chat}
                    endIcon={ChevronDown}
                  >
                    {t('PODashboard.Activity')}
                  </Link>
                </View.Item>

                {isShowOnHold && !isPOOnHold() && (
                  <View.Item>
                    <Button
                      size="large"
                      onClick={() => setShowPOHoldModal(true)}
                      variant="secondary"
                    >
                      <View direction="row">
                        <Text>{t('PODashboard.Actions.PutPOOnHold')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                )}

                <View.Item>
                  {isPOOnHold() ? (
                    <Button size="large" onClick={() => setShowPOHoldModal(true)}>
                      <View direction="row">
                        <Text>{t('PODashboard.Actions.RemoveHold')}</Text>
                      </View>
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      onClick={() => {
                        return;
                      }}
                    >
                      <View direction="row">
                        <Text>{t('PODashboard.Finalize')}</Text>
                      </View>
                    </Button>
                  )}
                </View.Item>

                <View.Item>
                  <View direction="row">
                    <Link
                      onClick={() => {
                        return;
                      }}
                    >
                      <Icon svg={Ellipses} />
                    </Link>
                  </View>
                </View.Item>
              </View>
            </MasterTitle>

            <View direction="row" width="100%" height="100%" wrap={false}>
              <View.Item
                columns={{ s: 4, m: 4, l: 4, xl: 3 }}
                className={styles['po-detail__left-column']}
                attributes={{
                  'data-testid': 'po-details-left-column',
                }}
              >
                <View>
                  <Accordion
                    className={styles['po-detail__left-column__accordion']}
                    headerOptions={{
                      triggerInnerClassName:
                        styles['po-detail__left-column__accordion__trigger-inner'],
                      headerElement: (
                        <View direction="row">
                          <View.Item grow>
                            <Text
                              color="primary"
                              size="100"
                              weight="bold"
                              className={styles['po-detail__left-column__accordion__header']}
                            >
                              {t('PODashboard.Assignees.Title')}
                            </Text>
                          </View.Item>
                          <View.Item>
                            <Link
                              className={
                                styles['po-detail__left-column__accordion__auxiliary-label__link']
                              }
                              onClick={(e) => onOpenAddEditAssignees(e)}
                              startIcon={Add}
                            >
                              {trailerOrderData?.users.filter(
                                (item) => item.userStatusCd !== USER_STATUS_CD.CANCEL
                              ).length === 0
                                ? t('PODashboard.Assignees.AddAssignee')
                                : t('PODashboard.Assignees.EditAssignee')}
                            </Link>
                          </View.Item>
                        </View>
                      ),
                    }}
                  >
                    {trailerOrderData?.users.filter(
                      (item) => item.userStatusCd !== USER_STATUS_CD.CANCEL
                    ).length === 0 ? (
                      recommendedAssigneeData && (
                        <RecommendedAssignees
                          assignees={recommendedAssigneeData
                            .slice(0, maxRecommendedAssignee)
                            .map((assigneeItem) => ({
                              ...assigneeItem.users,
                            }))}
                          isLoading={isLoadingUpdate}
                          onButtonClick={(user) => onAssigneeSelect(user)}
                        />
                      )
                    ) : (
                      <View
                        direction="column"
                        padding={[4, 0, 0, 0]}
                        className={
                          styles['po-detail__left-column__accordion__assignees_items_wrapper']
                        }
                      >
                        {trailerOrderData?.users
                          .filter((item) => item.userStatusCd !== USER_STATUS_CD.CANCEL)
                          .map((user, index) => (
                            <View.Item
                              key={t('PODashboard.ItemKey', { item: 'assignees', key: index })}
                            >
                              <View direction="row" align="center">
                                <View.Item grow>
                                  <Avatar
                                    name={user.assignedUserName}
                                    size="large"
                                    showText={true}
                                  />
                                </View.Item>

                                <View.Item>
                                  <ActionDropdownMenu isOpen={openAssigneeActionMenu}>
                                    <View padding={[1, 0]}>
                                      <View.Item>
                                        <View padding={[3, 4]}>
                                          <Actionable
                                            fullWidth
                                            onClick={() => {
                                              return;
                                            }}
                                          >
                                            <Text>{t('PODashboard.Actions.ManageTasks')}</Text>
                                          </Actionable>
                                        </View>
                                      </View.Item>
                                      <View.Item>
                                        <View padding={[3, 4]}>
                                          <Actionable
                                            fullWidth
                                            onClick={() => onRemoveAssignee(user.assignedUserId)}
                                          >
                                            <Text>{t('PODashboard.Actions.RemoveAssignee')}</Text>
                                          </Actionable>
                                        </View>
                                      </View.Item>
                                    </View>
                                  </ActionDropdownMenu>
                                </View.Item>
                              </View>
                            </View.Item>
                          ))}
                      </View>
                    )}
                  </Accordion>

                  <Accordion
                    className={styles['po-detail__left-column__accordion']}
                    headerOptions={{
                      triggerInnerClassName:
                        styles['po-detail__left-column__accordion__trigger-inner'],
                      headerElement: (
                        <View direction="row">
                          <View.Item grow>
                            <Text
                              color="primary"
                              size="100"
                              weight="bold"
                              className={styles['po-detail__left-column__accordion__header']}
                            >
                              {t('PODashboard.PODetails')}
                            </Text>
                          </View.Item>
                          <View.Item>
                            <Link
                              className={
                                styles['po-detail__left-column__accordion__auxiliary-label__link']
                              }
                              onClick={(
                                event:
                                  | React.MouseEvent<HTMLElement>
                                  | React.KeyboardEvent<HTMLElement>
                              ) => {
                                setShowPOEditModal(true);
                                event.stopPropagation();
                              }}
                            >
                              {t('Edit')}
                            </Link>
                          </View.Item>
                        </View>
                      ),
                    }}
                  >
                    <PODetailsSection rows={getPODetails(trailerOrderData)} />
                  </Accordion>

                  <Accordion
                    className={styles['po-detail__left-column__accordion']}
                    headerOptions={{
                      triggerInnerClassName:
                        styles['po-detail__left-column__accordion__trigger-inner'],
                      headerElement: (
                        <View direction="row">
                          <Text
                            color="primary"
                            size="100"
                            weight="bold"
                            className={styles['po-detail__left-column__accordion__header']}
                          >
                            {t('PODashboard.SCAContactInformation')}
                          </Text>
                        </View>
                      ),
                    }}
                  >
                    <View padding={[4, 0]}>
                      <Text color="primary" size="087" weight="medium">
                        {t('PODashboard.SCAContactInformation')}
                      </Text>
                    </View>
                  </Accordion>

                  <View padding={6} className={styles['po-detail__left-column__remove-po_wrapper']}>
                    <Link
                      className={styles['po-detail__left-column__remove-po_wrapper__action']}
                      onClick={() => {
                        return;
                      }}
                    >
                      <View direction="row" justify="center" align="center" gap={2}>
                        <Icon svg={ActionDelete} color="error" />
                        <Text color="error">{t('PODashboard.RemovePO.ActionText')}</Text>
                      </View>
                    </Link>
                  </View>
                </View>
              </View.Item>

              <View.Item
                grow
                className={styles['po-detail__right-column']}
                attributes={{
                  'data-testid': 'po-details-right-column',
                }}
              >
                <View
                  direction="row"
                  padding={[4, 6]}
                  className={styles['po-detail__right-column__statistics']}
                >
                  {
                    // @todo : This need to be replaced when backend integration for labour hours added
                  }
                  {renderPOStatistic(
                    t('PODashboard.Stats.Received'),
                    calculatePercentageWithSign(
                      trailerOrderData.inboundOrder.totalOrderedQty,
                      trailerOrderData.inboundOrder.totalReceivedQty
                    ),
                    t('PODashboard.LinesData', {
                      count: trailerOrderData.inboundOrder.totalOrderedQty,
                      total: trailerOrderData.inboundOrder.totalReceivedQty,
                    })
                  )}
                  {renderPOStatistic(
                    t('PODashboard.Stats.PiecesFitInFPS'),
                    calculatePercentageWithSign(
                      trailerOrderData.inboundOrder.receivedLineCount,
                      trailerOrderData.inboundOrder.totalLineCount
                    ),
                    t('PODashboard.PicesData', {
                      count: trailerOrderData.inboundOrder.receivedLineCount,
                      total: trailerOrderData.inboundOrder.totalLineCount,
                    })
                  )}
                  {renderPOStatistic(
                    t('PODashboard.Stats.SKUsWithFPS'),
                    calculatePercentageWithSign(
                      trailerOrderData.inboundOrder.totalFpsLineCount,
                      trailerOrderData.inboundOrder.totalLineCount
                    ),
                    t('PODashboard.SkuWithFPSData', {
                      count: trailerOrderData.inboundOrder.totalFpsLineCount,
                      total: trailerOrderData.inboundOrder.totalLineCount,
                    })
                  )}
                  {renderPOStatistic(
                    t('PODashboard.Stats.ReceivingLaborHoursRemaining'),
                    t('PODashboard.Stats.Hours', { count: 0.53 })
                  )}
                </View>

                {isPOOnHold() && (
                  <View padding={[4, 6]}>
                    <Notification
                      {...WMSInlineNotification.snack}
                      title={t('PODashboard.PutOnHold.InlineNotificationTitle')}
                      text={t('PODashboard.PutOnHold.InlineNotificationText')}
                      ctaLabel={
                        <View direction="row" justify="center" gap={2}>
                          <View.Item>
                            <Text color="secondary" weight="medium" lineHeight="140">
                              {t('PODashboard.Actions.RemoveHold')}
                            </Text>
                          </View.Item>

                          <View.Item>
                            <Icon svg={ArrowRight} color="secondary" />
                          </View.Item>
                        </View>
                      }
                      ctaType="button"
                      ctaOnClick={() => setShowPOHoldModal(true)}
                    />
                  </View>
                )}

                <View
                  direction="row"
                  padding={[4, 6]}
                  className={styles['po-detail__right-column__search-wrapper']}
                >
                  <SearchBar
                    className={styles['po-detail__right-column__search-bar']}
                    label={t('PODashboard.Search.DetailsPlaceholder')}
                    maxMenuHeight={300}
                    onValueChange={setSearchValue}
                  />
                </View>

                <View>
                  <ProductTabs tabs={tabs} />
                </View>
              </View.Item>
            </View>

            {trailerArrivalEditData && (
              <>
                <EditPOModal
                  isOpen={showPOEditModal}
                  trailerInId={trailerOrderData.trailerInId}
                  trailerArrivalData={trailerArrivalEditData}
                  onClose={() => onCloseEdit()}
                />

                <RemovePOModal
                  soureOrderNumber={sourceOrderId || ''}
                  trailerInId={trailerOrderData.trailerInId}
                  trailerArrivalData={trailerArrivalEditData}
                  isOpen={showPORemoveModal}
                  onClose={() => onCloseRemove()}
                />

                <POStatusModal
                  soureOrderNumber={sourceOrderId || ''}
                  isOpen={showPOHoldModal}
                  previousStatus={inboundOrderData.trailerOrder.prevStatusCd || ''}
                  trailerInId={trailerOrderData.trailerInId}
                  trailerArrivalData={trailerArrivalEditData}
                  onClose={(status) => onClosePutPOHold(status)}
                />
              </>
            )}

            {trailerArrivalEditData && recommendedAssigneeData && (
              <AddEditAssigneesModal
                isOpen={showAddEditAssigneesModal}
                existingAssignees={trailerOrderData.users}
                recommendedAssignees={recommendedAssigneeData
                  ?.slice(0, maxRecommendedAssignee)
                  .map((assigneeItem) => ({
                    ...assigneeItem.users,
                  }))}
                trailerInId={trailerOrderData.trailerInId}
                trailerArrivalData={trailerArrivalEditData}
                onClose={() => onCloseAddEditAssignees()}
                onSubmit={() => onSubmitAssignees()}
              />
            )}
          </>
        )}
      </View>
    );
  }
};
