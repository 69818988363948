/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  ALPHA_NUMERIC_REGEX,
  ALPHA_NUMERIC_WITH_SPACE_REGEX,
  NUMERIC_REGEX,
} from '@shared/constants/regexConstants.ts';

export const isAlphaNumeric = (value: string, allowSpace?: boolean) => {
  if (allowSpace) {
    return ALPHA_NUMERIC_WITH_SPACE_REGEX.test(value);
  }

  return ALPHA_NUMERIC_REGEX.test(value);
};

export const isNumeric = (value: string) => {
  return NUMERIC_REGEX.test(value.toString());
};
