/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  Button,
  Divider,
  FormControl,
  Icon,
  Modal,
  Select,
  Text,
  TextField,
  View,
  classNames,
} from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';

import { ACTIONS, COMMODITY_TYPE, CONFIG, NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { LogError } from '@ofm/classes/LogError';
import { ErrorLogType } from '@ofm/constants/constants';
import { formatErrorLog, getFormInputError } from '@ofm/utils/utils';

import { PriorityToggle } from '@inbound/components/PriorityToggle/PriorityToggle';
import { Lengths, POActions } from '@inbound/constants/constants';
import { LOCATION_TYPES } from '@inbound/constants/dataConstants';
import { TrailerArrivalUpdateSchema } from '@inbound/schemas/trailerArrivalSchema';
import { useGetFacilityConfig } from '@inbound/services/hooks/useGetFacilityConfig';
import { useMutateTrailerArrival } from '@inbound/services/hooks/useMutateTrailerArrival';
import { FacilityConfigType, TrailerArrivalUpdateType } from '@inbound/types/types';
import {
  getFormAlphaNumericInputError,
  getFormAlphaNumericInputInvalidError,
} from '@inbound/utils/utils';

import * as T from './EditPOModal.types';
import s from './EditPOModal.module.scss';

export const EditPOModal = ({ isOpen, trailerInId, trailerArrivalData, onClose }: T.Props) => {
  /* State variables */
  const [commodityData, setCommodityData] = useState<FacilityConfigType>([]);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    reset,
    control,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset: poReset,
  } = useForm<TrailerArrivalUpdateType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(TrailerArrivalUpdateSchema),
  });

  /* Queries */
  const { isLoading, mutateTrailerArrival } = useMutateTrailerArrival();
  const { configsData } = useGetFacilityConfig(
    {
      domainCd: CONFIG,
      subDomainCd: COMMODITY_TYPE,
    },
    isOpen
  );

  /* Functions */
  const resetForm = (status: string | null) => {
    reset();
    clearErrors();
    onClose(status);
  };

  const onModalClose = () => {
    resetForm(ACTIONS.CLOSE);
  };

  const onSubmit = (data: TrailerArrivalUpdateType) => {
    const trailerArrivalResult = TrailerArrivalUpdateSchema.safeParse(data);

    if (!trailerArrivalResult.success) {
      throw new LogError(formatErrorLog(ErrorLogType.ZOD, trailerArrivalResult.error));
    } else {
      const payload = {
        ...data,
        trailerOrders: [
          {
            ...data.trailerOrders,
            priority: data.priority ? 1 : 0,
          },
        ],
      };

      // As we have priority toggle, we used boolean priority to complete UI functionality,
      // which is not required in API call, as API have number type priority, so removed it.
      delete payload.priority;

      mutateTrailerArrival(
        {
          trailerInId: trailerInId,
          operation: POActions.EDIT_DETAILS,
          trailerArrivalRequest: payload,
        },
        {
          onSuccess: () => {
            resetForm(ACTIONS.SUBMIT);

            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              t('PODashboard.Notification.UpdateTrilerArrival.Success', {
                poNumber: data.trailerOrders.sourceOrderNbr,
              })
            );
          },
        }
      );
    }
  };

  /* Hooks */
  useEffect(() => {
    poReset(trailerArrivalData);
  }, [trailerArrivalData, poReset]);

  useEffect(() => {
    if (configsData) {
      setCommodityData(configsData);
    }
  }, [configsData]);

  return (
    <Modal open={isOpen} onClose={() => onModalClose()} className={s['edit-po-modal']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <View className={s['edit-po-modal__header']}>
          <View direction="column">
            <Text as="h2" size="175" weight="bold" color="primary">
              {t('PODashboard.EditPODetails')}
            </Text>
          </View>
          <Button
            variant="ghost"
            onClick={onModalClose}
            className={classNames(s['close-icon'], s['edit-po-modal__header__close-button'])}
          >
            <Icon svg={Close} />
          </Button>
        </View>

        <View direction="column" className={s['edit-po-modal__body']}>
          <View.Item>
            <View direction="column" className={s['edit-po-modal__body__section']}>
              <View.Item>
                <Text
                  weight="medium"
                  size="100"
                  className={s['edit-po-modal__body__section__title']}
                >
                  {t('PODashboard.LocationDetails')}
                </Text>
              </View.Item>

              <View.Item>
                <View direction="column" className={s['edit-po-modal__body__section__item']}>
                  <Text
                    weight="medium"
                    size="087"
                    className={s['edit-po-modal__body__form-field-label--required']}
                  >
                    {t('PODashboard.CarrierName')}
                  </Text>
                  <FormControl hasError={!!errors.trailerCarrierName}>
                    <TextField
                      inputAttributes={{
                        placeholder: t('PODashboard.AddPO.EnterCarrierName'),
                        ...register('trailerCarrierName'),
                      }}
                      defaultValue=""
                    />
                    {errors.trailerCarrierName && (
                      <View direction="row" justify="space-between">
                        <FormControl.Error
                          className={s['edit-po-modal__body__form-field-error-messages']}
                        >
                          {getFormAlphaNumericInputError(errors.trailerCarrierName.type)}
                        </FormControl.Error>
                      </View>
                    )}
                  </FormControl>
                </View>
              </View.Item>

              <View.Item>
                <View direction="column" className={s['edit-po-modal__body__section__item']}>
                  <Text
                    weight="medium"
                    size="087"
                    className={s['edit-po-modal__body__form-field-label--required']}
                  >
                    {t('PODashboard.TrailerNumber')}
                  </Text>
                  <FormControl hasError={!!errors.trailerNbr}>
                    <TextField
                      inputAttributes={{
                        placeholder: t('PODashboard.AddPO.EnterTrailerNumber'),
                        ...register('trailerNbr'),
                      }}
                      defaultValue=""
                    />
                    {errors.trailerNbr && (
                      <View direction="row" justify="space-between">
                        <FormControl.Error
                          className={s['edit-po-modal__body__form-field-error-messages']}
                        >
                          {getFormAlphaNumericInputError(errors.trailerNbr.type)}
                        </FormControl.Error>
                      </View>
                    )}
                  </FormControl>
                </View>
              </View.Item>

              <View.Item>
                <View direction="row" gap={3}>
                  <View.Item columns={6}>
                    <View direction="column" className={s['edit-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['edit-po-modal__body__form-field-label--required']}
                      >
                        {t('PODashboard.LocationType')}
                      </Text>
                      <FormControl hasError={!!errors.trailerLocationTypeCd}>
                        <Controller
                          control={control}
                          name="trailerLocationTypeCd"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              label={t('PODashboard.LocationType')}
                              placeholder={t('PODashboard.AddPO.SelectLocationType')}
                              name="trailerLocationTypeCd"
                              variant="no-label"
                              options={LOCATION_TYPES.map((locationOption) => ({
                                label: locationOption.label,
                                value: locationOption.value,
                              }))}
                              required
                              value={{ label: value, value }}
                              inputAttributes={{ onBlur }}
                              onValueChange={(trailerLocationTypeCd) => {
                                setValue('trailerLocationId', '');
                                onChange(trailerLocationTypeCd?.value);
                              }}
                            />
                          )}
                        />
                        {errors.trailerLocationTypeCd && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['edit-po-modal__body__form-field-error-messages']}
                            >
                              {getFormInputError(errors.trailerLocationTypeCd.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>

                  <View.Item columns={6}>
                    <View direction="column" className={s['edit-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['edit-po-modal__body__form-field-label--required']}
                      >
                        {t('PODashboard.Location')}
                      </Text>

                      <FormControl hasError={!!errors.trailerLocationId}>
                        <TextField
                          defaultValue=""
                          inputAttributes={{
                            placeholder: t('PODashboard.AddPO.EnterLocation'),
                            ...register('trailerLocationId'),
                          }}
                        />

                        {errors.trailerLocationId && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['edit-po-modal__body__form-field-error-messages']}
                            >
                              {getFormAlphaNumericInputInvalidError(errors.trailerLocationId.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                </View>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View padding={[6, 0]}>
              <Divider color="300" />
            </View>
          </View.Item>

          <View.Item>
            <View direction="column" className={s['edit-po-modal__body__section']}>
              <View.Item>
                <Text
                  weight="medium"
                  size="100"
                  className={s['edit-po-modal__body__section__title']}
                >
                  {t('PODashboard.CommodityAndReceivingDoor')}
                </Text>
              </View.Item>

              <View.Item>
                <View direction="row" gap={3}>
                  <View.Item columns={6}>
                    <View direction="column" className={s['edit-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['edit-po-modal__body__form-field-label--required']}
                      >
                        {t('PODashboard.Commodity')}
                      </Text>
                      <FormControl hasError={!!errors.trailerOrders?.commodityTypeCd}>
                        <Controller
                          control={control}
                          name="trailerOrders.commodityTypeCd"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              label={t('PODashboard.Commodity')}
                              placeholder={t('PODashboard.AddPO.SelectCommodity')}
                              name="trailerOrders.commodityTypeCd"
                              variant="no-label"
                              options={commodityData.map((commodityOption) => ({
                                label: commodityOption.configValue,
                                value: commodityOption.configCd,
                              }))}
                              required
                              value={{ label: value, value }}
                              inputAttributes={{ onBlur }}
                              onValueChange={(commodity) => onChange(commodity?.value)}
                            />
                          )}
                        />
                        {errors.trailerOrders?.commodityTypeCd && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['edit-po-modal__body__form-field-error-messages']}
                            >
                              {getFormInputError(errors.trailerOrders?.commodityTypeCd.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>

                  <View.Item columns={6}>
                    <View direction="column" className={s['edit-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['edit-po-modal__body__form-field-label']}
                      >
                        {t('PODashboard.ReceivingDoor')}
                      </Text>
                      <FormControl hasError={!!errors.trailerOrders?.orderLocationId}>
                        <TextField
                          inputAttributes={{
                            placeholder: t('PODashboard.AddPO.EnterReceivingDoor'),
                            ...register('trailerOrders.orderLocationId'),
                          }}
                          defaultValue=""
                        />
                        {errors.trailerOrders?.orderLocationId && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['edit-po-modal__body__form-field-error-messages']}
                            >
                              {getFormAlphaNumericInputInvalidError(
                                errors.trailerOrders?.orderLocationId.type,
                                Lengths.RECEIVING_DOOR
                              )}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                </View>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View padding={[6, 0]}>
              <Divider color="300" />
            </View>
          </View.Item>

          <View.Item>
            <View direction="column" className={s['edit-po-modal__body__section']}>
              <View.Item>
                <Text
                  weight="medium"
                  size="100"
                  className={s['edit-po-modal__body__section__title']}
                >
                  {t('PODashboard.Priority')}
                </Text>
              </View.Item>

              <View.Item>
                <View direction="column" className={s['edit-po-modal__body__section__item']}>
                  <FormControl hasError={!!errors.priority}>
                    <Controller
                      control={control}
                      name="priority"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View direction="row" gap={4}>
                          <PriorityToggle
                            name="priority"
                            checked={value ? true : false}
                            inputAttributes={{ onBlur }}
                            onValueChange={(priority) => onChange(priority)}
                          />
                          <View justify="center" padding={[2, 0]}>
                            <Text weight="medium" size="087">
                              {t('PODashboard.HighPriorityPurchaseOrder')}
                            </Text>
                          </View>
                        </View>
                      )}
                    />
                    {errors.priority && (
                      <View direction="row" justify="space-between">
                        <FormControl.Error>
                          {getFormInputError(errors.priority.type)}
                        </FormControl.Error>
                      </View>
                    )}
                  </FormControl>
                </View>
              </View.Item>
            </View>
          </View.Item>
        </View>

        <View className={s['edit-po-modal__footer']}>
          <View
            width="100%"
            direction="row"
            justify="end"
            className={s['edit-po-modal__footer__actions']}
          >
            <Button
              variant="secondary"
              attributes={{ style: { width: 'fit-content' } }}
              onClick={onModalClose}
              loading={isLoading}
            >
              <Text>{t('Cancel')}</Text>
            </Button>

            <Button
              variant="primary"
              attributes={{ style: { width: 'fit-content' } }}
              type="submit"
              loading={isLoading}
            >
              <Text>{t('Update')}</Text>
            </Button>
          </View>
        </View>
      </form>
    </Modal>
  );
};
