/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const ReceivedLineSchema = z.object({
  receiveOrderLineKey: z.string(),
  productId: z.string(),
  locationId: z.string(),
  locationTypeCd: z.string(),
  uomCd: z.string(),
  receivedQty: z.number(),
  receivedTs: z.string().nullable(),
});

export const TrailerArrivalSchema = z.object({
  trailerArrivalKey: z.string(),
  trailerArrivalTs: z.string(),
  trailerCarrierName: z.string().optional().nullable(),
  trailerNbr: z.string().optional().nullable(),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string(),
  contactName: z.string().optional().nullable(),
  contactPhone: z.string().optional().nullable(),
  contactEmail: z.string().optional().nullable(),
});

export const TrailerUserSchema = z.object({
  trailerOrderUserKey: z.string().optional(),
  assignedUserId: z.string(),
  assignedUserName: z.string(),
  userStatusCd: z.string(),
  receivedLines: z.array(ReceivedLineSchema).optional().nullable(),
});

export const TrailerInboundOrderSchema = z.object({
  sourceOrderNbr: z.string(),
  vendorId: z.string(),
  domainTypeCd: z.string(),
  orderTypeCd: z.string(),
  statusCd: z.string(),
  totalOrderedQty: z.number(),
  totalReceivedQty: z.number(),
  totalWeight: z.number(),
  weightUomCd: z.string().optional().nullable(),
  totalLineCount: z.number(),
  receivedLineCount: z.number(),
  totalFpsLineCount: z.number(),
  shipToOrgId: z.string().optional().nullable(),
  shipToFacilityId: z.string().optional().nullable(),
  sourceCreateTs: z.string().optional().nullable(),
  vendorLocale: z
    .object({
      vendorId: z.string(),
      vendorName: z.string(),
    })
    .optional()
    .nullable(),
});

export const TrailerOrderSchema = z.object({
  trailerOrderKey: z.string(),
  sourceOrderNbr: z.string(),
  orderLocationId: z.string().optional().nullable(),
  commodityTypeCd: z.string(),
  statusCd: z.string(),
  trailerInId: z.string(),
  priority: z.number(),
  users: z.array(TrailerUserSchema),
  trailerArrival: TrailerArrivalSchema,
  inboundOrder: TrailerInboundOrderSchema,
});

export const TrailerOrderListSchema = z.object({
  trailerOrder: TrailerOrderSchema,
});
