/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  ThemeBgColor,
  ThemeFgColor,
  Grayscale,
  ThemeActionColor,
} from '@az/starc-ui/dist/types/global';

interface stateTextColorType {
  [x: string]: ThemeBgColor | ThemeFgColor | Grayscale | ThemeActionColor;
}

export const stateTextColor: stateTextColorType = {
  'un-assigned': 'primary',
  'not-started': 'secondary',
  'in-progress': 'primary',
  complete: 'secondary',
  error: 'error',
};

export const waves = [
  {
    id: '20181005',
    groupBy: 'Wave',
    pallets: 34,
    pieces: '825 / 3,349',
    stores: ['000115', '000114', '000113', '000112', '000111', '000110', '000109', '000108'],
  },
  {
    id: '20181005',
    groupBy: 'Wave',
    pallets: 34,
    pieces: '825 / 3,349',
    stores: ['000107', '000106', '000105', '000104', '00103', '000102', '000101', '000100'],
  },
];

export const routes = [
  {
    id: '0322',
    groupBy: 'Route',
    pallets: 26,
    pieces: '825 / 3,349',
    stores: ['000115', '000114', '000113', '000112', '000111', '000110'],
  },
  {
    id: '0321',
    groupBy: 'Route',
    pallets: 26,
    pieces: '825 / 3,349',
    stores: ['000109', '000108', '000107', '000106'],
  },
  {
    id: '0320',
    groupBy: 'Route',
    pallets: 26,
    pieces: '825 / 3,349',
    stores: ['000105', '000104', '00103', '000102', '000101', '000100'],
  },
];

export const release = [
  {
    stores: [
      '000115',
      '000114',
      '000113',
      '000112',
      '000111',
      '000110',
      '000109',
      '000108',
      '000107',
      '000106',
      '000105',
      '000104',
      '00103',
      '000102',
      '000101',
      '000100',
    ],
  },
];
