/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { ErrorResponse, LayoutRequest } from '@mdm/types/types.ts';
import { layoutService } from '@mdm/services/layoutService.ts';

export const useMutateLocation = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  let message = t('Errors.Action.Location');

  const {
    mutate: mutateLocation,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { layoutKey?: string; layoutRequest: LayoutRequest }) => {
      if (params.layoutKey) {
        message = t('Errors.Action.UpdateLocation');
        return layoutService.updateLayout(params.layoutKey, params.layoutRequest);
      } else {
        return layoutService.createLayout(params.layoutRequest);
      }
    },
    onError: (error: ErrorResponse) => {
      if (error?.message) {
        message = error.message;
      }
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateLocation, isLoading, isError };
};
