/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Props as TagProps } from '@shared/components/Tag/Tag.types';
export type TagItemsProps = { variant: TagProps['variant']; text: string };

export const COMBINED_PILL_TAB_DATA = [
  {
    id: 1,
    taskType: 'PO',
    vendorName: 'Motorcar Parts of America',
    priority: true,
    tagItems: [
      {
        variant: 'order',
        text: 'LTD',
      },
      {
        variant: 'order',
        text: 'OIL',
      },
    ] as TagItemsProps[],
    arrivalDate: '03/06/23 13:01',
    poNumber: '20730091',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Benze',
        lastName: 'Dips',
      },
      {
        id: 2,
        firstName: 'Anmmy',
        lastName: 'Anchor',
      },
    ],
    piecesData: {
      total: 380,
      count: 72,
      label: 'Received',
    },
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    compatibleVehicles: 'FANY, RANY',
    shift: '1st',
    goalHours: 5,
    carrier: 'US Express',
    trailer: 'TR2253025',
    location: 'DC Location',
  },
  {
    id: 2,
    taskType: 'PO',
    vendorName: 'Dana Limited',
    priority: true,
    tagItems: [
      {
        variant: 'order',
        text: 'PO',
      },
      {
        variant: 'order',
        text: 'BATT',
      },
    ] as TagItemsProps[],
    arrivalDate: '03/06/23 13:32',
    poNumber: '20730091',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Benze',
        lastName: 'Dips',
      },
      {
        id: 2,
        firstName: 'Anmmy',
        lastName: 'Anchor',
      },
    ],
    piecesData: {
      total: 380,
      count: 72,
      label: 'Received',
    },
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    compatibleVehicles: 'FANY, RANY',
    shift: '1st',
    goalHours: 5,
    carrier: 'US Express',
    trailer: 'TR2253025',
    location: 'DC Location',
  },
  {
    id: 3,
    taskType: 'PO',
    vendorName: 'Ring & Pinion Service Inc.',
    priority: true,
    tagItems: [
      {
        variant: 'order',
        text: 'DSD',
      },
      {
        variant: 'order',
        text: 'BATT',
      },
    ] as TagItemsProps[],
    arrivalDate: '03/06/23 14:32',
    poNumber: '20730349',
    rcNumber: '08',
    gate: 12,
    aisle: 10,
    piecesData: {
      total: 111,
      count: 0,
      label: 'Received',
    },
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    compatibleVehicles: 'FANY, RANY',
    shift: '1st',
    goalHours: 5,
    carrier: 'US Express',
    trailer: 'TR2253025',
    location: 'DC Location',
  },
  {
    id: 4,
    taskType: 'PO',
    vendorName: 'Mahle Clevite Inc.',
    priority: false,
    tagItems: [
      {
        variant: 'order',
        text: 'DSD',
      },
    ] as TagItemsProps[],
    arrivalDate: '03/06/23 09:01',
    poNumber: '20730091',
    rcNumber: '07',
    gate: 12,
    aisle: 8,

    piecesData: {
      total: 78,
      count: 0,
      label: 'Received',
    },
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    compatibleVehicles: 'FANY, RANY',
    shift: '1st',
    goalHours: 5,
    carrier: 'US Express',
    trailer: 'TR2253025',
    location: 'DC Location',
  },
];
