/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { View } from '@az/starc-ui';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  TableStylingVariants,
  ZONE_TABLE_COLUMNS,
} from '@shared/components/Table/tableConstants.ts';
import {
  TableSorting,
  ZoneListRowTypes,
  DIRECTION as TABLE_SORT_DIRECTION,
} from '@shared/components/Table/Table.types.ts';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { DEFAULT_PAGE, NOTIFICATION_TYPES, PAGE_SIZE } from '@shared/constants/constants.ts';
import { mapZoneTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { useGetZones } from '@mdm/services/hooks/useGetZones.tsx';
import { useDeleteZone } from '@mdm/services/hooks/useDeleteZone.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useTranslation } from 'react-i18next';

export const ZoneList = () => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('layoutName');

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const { mutateDeleteZone } = useDeleteZone();
  const { searchQuery } = useOutletContext<{ searchQuery?: string }>();
  const { zonesData, isFetching: isLoading } = useGetZones({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    searchQuery: searchQuery || '',
    sortBy: sortColumnId,
    direction: sortDirection,
  });

  /* Functions */
  const onViewDetails = (zone: ZoneListRowTypes) => {
    navigate(PAGE_URLS.ZONE_DETAILS(String(zone.layout.layoutDistinctName)));
  };

  const onDeleteZone = (zone: ZoneListRowTypes) => {
    if (zone.layout.layoutKey) {
      mutateDeleteZone({
        layoutKey: zone.layout.layoutKey,
      });
    }
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  /* Hooks */
  useEffect(() => {
    if (zonesData && zonesData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
    }
  }, [handleNotification, t, zonesData]);

  return (
    <View>
      <View padding={[4, 6]}>
        <Table
          columns={ZONE_TABLE_COLUMNS}
          rows={mapZoneTableRows(zonesData?.content || [], onViewDetails, onDeleteZone)}
          isPaginated={true}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          isLoading={isLoading}
          isApiLoadedData={true}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={zonesData?.totalPages || 0}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
          onSort={onSort}
        />
      </View>
    </View>
  );
};
