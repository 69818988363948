/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const InboundTrailerReceivedLineSchema = z.object({
  receiveOrderLineKey: z.string(),
  productId: z.string(),
  locationId: z.string(),
  locationTypeCd: z.string(),
  receivedQty: z.number(),
  uomCd: z.string().optional().nullable(),
  receivedTs: z.string().nullable(),
});

export const InboundTrailerLineSchema = z.object({
  productId: z.string(),
  totalOrderedQty: z.number(),
  totalReceivedQty: z.number(),
  productLocaleDescription: z.string().optional(),
  uomCd: z.string().optional(),
  statusCd: z.string().optional(),
  partNumberId: z.string().optional(),
  commodityTypeCd: z.string().optional(),
  receiveOrderLines: z.array(InboundTrailerReceivedLineSchema),
});

export const InboundTrailerOrderSchema = z.object({
  trailerOrder: z.object({
    trailerOrderKey: z.string(),
    sourceOrderNbr: z.string(),
    commodityTypeCd: z.string(),
    statusCd: z.string(),
    prevStatusCd: z.string().optional().nullable(),
    orderLocationTypeCd: z.string().optional(),
    orderLocationId: z.string().optional(),
    priority: z.number().optional(),
    inboundOrder: z.object({
      sourceOrderNbr: z.string(),
      vendorId: z.string(),
      domainTypeCd: z.string(),
      orderTypeCd: z.string(),
      statusCd: z.string(),
      totalOrderedQty: z.number(),
      totalReceivedQty: z.number(),
      totalLineCount: z.number(),
      receivedLineCount: z.number(),
      totalWeight: z.number().optional().nullable(),
      weightUomCd: z.string().optional().nullable(),
      shipToOrgId: z.string().optional(),
      shipToFacilityId: z.string().optional(),
      sourceCreateTs: z.string().optional().nullable(),
      vendorLocale: z
        .object({
          vendorId: z.string(),
          vendorName: z.string(),
        })
        .optional(),
      inboundOrderLines: z.array(InboundTrailerLineSchema),
    }),
  }),
});
