/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { GetLayoutParams } from '@mdm/types/types.ts';
import { apiService } from '@mdm/services/apiService.ts';
import { LAYOUT_ENDPOINTS } from '@shared/constants/routes.ts';
import {
  ENTITY_ASSOCIATIONS,
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
} from '@mdm/constants/constants.ts';
import { paginatedParseAndLog } from '@mdm/utils/utils.ts';
import { LayoutListSchema } from '@mdm/schemas/layoutSchema.ts';

export const locationService = {
  getLocations: async (options: GetLayoutParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        entityCd: LAYOUT_ENTITY_CODE.LOCATION,
        entityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
        entityAssociations: options?.entityAssociations || [
          ENTITY_ASSOCIATIONS.ATTRIBUTES,
          ENTITY_ASSOCIATIONS.CHILDREN,
        ],
        ...(options?.searchQuery && { layoutOrParentDistinctName: options.searchQuery }),
        ...(options?.parentLayoutDistinctName && {
          parentLayoutDistinctName: options.parentLayoutDistinctName,
        }),
      },
    };

    const response = await apiService.post(LAYOUT_ENDPOINTS.GET_LAYOUTS, payload);
    return paginatedParseAndLog(LayoutListSchema, response.data);
  },
  getLocationBySubzoneId: async (options: GetLayoutParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        entityCd: LAYOUT_ENTITY_CODE.LOCATION,
        entityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
        entityAssociations: options?.entityAssociations || [
          ENTITY_ASSOCIATIONS.ATTRIBUTES,
          ENTITY_ASSOCIATIONS.CHILDREN,
        ],
        parentLayoutDistinctName: options.parentLayoutDistinctName,
      },
    };

    const response = await apiService.post(LAYOUT_ENDPOINTS.GET_LAYOUTS, payload);
    return paginatedParseAndLog(LayoutListSchema, response.data);
  },
  getLocationByLayoutKey: async (layoutKey: string) => {
    const payload = {
      searchCriteria: {
        entityCd: LAYOUT_ENTITY_CODE.LOCATION,
        entityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
        entityAssociations: [ENTITY_ASSOCIATIONS.ATTRIBUTES],
        layoutDistinctName: layoutKey,
      },
      searchPage: {},
    };

    const response = await apiService.post(LAYOUT_ENDPOINTS.GET_LAYOUTS, payload);
    return paginatedParseAndLog(LayoutListSchema, response.data);
  },
};
