/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { View } from '@az/starc-ui';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  SUBZONE_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';

import {
  SubZoneListRowTypes,
  TableSorting,
  DIRECTION as TABLE_SORT_DIRECTION,
} from '@shared/components/Table/Table.types.ts';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  CONFIG,
  DEFAULT_PAGE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
} from '@shared/constants/constants.ts';
import { mapSubzoneTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import { useDeleteSubzone } from '@mdm/services/hooks/useDeleteSubzone.ts';
import { useGetFacilityConfig } from '@inbound/services/hooks/useGetFacilityConfig';
import { SelectOption } from '@mdm/types/types.ts';
import { SUBZONE_FIELD } from '@mdm/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

export const SubzoneList = () => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('layoutName');
  const [subzoneTypeOptions, setSubzoneTypeOptions] = useState<SelectOption[]>([]);
  const [pickMethodOptions, setPickMethodOptions] = useState<SelectOption[]>([]);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const { mutateDeleteSubzone } = useDeleteSubzone();
  const { searchQuery } = useOutletContext<{ searchQuery?: string }>();
  const { subzonesData, isFetching: isLoading } = useGetSubzones({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    searchQuery: searchQuery || '',
    sortBy: sortColumnId,
    direction: sortDirection,
  });

  const { configsData } = useGetFacilityConfig({
    domainCd: CONFIG,
  });

  /* Functions */
  const onViewDetails = (subzone: SubZoneListRowTypes) => {
    navigate(PAGE_URLS.SUB_ZONE_DETAILS(String(subzone.layout.layoutDistinctName)));
  };

  const onDeleteSubzone = (subzone: SubZoneListRowTypes) => {
    if (subzone.layout.layoutKey) {
      mutateDeleteSubzone({
        layoutKey: subzone.layout.layoutKey,
      });
    }
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  /* Hooks */
  useEffect(() => {
    if (subzonesData && subzonesData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
    }
  }, [handleNotification, t, subzonesData]);

  useEffect(() => {
    const pickMethods = (configsData || [])
      .filter((config) => config.subDomainCd === SUBZONE_FIELD.PICK_METHOD)
      .map((config) => ({
        label: config.configValue,
        value: config.configCd,
      }));
    setPickMethodOptions(pickMethods);

    const subzoneTypes = (configsData || [])
      .filter((config) => config.subDomainCd === SUBZONE_FIELD.SUBZONE_TYPE)
      .map((config) => ({
        label: config.configValue,
        value: config.configCd,
      }));

    setSubzoneTypeOptions(subzoneTypes);
  }, [configsData]);

  return (
    <View>
      <View padding={[4, 6]}>
        <Table
          columns={SUBZONE_TABLE_COLUMNS}
          rows={mapSubzoneTableRows(subzonesData?.content || [], onViewDetails, onDeleteSubzone, {
            subzoneTypeOptions,
            pickMethodOptions,
          })}
          isPaginated={true}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          isLoading={isLoading}
          isApiLoadedData={true}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={Math.ceil((subzonesData?.totalElements || DEFAULT_PAGE - 1) / PAGE_SIZE)}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
          onSort={onSort}
        />
      </View>
    </View>
  );
};
