/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FACILITY_CONFIG_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@inbound/services/apiService.ts';
import { FacilityConfigSchema } from '@inbound/schemas/facilityConfigSchema';
import { parseAndLog } from '@inbound/utils/service/serviceUtils';
import { GetFacilityConfigParams } from '@inbound/types/service.type';

export const facilityConfigService = {
  getFacilityConfig: async (options?: GetFacilityConfigParams) => {
    const payload = {
      domainCd: options?.domainCd,
      ...(options?.subDomainCd && { subDomainCd: options.subDomainCd }),
      ...(options?.configCd && { configCd: options.configCd }),
    };

    const response = await apiService.post(FACILITY_CONFIG_ENDPOINTS.GET_FACILITY_CONFIG, payload);

    return parseAndLog(FacilityConfigSchema, response.data);
  },
};
