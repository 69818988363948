/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Button, Dropdown, Text, SearchBar, Actionable } from '@az/starc-ui';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { COMBINED_PILL_TAB_DATA } from '@taskManagement/components/CombinedPillTabs/CombinedData';
import { TASK_TYPE_TAB } from '@taskManagement/constants/constants';
import { MasterCard } from '@inbound/components/MasterCard/MasterCard';
import { AssignTaskModal } from '@taskManagement/components/AssignTaskModal/AssignTaskModal';
import { CombinedPillTabsProps, TabsFilterDataProps } from './CombinedPillTabs.types';
import styles from './CombinedPillTabs.module.scss';

export const CombinedPillTabs = ({ TabsFilterData, userName }: TabsFilterDataProps) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(TabsFilterData[0]);
  const MaxTabCount = TabsFilterData.length;

  const [OpenTaskConfirmModal, setOpenTaskConfirmModal] = useState<boolean>(false);
  const [AssignPOData, setAssignPOData] = useState<CombinedPillTabsProps>();

  const onClickAssignCurrentTask = (poData: CombinedPillTabsProps) => {
    setOpenTaskConfirmModal(true);
    setAssignPOData(poData);
  };

  const CloseConfirmationModal = () => {
    setOpenTaskConfirmModal(false);
  };

  const { handleNotification } = useNotificationHandler();
  const onShowNotification = () => {
    setOpenTaskConfirmModal(false);
    handleNotification(
      NOTIFICATION_TYPES.SUCCESS,
      t(`AssignTaskConfirmationModal.OnSuccess`, {
        userName: userName,
      })
    );
  };

  return (
    <>
      <View
        gap={2}
        direction="row"
        align="center"
        attributes={{ 'data-testid': 'st-combined-pill-tabs' }}
      >
        {TabsFilterData.slice(0, 3).map((filter) => (
          <>
            <View.Item>
              <Button
                className={styles['combined-pill-tabs__button']}
                onClick={() => setSelectedTab(filter)}
                variant={selectedTab.value === filter.value ? 'pill' : 'pill-secondary'}
                attributes={{ style: { width: 'fit-content' } }}
              >
                {t(filter.value)}
              </Button>
            </View.Item>
          </>
        ))}
        <View.Item>
          <Dropdown className={styles['combined-pill-tabs__dropdown']}>
            {selectedTab.value === t(TASK_TYPE_TAB.OUTBOUND) ||
            selectedTab.value === t(TASK_TYPE_TAB.INVENTORY_CONTROL) ||
            selectedTab.value === t(TASK_TYPE_TAB.GENERAL_TASKS) ? (
              <Dropdown.Button
                className={[
                  styles['combined-pill-tabs__dropdown-button'],
                  selectedTab.value ? styles['combined-pill-tabs__selected_button'] : '',
                ]}
              >
                <View align="center" gap={0} justify="center">
                  {selectedTab.value}
                </View>
              </Dropdown.Button>
            ) : (
              <Dropdown.Button className={[styles['combined-pill-tabs__dropdown-button']]}>
                <View align="center" gap={0}>
                  {t('MoreOptions')}
                </View>
              </Dropdown.Button>
            )}
            <Dropdown.Content className={styles['combined-pill-tabs__dropdown-content']}>
              {TabsFilterData.slice(3, MaxTabCount).map((filter) => (
                <Button
                  className={styles['combined-pill-tabs__content-button']}
                  onClick={() => setSelectedTab(filter)}
                  variant={selectedTab.value === filter.value ? 'pill' : 'pill-secondary'}
                  attributes={{ style: { width: 'fit-content' } }}
                >
                  {t(filter.value)}
                </Button>
              ))}
            </Dropdown.Content>
          </Dropdown>
        </View.Item>
      </View>
      {selectedTab.value === t(TASK_TYPE_TAB.POS) ||
      selectedTab.value === t(TASK_TYPE_TAB.REPLENISHMENTS) ? (
        <SearchBar
          className={styles['combined-pill-tabs__search']}
          label={t(`SearchDrawerPlaceholder`)}
          maxMenuHeight={300}
        />
      ) : (
        ''
      )}
      <View gap={2} align="start" width="100%">
        {selectedTab.value === t(TASK_TYPE_TAB.POS) && (
          <View direction="column" gap={2}>
            {COMBINED_PILL_TAB_DATA?.filter((data) => data.taskType == t('TaskType.Po')).map(
              (poData) => (
                <Actionable
                  onClick={() => onClickAssignCurrentTask(poData)}
                  className={styles['combined-tabs__actionable-card']}
                >
                  <MasterCard
                    title={poData.vendorName}
                    priority={poData.priority}
                    tagItems={poData.tagItems}
                    label={t('PoTask.Arrival', { date: poData.arrivalDate })}
                    detailsData={{
                      data: [
                        {
                          label: t('PoTask.PO', { poNumber: poData.poNumber }),
                        },
                        {
                          label: t('PoTask.RC', { rcNumber: poData.rcNumber }),
                        },
                        {
                          label: t('PoTask.Gate', { gateNumber: poData.gate }),
                        },
                        {
                          label: t('PoTask.Aisle', { aisleNumber: poData.aisle }),
                        },
                      ],
                    }}
                    {...(poData.users
                      ? {
                          avatarGroupData: {
                            users: poData.users && poData.users.length > 0 ? poData.users : [],
                            size: 'small',
                            maxDisplay: 2,
                          },
                        }
                      : {})}
                    piecesData={{
                      count: poData.piecesData.count,
                      total: poData.piecesData.total,
                      label: poData.piecesData.label,
                    }}
                    linesData={poData.linesData}
                  />
                </Actionable>
              )
            )}
          </View>
        )}
        {selectedTab.value === t(TASK_TYPE_TAB.RECOMMENDED) && t(TASK_TYPE_TAB.POS) && (
          <View direction="column" gap={2}>
            {COMBINED_PILL_TAB_DATA?.filter((data) => data.taskType == t('TaskType.Po'))
              .slice(0, 3)
              .map((poData) => (
                <Actionable
                  onClick={() => onClickAssignCurrentTask(poData)}
                  className={styles['combined-tabs__actionable-card']}
                >
                  <MasterCard
                    title={poData.vendorName}
                    priority={poData.priority}
                    tagItems={poData.tagItems}
                    label={t('PoTask.Arrival', { date: poData.arrivalDate })}
                    detailsData={{
                      data: [
                        {
                          label: t('PoTask.PO', { poNumber: poData.poNumber }),
                        },
                        {
                          label: t('PoTask.RC', { rcNumber: poData.rcNumber }),
                        },
                        {
                          label: t('PoTask.Gate', { gateNumber: poData.gate }),
                        },
                        {
                          label: t('PoTask.Aisle', { aisleNumber: poData.aisle }),
                        },
                      ],
                    }}
                    {...(poData.users
                      ? {
                          avatarGroupData: {
                            users: poData.users && poData.users.length > 0 ? poData.users : [],
                            size: 'small',
                            maxDisplay: 2,
                          },
                        }
                      : {})}
                    piecesData={{
                      count: poData.piecesData.count,
                      total: poData.piecesData.total,
                      label: poData.piecesData.label,
                    }}
                    linesData={poData.linesData}
                  />
                </Actionable>
              ))}
          </View>
        )}
        {selectedTab.value === t(TASK_TYPE_TAB.REPLENISHMENTS) && (
          <>
            <View padding={0} direction="row">
              <Text>{selectedTab.value}</Text>
            </View>
          </>
        )}
        {selectedTab.value === t(TASK_TYPE_TAB.GENERAL_TASKS) && (
          <>
            <View gap={2} direction="row" align="start">
              <Text size="112" variant="display-4">
                {t('GeneralTask.AssignGeneralTask')}
              </Text>
              <Text> {t('GeneralTask.Subtitle')}</Text>
            </View>
            <View width="100%" direction="row" align="center" justify="center">
              <Button
                fullWidth={true}
                className={styles['combined-pill-tabs__general_button']}
                variant="secondary"
              >
                {t('GeneralTask.CreateGeneralTask')}
              </Button>
            </View>
          </>
        )}
      </View>

      <AssignTaskModal
        isOpen={OpenTaskConfirmModal}
        onClose={CloseConfirmationModal}
        POModalData={AssignPOData}
        onSuccess={() => onShowNotification()}
        userName={userName}
      />
    </>
  );
};
