/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const newStoresAndBackupHeaderData = [
  {
    id: '20',
    groupBy: 'DC',
    pallets: 34,
    pieces: '825 / 3,349',
    stores: ['000115', '000114', '000113', '000112', '000111', '000110', '000109', '000108'],
  },
  {
    id: '21',
    groupBy: 'DC',
    pallets: 34,
    pieces: '825 / 3,349',
    stores: ['000107', '000106', '000105', '000104', '00103', '000102', '000101', '000100'],
  },
];

export const newStoresAndBackupLocationsData = [
  {
    location: 'LTD',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 1, state: 'complete' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
];
