/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Actionable,
  Add,
  Button,
  Dropdown,
  Icon,
  SearchBar,
  View,
  Text,
  Skeleton,
  useDebounce,
} from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import styles from './ZoneSubzoneHeader.module.scss';
import { Download } from '@shared/assets/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes.ts';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { CombinedTabs } from '@shared/components/CombinedTabs/CombinedTabs.tsx';
import { Tab } from '@shared/components/CombinedTabs/CombinedTabs.types.ts';
import { useGetZones } from '@mdm/services/hooks/useGetZones.tsx';
import { DEBOUNCE_TIMER, DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';

export const ZoneSubzoneHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [value, setValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQueryValue = useDebounce(searchQuery, DEBOUNCE_TIMER);
  const breadcrumbs = useBreadcrumb();

  const { zonesData, isFetching: isZoneCountLoading } = useGetZones({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: PAGE_SIZE,
    entityAssociations: [],
  });

  const { subzonesData, isFetching: isSubzoneCountLoading } = useGetSubzones({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: PAGE_SIZE,
    entityAssociations: [],
  });

  const tabs: Array<Tab> = [
    {
      name: t('Zones'),
      value: ROUTES.ZONE_LIST,
      numberOfItems: 0,
    },
    {
      name: t('Subzones'),
      value: ROUTES.SUB_ZONE_LIST,
      numberOfItems: 0,
    },
  ];

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      setSearchParam(inputValue);
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
  };

  return (
    <>
      <View
        className={styles['zone-subzone-header']}
        attributes={{
          'data-testid': 'zone-subzone-header',
        }}
      >
        <MasterTitle
          title={t('MasterTitle.ZonesAndSubZones')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
        >
          <View
            attributes={{
              'data-testid': 'zone-subzone-search',
            }}
            className={styles['zone-subzone-search']}
            direction="row"
            justify="end"
            align="center"
            gap={4}
          >
            <View.Item columns={6}>
              <SearchBar
                value={value}
                onValueChange={setValue}
                suggestions={[]}
                className={styles['search-bar']}
                label={t('Search.ZoneOrSubZone')}
                onValueClear={onClearSearch}
                inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
                maxMenuHeight={300}
              />
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text>{t('Download')}</Text>
                    </View>
                  </Button>
                </View.Item>

                <View.Item>
                  <Dropdown
                    variant="ghost"
                    padding={3}
                    className={styles['zone-subzone-header__create-actions']}
                  >
                    <Dropdown.Button
                      className={styles['zone-subzone-header__create-actions__button']}
                    >
                      <Button size="large">
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Icon svg={Add} color="secondary" />
                          <Text>{t('Create')}</Text>
                        </View>
                      </Button>
                    </Dropdown.Button>
                    <Dropdown.Content
                      className={styles['zone-subzone-header__create-actions__content']}
                    >
                      <View>
                        <View.Item
                          className={styles['zone-subzone-header__create-actions__content__item']}
                        >
                          <Actionable onClick={() => navigate(PAGE_URLS.ZONE_CREATE)}>
                            <Text>{t('Zone')}</Text>
                          </Actionable>
                        </View.Item>
                        <View.Item
                          className={styles['zone-subzone-header__create-actions__content__item']}
                        >
                          <Actionable onClick={() => navigate(PAGE_URLS.SUB_ZONE_CREATE)}>
                            <Text>{t('Subzone')}</Text>
                          </Actionable>
                        </View.Item>
                      </View>
                    </Dropdown.Content>
                  </Dropdown>
                </View.Item>
              </View>
            </View.Item>
          </View>
          <View className={styles['sub-title']} align="end" direction="row" gap={4}>
            <View>
              <Text size="100" color="600">
                {`${t('TotalZones')}: `}
                {isZoneCountLoading ? (
                  <Skeleton className={styles['count-wrapper']} width="100px" height="24px" />
                ) : (
                  zonesData?.totalElements || 0
                )}
              </Text>
            </View>
            <View>
              <Text size="100" color="600">
                {`${t('TotalSubzones')}: `}
                {isSubzoneCountLoading ? (
                  <Skeleton className={styles['count-wrapper']} width="100px" height="24px" />
                ) : (
                  subzonesData?.totalElements || 0
                )}
              </Text>
            </View>
          </View>
        </MasterTitle>
      </View>

      <View className={styles['zone-subzone-header__combined_tabs']}>
        <CombinedTabs tabs={tabs} rootPath={PAGE_URLS.LOCATION_MANAGER} />
      </View>

      <Outlet context={{ searchQuery: debouncedSearchQueryValue }} />
    </>
  );
};
