/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Icon, SearchBar, Select, Skeleton, Text, useDebounce, View } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { Download } from '@shared/assets/icons';
import { Add } from '@az/starc-ui-icons';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { CONSOLIDATION_LOCATION_FIELD, SEARCH_MENU_MAX_HEIGHT } from '@mdm/constants/constants.ts';

import styles from './ConsolidationLocationList.module.scss';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  CONSOLIDATION_LOCATION_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';
import { mapConsolidationLocationTableRows } from '@mdm/utils/table/tableUtils.tsx';
import {
  CONFIG,
  DEBOUNCE_TIMER,
  DEFAULT_PAGE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
} from '@shared/constants/constants.ts';
import {
  DIRECTION as TABLE_SORT_DIRECTION,
  LayoutListRowTypes,
  TableSorting,
} from '@shared/components/Table/Table.types.ts';
import { useGetZones } from '@mdm/services/hooks/useGetZones.tsx';
import { useGetConsolidationLocations } from '@mdm/services/hooks/useGetConsolidationLocations.tsx';

import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { useDeleteConsolidationLocation } from '@mdm/services/hooks/useDeleteConsolidationLocation.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

export const ConsolidationLocationList = () => {
  /* State */
  const [zoneFilterOptions, setZoneFilterOptions] = useState<SelectOption[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('layoutName');
  const [locationTypeOptions, setLocationTypeOptions] = useState<SelectOption[]>([]);

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQueryValue = useDebounce(searchQuery, DEBOUNCE_TIMER);

  const [value, setValue] = useState<string>('');

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();

  const { mutateDeleteLocation } = useDeleteConsolidationLocation();

  const { locationsData: locationCountData, isFetching: isLocationCountLoading } =
    useGetConsolidationLocations({
      currentPage: DEFAULT_PAGE - 1,
      pageSize: PAGE_SIZE,
      entityAssociations: [],
    });

  const { locationsData, isFetching: isLoading } = useGetConsolidationLocations({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    searchQuery: debouncedSearchQueryValue || '',
    sortBy: sortColumnId,
    direction: sortDirection,
  });

  const { zonesData, isFetching: isZonesLoading } = useGetZones({
    currentPage: DEFAULT_PAGE - 1,
    entityAssociations: [],
  });

  const { configsData, isLoading: isConfigDataLoading } = useGetFacilityConfig({
    domainCd: CONFIG,
  });

  /* Functions */
  const onViewDetails = (location: LayoutListRowTypes) => {
    navigate(PAGE_URLS.CONSOLIDATION_LOCATION_DETAILS(String(location.layout.layoutDistinctName)));
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  const onDeleteLocation = (location: LayoutListRowTypes) => {
    if (location.layout.layoutKey) {
      mutateDeleteLocation({
        layoutKey: location.layout.layoutKey,
      });
    }
  };

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      if (inputValue) {
        setSearchParam(inputValue);
      }
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
  };

  /* Hooks */
  useEffect(() => {
    if (locationsData && locationsData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
    }
  }, [handleNotification, t, locationsData]);

  useEffect(() => {
    let zoneOptions = [
      {
        label: t('AllZones'),
        value: '',
      },
    ];

    if (zonesData?.content) {
      zoneOptions = zoneOptions.concat(
        zonesData.content.map((row) => ({
          label: row.layout.layoutName,
          value: row.layout.layoutDistinctName,
        }))
      );
    }

    setZoneFilterOptions(zoneOptions);
  }, [t, zonesData]);

  useEffect(() => {
    const locationTypes = (configsData || [])
      .filter((config) => config.subDomainCd === CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE)
      .map((config) => ({
        label: config.configValue,
        value: config.configCd,
      }));

    setLocationTypeOptions(locationTypes);
  }, [configsData]);

  const SubTitle = () => {
    return (
      <View direction="row" align="center" gap={4}>
        <View.Item>
          <Text size="100" color="600">
            {`${t('TotalConsolidationLocations')}: `}
            {isLocationCountLoading ? (
              <Skeleton className={styles['location__count-wrapper']} width="100px" height="24px" />
            ) : (
              locationCountData?.totalElements || 0
            )}
          </Text>
        </View.Item>
      </View>
    );
  };

  return (
    <>
      <View className={styles['consolidation-location-list']}>
        <MasterTitle
          title={t('MasterTitle.ConsolidationLocations')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
          subtitle={<SubTitle />}
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item columns={6}>
              <SearchBar
                className={styles['search-bar']}
                value={value}
                label={t('Search.ConsolidationLocation')}
                onValueChange={setValue}
                onValueClear={onClearSearch}
                inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
                maxMenuHeight={SEARCH_MENU_MAX_HEIGHT}
              />
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text>{t('Download')}</Text>
                    </View>
                  </Button>
                </View.Item>

                <View.Item>
                  <Button
                    size="large"
                    onClick={() => navigate(PAGE_URLS.CONSOLIDATION_LOCATION_CREATE)}
                  >
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Add} color="secondary" />
                      <Text>{t('Create')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View padding={[4, 6]}>
          <>
            <View direction="row" gap={4}>
              <View.Item columns={{ s: 12, l: 3 }}>
                {isZonesLoading ? (
                  <Skeleton width="200px" height="50px" />
                ) : (
                  <Select
                    label=""
                    variant="no-label"
                    placeholder={t('AllZones')}
                    name="zoneFilter"
                    options={zoneFilterOptions}
                  />
                )}
              </View.Item>

              <View.Item columns={{ s: 12, l: 3 }}>
                {isConfigDataLoading ? (
                  <Skeleton width="200px" height="50px" />
                ) : (
                  <Select
                    label=""
                    variant="no-label"
                    placeholder={t('AllLocationTypes')}
                    name="locationTypeFilter"
                    options={locationTypeOptions}
                  />
                )}
              </View.Item>
            </View>

            <View className={styles['consolidation-location-list__table-wrapper']}>
              <Table
                columns={CONSOLIDATION_LOCATION_TABLE_COLUMNS}
                rows={mapConsolidationLocationTableRows(
                  locationsData?.content || [],
                  onViewDetails,
                  onDeleteLocation,
                  {
                    locationTypeOptions,
                  }
                )}
                isPaginated={true}
                isCheckboxDisabled={false}
                pageSize={PAGE_SIZE}
                defaultPage={DEFAULT_PAGE}
                isCreditItem={false}
                isCheckboxTable={false}
                styleVariant={TableStylingVariants.DETAILS}
                isLoading={isLoading}
                isApiLoadedData={true}
                totalPages={locationsData?.totalPages || 0}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                onSort={onSort}
              />
            </View>
          </>
        </View>
      </View>
    </>
  );
};
