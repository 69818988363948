/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import { ProductStatus } from '@ofm/constants/constants';
import { MOCK_WILL_CALL_ITEM_OPTIONS } from '@ofm/constants/dataConstants';
import { WillCallRowTypes } from './Table.types';

const STORE_ERROR_ROWS = [
  {
    storeId: 94794,
    errorStatus: 'Store_Connnect_Failed',
    attempts: 3,
    created: new Date('06/20/23 08:22:13'),
    lastAttempted: new Date('06/25/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94794,
    errorStatus: 'Store_Connnect_Failed',
    attempts: 7,
    created: new Date('06/21/23 08:22:13'),
    lastAttempted: new Date('06/24/23 10:30:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94794,
    errorStatus: 'Store_Connnect_Failed',
    attempts: 5,
    created: new Date('06/07/23 08:22:13'),
    lastAttempted: new Date('06/23/23 08:59:40'),
    link: 'View Order Details',
  },
];

const CSR_ERROR_ROWS = [
  {
    storeId: 94781,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94782,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 10:30:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94783,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94784,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94785,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
];

const ORDER_DETAILS_ROWS = [
  {
    sku: '000991131',
    description: 'Polyurethane Wheels Lorem',
    partNumber: 'TMK-2.5',
    status: ProductStatus.CLEARANCE,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
      {
        warehouseId: '600',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
      {
        warehouseId: '900',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '111',
  },
  {
    sku: '000991132',
    description: 'Polyurethane Wheels X',
    partNumber: 'TMK-2.5',
    status: ProductStatus.SELL_THROUGH,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '222',
  },
  {
    sku: '000991133',
    description: 'Polyurethane Wheels',
    partNumber: 'TMK-2.5',
    status: ProductStatus.SELL_THROUGH,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '333',
  },
  {
    sku: '000991134',
    description: 'Polyurethane Wheels',
    partNumber: 'TMK-2.5',
    status: ProductStatus.NEW,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '444',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '555',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '666',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '777',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '888',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '999',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '123',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '321',
  },
];

const STORE_DETAILS_ROWS = [
  {
    orderId: '1',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '2',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '3',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '4',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '5',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '6',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '7',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '8',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '9',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '10',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '11',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '12',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '13',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '14',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '15',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '16',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '17',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '18',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '19',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '20',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '21',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '22',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '23',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '24',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '25',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '26',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '27',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '28',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '29',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '40',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '30',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '31',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '32',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '33',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '34',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '35',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '36',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '37',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '38',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '39',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
];

const WILL_CALL_ROW_ADDITIONAL_DATA = {
  pack: 10,
  upc: '111',
  planogramId: '123',
  selectedWarehouse: {
    value: 'DC 20 - Primary',
    label: 'DC 20 - Primary',
    warehouseId: '20',
    warehouseName: 'abc',
    quantityOnHand: 800,
    minimumPack: 2,
  },
  warehouseDetails: [
    {
      value: 'DC 20 - Primary',
      label: 'DC 20 - Primary',
      warehouseId: '20',
      warehouseName: 'abc',
      quantityOnHand: 800,
      minimumPack: 2,
    },
    {
      value: 'DC 40',
      label: 'DC 40',
      warehouseId: '40',
      warehouseName: 'abc',
      quantityOnHand: 800,
      minimumPack: 1,
    },
    {
      value: 'DC 48',
      label: 'DC 48',
      warehouseId: '48',
      warehouseName: 'abc',
      quantityOnHand: 800,
      minimumPack: 10,
      disabled: true,
    },
  ],
  quantityCurrent: 20,
  numberOfPacksCurrent: 2,
  isRowBeingEdited: false,
};

const WILL_CALL_TABLE_ROWS: WillCallRowTypes[] = MOCK_WILL_CALL_ITEM_OPTIONS.map((item) => {
  return {
    sku: item.sku,
    partNumber: item.partNumber,
    description: item.name,
    status: ProductStatus.SELL_THROUGH,
    ...WILL_CALL_ROW_ADDITIONAL_DATA,
  };
});

const WAREHOUSE_CONFIGURATION_TABLE_ROWS = [
  {
    id: 1,
    dcNumber: 10,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 2,
    dcNumber: 15,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 3,
    dcNumber: 19,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '',
  },
  {
    id: 4,
    dcNumber: 22,
    address: '77 Rush St, Lexington, TN 38351, USA',
    description: 'Lexington Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 5,
    dcNumber: 25,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '',
  },
];

const ZONE_TABLE_ROWS = [
  {
    id: 1,
    zoneId: 'AR',
    totalSubzones: 5,
    description: 'Aerosol Room',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 87,
      },
    ],
  },
  {
    id: 2,
    zoneId: 'AU',
    totalSubzones: 1,
    description: 'Shuttle',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 145,
      },
    ],
  },
  {
    id: 3,
    zoneId: 'BA',
    totalSubzones: 6,
    description: 'Aisles 1-6',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 15,
      },
    ],
  },
  {
    id: 4,
    zoneId: 'BR',
    totalSubzones: 3,
    description: 'Aisles 11-15',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 16,
      },
    ],
  },
  {
    id: 5,
    zoneId: 'CR',
    totalSubzones: 7,
    description: 'CR',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 17,
      },
    ],
  },
  {
    id: 6,
    zoneId: 'FM',
    totalSubzones: 6,
    description: 'Selective Rack',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 90,
      },
    ],
  },
  {
    id: 7,
    zoneId: 'LTD',
    totalSubzones: 3,
    description: 'Long Tail Distribution',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 140,
      },
    ],
  },
  {
    id: 8,
    zoneId: 'MZ',
    totalSubzones: 8,
    description: 'Recall mezzanine',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 120,
      },
    ],
  },
  {
    id: 9,
    zoneId: 'NS',
    totalSubzones: 0,
    description: 'Not slotted yet',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 55,
      },
    ],
  },
  {
    id: 10,
    zoneId: 'OP',
    totalSubzones: 5,
    description: 'Old Pick Mod 2',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 76,
      },
    ],
  },
  {
    id: 11,
    zoneId: 'MS',
    totalSubzones: 5,
    description: 'Recall mezzanine',
    attributes: [
      {
        name: 'MAP_SEQUENCE',
        value: 98,
      },
    ],
  },
];

const SUBZONE_TABLE_ROWS = [
  {
    id: 1,
    name: 'ARP1',
    zoneName: 'AR',
    pickRouteType: 'ZPICK',
    totalLocations: 5,
    totalVehicles: 14,
    type: 'FWP',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: true,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDC',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP1',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP1',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 2,
    name: 'ARP2',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 5,
    totalVehicles: 13,
    type: 'FWP',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDB',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP2',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP2',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 3,
    name: 'ARP3',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 5,
    totalVehicles: 12,
    type: 'FWP',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDD',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP3',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP3',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 4,
    name: 'ARP4',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 5,
    totalVehicles: 12,
    type: 'FWP',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDC',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP4',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP4',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 5,
    name: 'ARP5',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 5,
    totalVehicles: 12,
    type: 'FWP',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDC',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP5',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP5',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 6,
    name: 'ARP6',
    zoneName: 'AR',
    pickRouteType: 'Standard',
    totalLocations: 5,
    totalVehicles: 19,
    type: 'RS',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDB',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP6',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP6',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 7,
    name: 'ARP7',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 4,
    totalVehicles: 6,
    type: 'FWP',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDD',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP7',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP7',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 8,
    name: 'ARP8',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 5,
    totalVehicles: 8,
    type: 'RS',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDC',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP8',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP8',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 9,
    name: 'ARP9',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 9,
    totalVehicles: 7,
    type: 'FWP',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDC',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP9',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP9',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 10,
    name: 'ARP10',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 6,
    totalVehicles: 7,
    type: 'RS',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDC',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP10',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP10',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
  {
    id: 11,
    name: 'ARP11',
    zoneName: 'AR',
    pickRouteType: 'Aisle Sweep',
    totalLocations: 8,
    totalVehicles: 12,
    type: 'RS',
    description: 'Aerosol room plt. flows',
    containerType: 'Regular Pallet',
    pickMethod: 'STD',
    labelSort: 'Aisle Sweep',
    skuPickClass: 'Broken Case',
    pickDropLocation: 'DEFAULT',
    putDropLocation: 'DEFAULT',
    isMultiplePartsAllowedWithinLocation: 'true',
    isMezzanine: 'false',
    packMethod: '',
    isConveyorFlag: false,
    isItemVerify: false,
    hasBidirectionalPick: false,
    itemDescriptionPrompt: false,
    stockRoom: 'LXDC',
    pickPriority: 0,
    putAwayPriority: 0,
    layout: {
      layoutName: 'ARP11',
      layoutNameDesc: 'Aerosol room plt. flows',
      layoutDistinctName: 'ARP11',
      entityCd: 'SZ',
      entityTypeCd: 'DEFAULT',
      active: true,
      attributes: [
        {
          name: 'CATEGORY_LABEL',
          value: 'CAT123',
        },
        {
          name: 'pickRouteType',
          value: 'ZPICK',
        },
      ],
    },
    parent: {
      layoutName: 'AR',
      layoutNameDesc: '',
      parentLayoutDistinctName: 'AR',
    },
    children: [
      {
        layoutName: 'AR',
        layoutNameDesc: 'AR',
        childLayoutDistinctName: 'AR',
      },
    ],
    attributes: [
      {
        name: 'CATEGORY_LABEL',
        value: 'CAT123',
      },
      {
        name: 'pickRouteType',
        value: 'ZPICK',
      },
    ],
  },
];

const STORE_ORDER_RELEASE_ROWS = [
  {
    storeNumber: '00859',
    pallets: 4,
    pieces: 121,
    comments: '',
  },
  {
    storeNumber: '00144',
    pallets: 2,
    pieces: 105,
    comments: 'DSD',
  },
  {
    storeNumber: '00668',
    pallets: 3,
    pieces: 150,
    comments: 'Mega/Area 6',
  },
  {
    storeNumber: '001854',
    pallets: 1,
    pieces: 100,
    comments: '',
  },
  {
    storeNumber: '00859',
    pallets: 4,
    pieces: 121,
    comments: '',
  },
  {
    storeNumber: '00144',
    pallets: 2,
    pieces: 105,
    comments: 'DSD',
  },
  {
    storeNumber: '00668',
    pallets: 3,
    pieces: 150,
    comments: 'Mega/Area 6',
  },
  {
    storeNumber: '001854',
    pallets: 1,
    pieces: 100,
    comments: '',
  },
];

const CONSOLIDATION_LOCATION_TABLE_ROWS = [
  {
    id: 1,
    locationId: 'ARA0001A',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 2,
    locationId: 'ARA0001B',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 3,
    locationId: 'ARA0001C',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 4,
    locationId: 'ARA0001D',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 5,
    locationId: 'ARA0001E',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 6,
    locationId: 'ARA0001F',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 7,
    locationId: 'ARA0001G',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 8,
    locationId: 'ARA0001H',
    attributes: [
      {
        name: 'locationType',
        value: 'Dock',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 9,
    locationId: 'ARA0001I',
    attributes: [
      {
        name: 'locationType',
        value: 'Conveyor',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 10,
    locationId: 'ARA0001J',
    attributes: [
      {
        name: 'locationType',
        value: 'Conveyor',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
  {
    id: 11,
    locationId: 'ARA0001K',
    attributes: [
      {
        name: 'locationType',
        value: 'Conveyor',
      },
      {
        name: 'status',
        value: 'Available',
      },
      {
        name: 'height',
        value: 9999,
      },
      {
        name: 'width',
        value: 9999,
      },
      {
        name: 'depth',
        value: 9999,
      },
      {
        name: 'order',
        value: '005625',
      },
      {
        name: 'documentPrinterLocation',
        value: '',
      },
      {
        name: 'labelPrinterLocation',
        value: '',
      },
    ],
    zoneName: 'AR',
    subzoneName: 'ARP1',
  },
];

const VEHICLE_TABLE_ROWS = [
  {
    id: 1,
    vehicleId: 'AF04',
    type: 'Reach Lift',
    description: 'Aerosol Racks PLT Flow',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
  {
    id: 2,
    vehicleId: 'AF03',
    type: 'Truck',
    description: 'OPP Count Vehicles FPS AR, BA, BR & FM',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
  {
    id: 3,
    vehicleId: 'AF05',
    type: 'Reach Lift',
    description: 'Replenishment Aerosol PLT Flow/Gravity',
    isFullPallet: false,
    isPartPallet: true,
    isPickingAllowed: true,
    isPutAwayAllowed: false,
  },
  {
    id: 4,
    vehicleId: 'AF06',
    type: 'Truck',
    description: 'Replenishment for W',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: false,
    isPutAwayAllowed: false,
  },
  {
    id: 5,
    vehicleId: 'AF07',
    type: 'Truck',
    description: 'OS Vehicle for Aerosol Pallet Flow',
    isFullPallet: false,
    isPartPallet: true,
    isPickingAllowed: false,
    isPutAwayAllowed: true,
  },
  {
    id: 6,
    vehicleId: 'AF08',
    type: 'Truck',
    description: 'Replen BA and Aerosol Pallet Flows Only',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
  {
    id: 7,
    vehicleId: 'AF09',
    type: 'Reach Lift',
    description: 'Vehicle Oil, Aerosol',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
  {
    id: 8,
    vehicleId: 'BPPF',
    type: 'Truck',
    description: 'Oil and Aerosol/fml Single Bin',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
  {
    id: 9,
    vehicleId: 'CRAA',
    type: 'Reach Lift',
    description: 'Aerosol Racks PLT Flow',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
  {
    id: 10,
    vehicleId: 'CRAC',
    type: 'Truck',
    description: 'OS Vehicle for FMB, FMD, FMF, FMH, FMK, ARA & ARC',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
  {
    id: 12,
    vehicleId: 'FMB3',
    type: 'Truck',
    description: 'OS Vehicle for FMH, FMK, ARA & ARC',
    isFullPallet: true,
    isPartPallet: false,
    isPickingAllowed: true,
    isPutAwayAllowed: true,
  },
];

export {
  CSR_ERROR_ROWS,
  STORE_ERROR_ROWS,
  ORDER_DETAILS_ROWS,
  STORE_DETAILS_ROWS,
  WILL_CALL_TABLE_ROWS,
  WAREHOUSE_CONFIGURATION_TABLE_ROWS,
  ZONE_TABLE_ROWS,
  SUBZONE_TABLE_ROWS,
  STORE_ORDER_RELEASE_ROWS,
  VEHICLE_TABLE_ROWS,
  CONSOLIDATION_LOCATION_TABLE_ROWS,
};
