/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useAtom } from 'jotai';

import { Actionable, Text, View } from '@az/starc-ui';

import { Accordion } from '@shared/components/Accordion/Accordion';

import { selectedStoresLaneAtom } from '@outbound/atoms/releaseOrder/releaseOrderAtom';

import { Props } from './SubzoneAccordion.types';
import Styles from '@outbound/components/OrderReleaseAccordion/OrderReleaseAccordion.module.scss';
import {
  printAndReleaseAtom,
  releaseToPaperLaneConfirmationAtom,
  releaseToPaperSubzonesAtom,
} from '@outbound/atoms/releaseToPaper/releaseToPaperAtom';
import s from './SubzoneAccordion.module.scss';
import { generateDateString } from '@ofm/utils/utils';
import { headerItems, subzoneSku } from './data';
import { allLaneOptions } from '../OrderTabs/data';
import { ReleaseToPaperRowTypes } from '@shared/components/Table/Table.types';

export const SubzoneAccordion = ({ storeNumber, item, order, subzone }: Props) => {
  /*Atoms*/
  const [selectedSubzones] = useAtom(releaseToPaperSubzonesAtom);
  const [selectedStoresLane] = useAtom(selectedStoresLaneAtom);
  const [releaseToPaperLaneConfirmation] = useAtom(releaseToPaperLaneConfirmationAtom);
  const [printAndRelease] = useAtom(printAndReleaseAtom);

  /* constants */
  const { label, collapsible = true } = order;
  const { subzones = [], pallets, pcs, routeNo, dispatchTime } = item;

  /* State variables */
  const [rows] = useState(subzones);
  const [, setOpen] = useState(collapsible ? false : true);
  const [location, setLocation] = useState<{ [key: string]: boolean }>({});

  // useEffect added to open accordion if its in laneconfirmation page
  useEffect(() => {
    releaseToPaperLaneConfirmation && setOpen(true);
  }, [releaseToPaperLaneConfirmation]);

  const handleShowMore = (sku: string) => {
    setLocation((prevState) => ({
      ...prevState,
      [sku]: !prevState[sku],
    }));
  };
  const renderSubzoneHeader = () => (
    <View className={s['subzone-header__content']}>
      <View
        padding={[2, 4]}
        direction="row"
        className={s['subzone-header__content-header-wrapper']}
      >
        <View width="calc(var(--st-unit-20)*2)" />
        {headerItems.map((header) => (
          <View width={header.width}>
            <Text className={s['subzone-header__header-text']}>{header.text}</Text>
          </View>
        ))}
      </View>

      {/* Rows */}
      {subzoneSku.map((sku) => (
        <>
          <View
            className={s['subzone']}
            padding={[4, 4]}
            align="center"
            width="100%"
            direction="row"
          >
            <View width="calc(var(--st-unit-20)*2)" />

            <View width="var(--st-unit-30)">
              <Text>{sku.sku}</Text>
            </View>

            <View width="var(--st-unit-25)">
              <Text>{sku.part}</Text>
            </View>

            <View width="calc(var(--st-unit-30)*2)">
              <View direction="row" gap={2}>
                <Text>{sku.locationId}</Text>

                <Text decoration="underline">
                  <b>
                    {sku.locations.length > 0 && (
                      <Actionable onClick={() => handleShowMore(sku.sku)}>
                        <Text>
                          {!location[sku.sku]
                            ? t('OutboundMatrix.ReleaseToPaper.Show', {
                                count: sku.locations.length,
                              })
                            : t('OutboundMatrix.ReleaseToPaper.Hide', {
                                count: sku.locations.length,
                              })}
                        </Text>
                      </Actionable>
                    )}
                  </b>
                </Text>
              </View>
            </View>

            <View width="calc(var(--st-unit-11)*5)">
              <Text>{sku.description}</Text>
            </View>

            <View width="var(--st-unit-25)">
              <Text>{sku.pack}</Text>
            </View>

            <View width="var(--st-unit-25)">
              <Text>{sku.qoh}</Text>
            </View>

            <View width="var(--st-unit-25)">
              <Text>{sku.quantity}</Text>
            </View>
          </View>

          {location[sku.sku] && (
            <View direction="row" padding={[2, 0, 0, 94]}>
              <View.Item grow>
                <View>
                  <Text textCase="uppercase" className={s['subzone__location']}>
                    {t('OutboundMatrix.ReleaseToPaper.QuantityByLocation')}
                  </Text>
                </View>

                {sku.locations.map((location, idx) => (
                  <View
                    direction="row"
                    width="100%"
                    padding={[4, 4]}
                    key={idx}
                    justify="space-between"
                    className={s['subzone']}
                  >
                    <View width="calc(var(--st-unit-11)*5)">
                      <Text>{location.locationId}</Text>
                    </View>

                    <View width="var(--st-unit-27)">
                      <Text>{sku.quantity}</Text>
                    </View>
                  </View>
                ))}
              </View.Item>
            </View>
          )}
        </>
      ))}
    </View>
  );

  const renderAccordion = (subzone: ReleaseToPaperRowTypes) => (
    <Accordion
      header={{
        label: (
          <View direction="row" align="center">
            <View
              direction="row"
              align="center"
              className={Styles['order-release-accordion__left']}
            >
              <View
                direction="row"
                align="center"
                padding={[4, 0]}
                width="calc(var(--st-unit-20) * 2)"
                className={Styles['order-release-accordion__left--label']}
              >
                <Text size="100" weight="bold">
                  {subzone.subzone}
                </Text>
              </View>
              <View padding={[4, 2]} width="calc(var(--st-unit-20)*2)">
                <Text size="100" weight="regular">
                  {subzone.sku} {t('Table.OrderRelease.SKU')}
                </Text>
              </View>
              <View padding={[4, 2]} width="calc(var(--st-unit-20)*2)">
                <Text size="100" weight="regular">
                  {subzone.pallets} {t('Table.OrderRelease.Pallets')}
                </Text>
              </View>
            </View>
          </View>
        ),
        auxiliaryLabel: <View />,
      }}
    >
      {renderSubzoneHeader()}
    </Accordion>
  );

  return (
    <>
      <View backgroundColor="secondary">
        <View padding={2} width="100%" direction="row">
          <View padding={[4, 2]} width="calc(var(--st-unit-20)*2)">
            <Text size="100" weight="bold">{`${t(label)} ${storeNumber}`}</Text>
          </View>
          <View padding={[4, 2]} width="calc(var(--st-unit-20)*2)">
            <Text size="100" weight="regular">
              {t('OutboundMatrix.OrderRelease.Pallet', { count: pallets })}
            </Text>
          </View>
          <View padding={[4, 2]} width="calc(var(--st-unit-20)*2)">
            <Text size="100" weight="regular">
              {t('OutboundMatrix.OrderRelease.Pieces', { count: pcs })}
            </Text>
          </View>
          <View padding={[4, 2]} width="calc(var(--st-unit-20)*2)">
            <Text size="100" weight="regular">
              {t('OutboundMatrix.ReleaseToPaper.RouteNo', { id: routeNo })}
            </Text>
          </View>
          <View padding={[4, 2]} width="calc(var(--st-unit-20)*4)">
            <Text size="100" weight="regular">
              {t('OutboundMatrix.ReleaseToPaper.DispatchTime', {
                date: dispatchTime && generateDateString(dispatchTime, t('DateFormat.ShortTime')),
              })}
            </Text>
          </View>
          <View padding={[4, 2]} width="calc(var(--st-unit-20)*2)">
            {printAndRelease && (
              <Text size="100" weight="regular">
                {selectedStoresLane.length > 0
                  ? selectedStoresLane[0].lane
                  : allLaneOptions[0].label}
              </Text>
            )}
          </View>
        </View>
        <View>
          {selectedSubzones.length > 0
            ? rows
                .filter(({ subzone }) => selectedSubzones.includes(subzone))
                .map((sub) => renderAccordion(sub))
            : subzone
            ? subzone.map((subzone) => renderAccordion(subzone))
            : ''}
        </View>
      </View>
    </>
  );
};
