/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const newStoresAndBackupHeaderData = [
  {
    groupBy: 'New',
    pieces: '825 / 3,349',
    pallets: 6,
    stores: ['154826'],
  },
  {
    groupBy: 'Backup',
    pieces: '825 / 3,349',
    pallets: 6,
    stores: ['152489'],
  },
];

export const newStoresAndBackupLocationsData = [
  {
    location: 'Aerosol',
    tiles: [
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
      [{ count: '4/4', assigned: null, state: 'empty' }],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [{ count: '1/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [{ count: '1/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
    ],
  },
  {
    location: 'Battery',
    tiles: [
      [{ count: '0/4', assigned: null, state: 'un-assigned' }],
      [{ count: '4/4', assigned: null, state: 'empty' }],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [{ count: '1/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [{ count: '1/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
    ],
  },
  {
    location: 'Hard Parts',
    tiles: [
      [{ count: '0/4', assigned: null, state: 'un-assigned' }],
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '1/4', vehicle: 'APAL', selector: null, state: 'un-assigned' }],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [{ count: '4/4', vehicle: null, selector: null, state: 'completed' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
    ],
  },
  {
    location: 'Mezzanine',
    tiles: [
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
      [{ count: '0/4', assigned: null, state: 'un-assigned' }],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [{ count: '1/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [{ count: '1/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
        ],
      },
    ],
  },
  {
    location: 'Oil',
    tiles: [
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '1/4', vehicle: 'APAL', selector: null, state: 'un-assigned' }],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
    ],
  },
  {
    location: 'Reserves',
    tiles: [
      [{ count: '1/4', assigned: null, state: 'empty' }],
      [{ count: '4/4', assigned: null, state: 'empty' }],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [{ count: '1/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
        ],
      },
    ],
  },
  {
    location: 'Pick to Cart',
    tiles: [
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '1/4', vehicle: 'APAL', selector: null, state: 'un-assigned' }],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
    ],
  },
  {
    location: 'LTD',
    tiles: [
      [{ count: '1/4', assigned: null, state: 'un-assigned' }],
      [{ count: '1/4', assigned: null, state: 'empty' }],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '1/4', vehicle: 'APAL', selector: null, state: 'un-assigned' }],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [{ count: '4/4', vehicle: null, selector: null, state: 'completed' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '4/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [{ count: '0/4', vehicle: null, selector: null, state: 'un-assigned' }],
          [{ count: '0/4', vehicle: null, selector: null, state: 'empty' }],
        ],
      },
    ],
  },
];
