/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { INBOUND_RECEIVING_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@inbound/services/apiService.ts';
import { parseAndLog } from '@inbound/utils/service/serviceUtils';
import { TrailerArrivalRequestType } from '@inbound/types/types';
import { TrailerArrivalRequestSchema } from '@inbound/schemas/trailerArrivalSchema';

export const trailerArrivalService = {
  createTrailerArrival: async (trailerArrivalRequest: TrailerArrivalRequestType) => {
    const resp = await apiService.post(
      INBOUND_RECEIVING_ENDPOINTS.CREATE_TRAILER_ARRIVALS,
      trailerArrivalRequest
    );

    return parseAndLog(TrailerArrivalRequestSchema, resp.data);
  },

  updateTrailerArrival: async (
    trailerInId: string,
    trailerArrivalRequest: TrailerArrivalRequestType
  ) => {
    const resp = await apiService.put(
      INBOUND_RECEIVING_ENDPOINTS.UPDATE_TRAILER_ARRIVALS(trailerInId),
      trailerArrivalRequest
    );

    return parseAndLog(TrailerArrivalRequestSchema, resp.data);
  },
};
