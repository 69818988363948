/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Divider, FormControl, Select, Text, TextField, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultAttributeValues, ZoneSchema } from '@mdm/schemas/zoneSchema.ts';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useNavigate, useParams } from 'react-router-dom';
import { ZoneAssociatedSubzoneList } from './ZoneAssociatedSubzoneList.tsx';

import { useSessionStorage } from '@shared/hooks/useStorage';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants';
import { z } from 'zod';

import styles from './ZoneDetail.module.scss';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { getFormDefaults, getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { displayAttributeFieldValidationMessage } from '@mdm/utils/form/validationUtils.tsx';
import { useMutateZone } from '@mdm/services/hooks/useMutateZone.tsx';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteZone } from '@mdm/services/hooks/useDeleteZone.ts';

import {
  MAX_LENGTH_FIELD,
  DEFAULT_DETAIL_VIEW_LOADING_COUNT,
  ZONE_FIELD,
} from '@mdm/constants/constants.ts';
import { useGetZoneByLayoutKey } from '@mdm/services/hooks/useGetZoneByLayoutKey.tsx';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useEffect } from 'react';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';

export const ZoneDetail = () => {
  /* Constants */
  type FormData = z.infer<typeof ZoneSchema>;
  const { zoneId: id } = useParams();
  const queryClient = useQueryClient();

  const { zoneData, isLoading } = useGetZoneByLayoutKey(
    {
      layoutKey: id || '',
    },
    !!id
  );

  const { isLoading: isZoneUpdating, mutateZone } = useMutateZone();
  const { mutateDeleteZone } = useDeleteZone();

  /* Functions */
  const onSubmit = () => {
    const payload = getValues();

    mutateZone(
      {
        layoutKey: payload.layoutKey || '',
        layoutRequest: payload,
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries(['zones']);
            queryClient.invalidateQueries(['zone']);
            if (payload.layoutKey) {
              handleNotification(
                NOTIFICATION_TYPES.SUCCESS,
                t('Success.Action.Zone.Updated', { zoneName: payload.layoutName })
              );
            } else {
              handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.Zone.Created'));
            }

            if (data.layoutDistinctName) {
              navigate(PAGE_URLS.ZONE_DETAILS(data.layoutDistinctName));
            }
          }
        },
      }
    );
  };

  const onDeleteZone = () => {
    const payload = getValues();
    if (payload.layoutKey) {
      mutateDeleteZone(
        {
          layoutKey: payload.layoutKey || '',
        },
        {
          onSuccess: async () => {
            navigate(PAGE_URLS.ZONE_LIST);
          },
        }
      );
    }
  };

  /* Hooks */
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);
  const { handleNotification } = useNotificationHandler();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    reset: zoneReset,
    formState: { errors },
    register,
    getValues,
    watch,
  } = useForm<FormData>({
    defaultValues: getFormDefaults(ZoneSchema),
    resolver: zodResolver(ZoneSchema),
  });

  const zoneID = watch('layoutName');

  const breadcrumbs = useBreadcrumb(id ? PAGE_URLS.ZONE_CREATE : '');

  useEffect(() => {
    if (zoneData && zoneData.content[0]) {
      const attributes = zoneData.content[0].layout.attributes || [];
      for (const defaultAttributeValue of defaultAttributeValues) {
        if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
          attributes.push(defaultAttributeValue);
        }
      }

      zoneReset({
        ...zoneData.content[0].layout,
        attributes,
      });
    }
  }, [zoneData, zoneReset]);

  return (
    <>
      <View
        className={styles['zone-detail']}
        backgroundColor="secondary"
        direction="column"
        height="100%"
      >
        <View.Item
          attributes={{
            'data-testid': 'zone-details-header',
          }}
        >
          <MasterTitle
            title={`${t('Zone')}: ${zoneID ? zoneID : t('Untitled')}`}
            breadcrumbProps={breadcrumbs}
          >
            <View
              attributes={{
                'data-testid': 'zone-details-action',
              }}
              direction="row"
              justify="end"
              align="center"
              gap={4}
            >
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      variant="secondary"
                      size="large"
                      onClick={() => navigate(PAGE_URLS.ZONE_LIST)}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('Cancel')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      size="large"
                      loading={isZoneUpdating}
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('SaveEdits')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              {id ? (
                <ActionMenu
                  menus={[
                    {
                      label: t('RemoveZone'),
                      clickHandler: onDeleteZone,
                      labelColor: 'error',
                    },
                  ]}
                />
              ) : null}
            </View>
          </MasterTitle>
        </View.Item>
        <View backgroundColor="secondary" padding={6} height="100%">
          <View>
            <View.Item>
              <Text size="125" weight="bold">
                {t('ZoneDetails.Title')}
              </Text>
            </View.Item>
          </View>
          <View className={styles['zone-detail__content-section']}>
            <View>
              {id && isLoading ? (
                <View padding={4}>
                  <DetailsSectionSkeleton items={DEFAULT_DETAIL_VIEW_LOADING_COUNT} />
                </View>
              ) : (
                <form className="zone-detail__form">
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['zone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('Warehouse')}
                        </Text>
                        <FormControl>
                          <Select
                            label={t('Warehouse')}
                            name="warehouse"
                            variant="no-label"
                            multiSelect={false}
                            defaultValue={{
                              label: 'DC ' + selectedDC?.toString(),
                              value: 'DC ' + selectedDC?.toString(),
                            }}
                            options={[]}
                            attributes={{ style: { width: 'var(--st-unit-5)' } }}
                            disabled
                          />
                        </FormControl>
                        <Text weight="regular" color="600" size="087">
                          {t('ZoneDetails.WarehouseInstruction')}
                        </Text>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['zone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('ZoneDetails.Name')}
                        </Text>
                        <FormControl hasError={!!errors?.layoutName}>
                          <TextField
                            attributes={{
                              'data-testid': 'name',
                            }}
                            inputAttributes={{
                              placeholder: t('ZoneDetails.EnterZoneName'),
                              maxLength: MAX_LENGTH_FIELD.ZONE_NAME,
                              ...register('layoutName'),
                            }}
                            disabled={!!id}
                            defaultValue=""
                          />
                          {errors?.layoutName && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>{errors?.layoutName.message}</FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text weight="medium" size="087">
                          {`${t('ZoneDetails.Description')} (${t('Optional')})`}
                        </Text>
                        <FormControl hasError={!!errors?.layoutNameDesc}>
                          <TextField
                            attributes={{
                              'data-testid': 'description',
                            }}
                            inputAttributes={{
                              placeholder: t('ZoneDetails.EnterZoneDescription'),
                              maxLength: MAX_LENGTH_FIELD.ZONE_DESCRIPTION,
                              ...register('layoutNameDesc'),
                            }}
                            defaultValue=""
                          />
                          {errors?.layoutNameDesc && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {errors?.layoutNameDesc.message}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('ZoneDetails.MapSequence.Label')} isRequired={true} />
                        <FormControl
                          hasError={
                            !!(
                              errors?.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ZONE_FIELD.MAP_SEQUENCE
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'map-sequence',
                            }}
                            inputAttributes={{
                              placeholder: t('ZoneDetails.MapSequence.Placeholder'),
                              maxLength: MAX_LENGTH_FIELD.ZONE_MAP_SEQUENCE,
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ZONE_FIELD.MAP_SEQUENCE
                                )}.value`
                              ),
                            }}
                          />
                          <FormControl.Error>
                            {errors?.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ZONE_FIELD.MAP_SEQUENCE
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                </form>
              )}
            </View>

            <View>
              <Divider color="300" className={styles['zone-detail__divider']} />
              <ZoneAssociatedSubzoneList zoneLayoutId={id} />
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
