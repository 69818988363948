/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Divider, Text, Toggle, View, Modal, Actionable, Close, Icon, Button } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Tag } from '@shared/components/Tag/Tag';
import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';
import { TagItemsProps } from '../TaskManagerCard/TaskManagerCard.types';
import * as T from './AssignTaskModal.types';
import styles from './AssignTaskModal.module.scss';

export const AssignTaskModal = ({ isOpen, onClose, POModalData, onSuccess, userName }: T.Props) => {
  const { t } = useTranslation();
  const [urgentTaskChecked, setUrgentTaskChecked] = useState(false);
  const handleValueChange = (urgentTaskChecked: boolean) => {
    setUrgentTaskChecked(urgentTaskChecked);
  };
  const OnClickAssignTaskSuccess = () => {
    setUrgentTaskChecked(false);
    if (onSuccess) {
      onSuccess();
    }
  };
  const OnModalClose = () => {
    setUrgentTaskChecked(false);
    onClose();
  };
  return (
    <Modal open={isOpen} onClose={OnModalClose} className={styles['assign-task-modal']}>
      <View padding={6} direction="row" justify="space-between" className={styles['modal__header']}>
        <View.Item columns={11}>
          <Text size="175" weight="bold">
            {t(`AssignTaskConfirmationModal.TitleText`, {
              userName: userName,
            })}
          </Text>
          <Text size="087" variant="large-body" color="primary">
            {t(`AssignTaskConfirmationModal.SubtitleText`)}
          </Text>
        </View.Item>
        <Actionable onClick={onClose}>
          <Icon svg={Close} />
        </Actionable>
      </View>

      <View padding={[0, 6, 6, 6]} className={styles['modal__body']}>
        {POModalData && (
          <>
            <View gap={2} direction="row">
              <Text size="075" weight="bold" color="500" textCase="uppercase">
                {t(`AssignTaskConfirmationModal.PurchaseOrders`)}
              </Text>
            </View>
            <View gap={4} direction="row">
              <Text size="125" weight="bold" color="primary">
                {POModalData.vendorName}
                {POModalData.priority && <PriorityTag />}
              </Text>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.CompatibleVehicles`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.compatibleVehicles}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.PO#`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {t('PoTasks.PO', { poNumber: POModalData.poNumber })}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Assignees`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {t(`AssignTaskConfirmationModal.NoReceiversAssigned`)}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Pieces#`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {t(`AssignTaskConfirmationModal.Pieces`, {
                    total: POModalData.piecesData?.total,
                  })}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.ArrivalTime`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.arrivalDate}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Shift`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.shift}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.GoalHours`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.goalHours}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Vendor`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.vendorName}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Carrier`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.carrier}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Trailer`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.trailer}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Location`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.location},&nbsp;
                  {t('AssignTaskConfirmationModal.Gate', {
                    gateNo: POModalData.gate,
                  })}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Commodity`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {POModalData.tagItems &&
                    POModalData.tagItems.map((tagItem: TagItemsProps) => (
                      <Tag text={tagItem.text} variant={tagItem.variant} />
                    ))}
                </Text>
              </View.Item>
            </View>

            <Divider className={styles['assign-task-modal__bottom-divider']} />

            <View gap={6} direction="row">
              <View.Item columns={10}>
                <Text size="100" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.UrgentTask`)}
                </Text>
                <Text size="087" variant="large-body" color="primary">
                  {t(`AssignTaskConfirmationModal.UrgentTaskSubText`)}
                </Text>
              </View.Item>
              <View.Item columns={2}>
                <Toggle
                  name="controlled"
                  checked={urgentTaskChecked}
                  onValueChange={handleValueChange}
                  className={styles['assign-task-modal__priority-toggle']}
                />
              </View.Item>
            </View>
          </>
        )}
      </View>

      <View gap={3} direction="row" padding={6} justify="end" className={styles['modal__footer']}>
        <View.Item columns={3}>
          <Button variant="secondary" size="large" onClick={OnModalClose}>
            {t(`AssignTaskConfirmationModal.Cancel`)}
          </Button>
        </View.Item>
        <View.Item columns={5}>
          <Button
            size="large"
            onClick={OnClickAssignTaskSuccess}
            //disabled={!urgentTaskChecked}
          >
            {t(`AssignTaskConfirmationModal.YesAssignTask`)}
          </Button>
        </View.Item>
      </View>
    </Modal>
  );
};
