/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { RouteObject } from 'react-router-dom';

import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { ControlDesk } from '@outbound/pages/ControlDesk/ControlDesk';
import { ReplenishmentOrders } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/Replenishment';
import { WillCallOrders } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/WillCallOrders';
import { NewStore } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/NewStoreOrders/NewStore';
import { LongTailDistribution } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/LongTailDistribution/LongTailDistribution';
import { TransferOrders } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/TransferOrders/TransferOrders';
import { Shipments } from '@outbound/pages/Shipments/Shipments';
import { StoreDetails } from '@outbound/pages/ControlDesk/StoreDetails/StoreDetails';
import { CrossDock } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/CrossDock/CrossDock';
import { ReplenishmentMatrix } from '@outbound/pages/ControlDesk/MatrixTabs/Replenishment';
import { WillCallMatrix } from '@outbound/pages/ControlDesk/MatrixTabs/WillCall/WillCall';
import { NewStoreAndBackupMatrix } from '@outbound/pages/ControlDesk/MatrixTabs/NewStoreAndBackup/NewStoreAndBackup';
import { LongTailDistributionMatrix } from '@outbound/pages/ControlDesk/MatrixTabs/LongTailDistribution/LongTailDistribution';
import { TransferMatrix } from '@outbound/pages/ControlDesk/MatrixTabs/Transfer/Transfer';
import { CrossDockMatrix } from '@outbound/pages/ControlDesk/MatrixTabs/CrossDock/CrossDock';
import { DifMatrix } from '@outbound/pages/ControlDesk/MatrixTabs/Dif/dif';
import { DifOrders } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/DifOrders/DifOrders';

export const outboundRoutes: RouteObject = {
  path: ROUTES.OUTBOUND,
  children: [
    {
      path: ROUTES.CONTROL_DESK,
      element: <ControlDesk />,
      children: [
        {
          path: ROUTES.REPLENISHMENT,
          element: <ReplenishmentMatrix />,
        },
        {
          path: ROUTES.Will_CAll,
          element: <WillCallMatrix />,
        },
        {
          path: ROUTES.New_Store,
          element: <NewStoreAndBackupMatrix />,
        },
        {
          path: ROUTES.Long_Tail_Distribution,
          element: <LongTailDistributionMatrix />,
        },
        {
          path: ROUTES.Transfer,
          element: <TransferMatrix />,
        },
        {
          path: ROUTES.CROSS_DOCK,
          element: <CrossDockMatrix />,
        },
        {
          path: ROUTES.DIF,
          element: <DifMatrix />,
        },
        {
          path: PAGE_URLS.REPLENISHMEHT_ORDER_RELEASE,
          element: <ReplenishmentOrders />,
        },
        {
          path: PAGE_URLS.WILLCALL_ORDER_RELEASE,
          element: <WillCallOrders />,
        },
        {
          path: PAGE_URLS.NEWSTORE_ORDER_RELEASE,
          element: <NewStore />,
        },
        {
          path: PAGE_URLS.LTD_ORDER_RELEASE,
          element: <LongTailDistribution />,
        },
        {
          path: PAGE_URLS.TRANSFER_ORDER_RELEASE,
          element: <TransferOrders />,
        },
        {
          path: PAGE_URLS.CROSS_DOCK_ORDER_RELEASE,
          element: <CrossDock />,
        },
        {
          path: PAGE_URLS.DIF_ORDER_RELEASE,
          element: <DifOrders />,
        },
      ],
    },
    {
      path: ROUTES.SHIPMENTS,
      element: <Shipments />,
    },
    {
      path: `${ROUTES.STORE_DETAILS}/:storeId`,
      element: <StoreDetails />,
      children: [
        {
          path: `${ROUTES.ORDERS}/:orderId/:orderType`,
          element: <StoreDetails />,
        },
      ],
    },
  ],
};
