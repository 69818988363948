/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';
import {
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  CONSOLIDATION_LOCATION_FIELD,
  DEFAULT_CONSOLIDATION_STATUS,
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
} from '@mdm/constants/constants.ts';
import { t } from 'i18next';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';

export const defaultAttributeValues = [
  {
    name: CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE,
    value: '',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.STATUS,
    value: DEFAULT_CONSOLIDATION_STATUS,
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.HEIGHT,
    value: '0',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.WIDTH,
    value: '0',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.DEPTH,
    value: '0',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.ORDER,
    value: '',
  },
];

export const ConsolidationLocationSchema = z.object({
  zoneName: z.string().min(1),
  layoutKey: z.string().optional(),
  layoutName: z.string().superRefine((data, ctx) => {
    if (data === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (!isAlphaNumeric(data)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }
  }),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().min(1),
  entityCd: z.string().default(LAYOUT_ENTITY_CODE.LOCATION),
  entityTypeCd: z.string().default(LAYOUT_ENTITY_TYPE_CODE.CONSOLIDATED),
  active: z.boolean().default(true),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [
        CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE,
        CONSOLIDATION_LOCATION_FIELD.STATUS,
        CONSOLIDATION_LOCATION_FIELD.HEIGHT,
        CONSOLIDATION_LOCATION_FIELD.WIDTH,
        CONSOLIDATION_LOCATION_FIELD.DEPTH,
      ];
      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const numericFields = [
        {
          key: CONSOLIDATION_LOCATION_FIELD.HEIGHT,
        },
        {
          key: CONSOLIDATION_LOCATION_FIELD.WIDTH,
        },
        {
          key: CONSOLIDATION_LOCATION_FIELD.DEPTH,
        },
      ];

      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);
    })
    .nullable()
    .default(defaultAttributeValues),
});
