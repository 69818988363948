/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { GetLayoutParams } from '@mdm/types/types.ts';
import { subzoneService } from '@mdm/services/subzoneService.ts';

export const useGetSubzonesByZoneId = (options: GetLayoutParams, isEnabled = true) => {
  const {
    data: subzonesData,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['zoneAssociatedSubzones', options],
    queryFn: () => subzoneService.getSubzonesByZoneId(options),
    enabled: isEnabled,
    keepPreviousData: true,
  });

  return { subzonesData, isFetching, isError };
};
