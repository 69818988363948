/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { t } from 'i18next';

import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';
import {
  addCustomMaxLengthAttributeFieldValidation,
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  MAX_LENGTH_FIELD,
  ZONE_FIELD,
} from '@mdm/constants/constants.ts';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';

export const defaultAttributeValues = [
  {
    name: ZONE_FIELD.MAP_SEQUENCE,
    value: '',
  },
];

export const ZoneSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z.string().superRefine((val, ctx) => {
    if (val == '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (val.length > MAX_LENGTH_FIELD.ZONE_NAME) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: MAX_LENGTH_FIELD.ZONE_NAME,
        type: 'string',
        inclusive: true,
        message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.ZONE_NAME }),
      });
    }

    if (!isAlphaNumeric(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }
  }),
  layoutNameDesc: z
    .string()
    .nullable()
    .optional()
    .superRefine((val, ctx) => {
      if (val && val.length > MAX_LENGTH_FIELD.ZONE_DESCRIPTION) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: MAX_LENGTH_FIELD.ZONE_DESCRIPTION,
          type: 'string',
          inclusive: true,
          message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.ZONE_DESCRIPTION }),
        });
      }

      if (val && !isAlphaNumeric(val, true)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.AlphaNumericError'),
        });
      }
    }),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().nullable().optional().default(''),
  entityCd: z.string().default(LAYOUT_ENTITY_CODE.ZONE),
  entityTypeCd: z.string().default(LAYOUT_ENTITY_TYPE_CODE.DEFAULT),
  active: z.boolean().default(true),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [ZONE_FIELD.MAP_SEQUENCE];
      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const numericFields = [{ key: ZONE_FIELD.MAP_SEQUENCE, allowOnlyPositiveNumber: true }];
      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);

      const maxLengthFields = [
        { name: ZONE_FIELD.MAP_SEQUENCE, maxLength: MAX_LENGTH_FIELD.ZONE_MAP_SEQUENCE },
      ];
      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);
    })
    .nullable()
    .default(defaultAttributeValues),
});
