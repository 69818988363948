/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

import { POActions } from '@inbound/constants/constants';
import { trailerArrivalService } from '@inbound/services/trailerArrivalService';
import { ErrorResponse } from '@inbound/types/service.type';
import { TrailerArrivalRequestType } from '@inbound/types/types';

export const useMutateTrailerArrival = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  let message = t('PODashboard.Notification.CreateTrilerArrival.Error');

  const {
    mutate: mutateTrailerArrival,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: {
      trailerInId?: string;
      operation?: string;
      trailerArrivalRequest: TrailerArrivalRequestType;
    }) => {
      if (params.trailerInId) {
        message = t('PODashboard.Notification.UpdateTrilerArrival.Error');

        if (params.operation === POActions.REMOVE) {
          message = t('PODashboard.Notification.RemoveTrilerArrival.Error');
        } else if (params.operation === POActions.PUT_ON_HOLD) {
          message = t('PODashboard.Notification.PutTrilerArrivalOnHold.Error');
        } else if (params.operation === POActions.REMOVE_HOLD) {
          message = t('PODashboard.Notification.RemoveTrilerArrivalHold.Error');
        }

        const payload = { ...params.trailerArrivalRequest };
        return trailerArrivalService.updateTrailerArrival(params.trailerInId, payload);
      } else {
        return trailerArrivalService.createTrailerArrival(params.trailerArrivalRequest);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['sourceOrderNumber']);
      queryClient.invalidateQueries(['trailerOrders']);
    },
    onError: (error: ErrorResponse) => {
      if (error?.message) {
        message = error.message;
      }

      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateTrailerArrival, isLoading, isError };
};
