/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAtom } from 'jotai';

import {
  Actionable,
  Add,
  Button,
  Close,
  Dropdown,
  Icon,
  SingleValue,
  Text,
  View,
} from '@az/starc-ui';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { Stat } from '@shared/components/Stat/Stat';
import { Table } from '@shared/components/Table/Table';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { SortRowsParam } from '@shared/components/Table/Table.types';
import { DEFAULT_PAGE, NOTIFICATION_TYPES, PAGE_SIZE } from '@shared/constants/constants';
import { PAGE_URLS } from '@shared/constants/routes';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { searchAtom } from '@ofm/atoms/search/searchInputAtom';

import { AddPOModal } from '@inbound/components/AddPOModal/AddPOModal';
import { DropdownSelectMenu } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu';
import { DropdownOption } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu.types';
import { ListKanbanToggle } from '@inbound/components/ListKanbanToggle/ListKanbanToggle';
import { POSearch } from '@inbound/components/POSearch/POSearch';

import { LIST, KANBAN, POActions } from '@inbound/constants/constants';
import { ARRIVAL_TIMES, PO_CATEGORIES, DIVIDER_LABELS } from '@inbound/constants/dataConstants';
import { PO_DASHBOARD_TABLE_COLUMNS } from '@inbound/constants/tableConstants';
import { useGetFacilityConfig } from '@inbound/services/hooks/useGetFacilityConfig';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import { PODashboardDataRowType, PODashboardDividerRowType } from '@inbound/types/types';
import { mapUItoDBField, statusToBadgeVariant, toUTCString } from '@inbound/utils/utils';
import {
  mapPODashboardTableAgeBreakdownRows,
  mapTrailerOrders,
} from '@inbound/utils/table/tableUtils';

import styles from './PODashboard.module.scss';

export const PODashboard = () => {
  /* Atoms */
  const [searchValue, setSearchValue] = useAtom(searchAtom);

  /* State variables */
  const [currentView, setCurrentView] = useState<string>(LIST);
  const [showAddPOModal, setShowAddPOModal] = useState(false);
  const [poCategory, setPOCategory] = useState<SingleValue>(undefined);
  const [arrivalTimeFilters, setArrivalTimeFilters] = useState<DropdownOption[]>([]);
  const [poTypes, setPOTypes] = useState<DropdownOption[]>([]);
  const [commodityTypes, setCommodityTypes] = useState<DropdownOption[]>([]);
  const [poStatuses, setPOStatuses] = useState<DropdownOption[]>([]);
  const [poData, setPOData] = useState<PODashboardDividerRowType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('priority');
  const [direction, setDirection] = useState<string>('DESC');
  const { configsData: PO_TYPES } = useGetFacilityConfig({
    domainCd: 'CONFIG',
    subDomainCd: 'PO_TYPE',
  });
  const { configsData: COMMODITY_TYPES } = useGetFacilityConfig({
    domainCd: 'CONFIG',
    subDomainCd: 'COMMODITY_TYPE',
  });
  const { configsData: PO_STATUES } = useGetFacilityConfig({
    domainCd: 'CONFIG',
    subDomainCd: 'TRAILER_ORDER_STATUS',
  });

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();

  /* Queries */
  /**
   * searchPage: Current Page, Page Size, SortBy, Direction (ASC, DESC)
   * searchCriteria:
   *   userAssignState: 'BOTH', 'ASSIGNED', 'UNASSIGNED'
   *   arrivalTimestamps: [{ startTime, endTime }]
   *   orderTypes: LTD, PO, ...
   *   commodityTypes: AO, ...
   *   orderStatus: 'NOT_STARTED', 'READY_FOR_SIGNATURE', ...
   */
  const { trailerOrdersData, isLoading } = useGetTrailerOrders({
    searchPage: {
      currentPage: currentPage - 1,
      pageSize: PAGE_SIZE,
      sortBy: mapUItoDBField(sortBy),
      direction: direction.toUpperCase(),
    },
    searchCriteria: {
      userAssignState: !poCategory ? 'BOTH' : poCategory.value,
      arrivalTimestamps: arrivalTimeFilters.map(({ value }) => {
        const index = ARRIVAL_TIMES.findIndex((item) => item.value === value);
        const datetime = Date.now() - index * 86400000;
        return {
          startTime:
            index < ARRIVAL_TIMES.length - 1
              ? toUTCString(new Date(datetime - 86400000))
              : toUTCString(new Date(2020, 1, 1)),
          endTime: toUTCString(new Date(datetime)),
        };
      }),
      orderTypes: poTypes.map((item) => item.value.toUpperCase()),
      commodityTypes: commodityTypes.map((item) => item.value),
      orderStatus: poStatuses.map((item) => item.value),
    },
  });

  const {
    trailerOrdersData: trailerOrdersSearchData,
    isLoading: isSearchLoading,
    refetch: refetchSearchData,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: currentPage - 1,
        pageSize: PAGE_SIZE,
        sortBy: mapUItoDBField(sortBy),
        direction: direction.toUpperCase(),
      },
      searchCriteria: {
        sourceOrderNbr: searchValue,
      },
    },
    false
  );

  /* Functions */
  const handleItemSearch = (value: string) => {
    setSearchValue(value);

    if (value?.length === 8) {
      setTimeout(() => {
        refetchSearchData(); // fake the delay to set state
      }, 1);
    }
  };

  const onListKanbanToggle = (selectedView: string) => {
    setCurrentView(selectedView);
  };

  const onCloseAddPOModal = () => {
    setShowAddPOModal(false);
  };

  const handlePOCategoryFilterChange = (value: SingleValue) => {
    setPOCategory(value);
  };

  const handleArrivalFilterChange = (values: DropdownOption[]) => {
    setArrivalTimeFilters(values);
  };

  const handlePOTypeFilterChange = (values: DropdownOption[]) => {
    setPOTypes(values);
  };

  const handleCommodityTypeFilterChange = (values: DropdownOption[]) => {
    setCommodityTypes(values);
  };

  const handleStatusFilterChange = (values: DropdownOption[]) => {
    setPOStatuses(values);
  };

  const onClearAll = () => {
    handlePOCategoryFilterChange(undefined);
    handleArrivalFilterChange([]);
    handlePOTypeFilterChange([]);
    handleCommodityTypeFilterChange([]);
    handleStatusFilterChange([]);
  };

  const onRemoveFilter = (
    filters: DropdownOption[],
    handleFilterChange: (values: DropdownOption[]) => void,
    index: number
  ) => {
    const result = [...filters];
    result.splice(index, 1);
    handleFilterChange(result);
  };

  const onActionMenuClick = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    sourceOrderNumber: string,
    trailerInId: string,
    action: string
  ) => {
    event.stopPropagation();

    if (action === POActions.VIEW_DETAILS) {
      navigate(PAGE_URLS.PO_DETAILS(sourceOrderNumber, trailerInId));
    } else if (action === POActions.EDIT_DETAILS) {
      navigate(PAGE_URLS.PO_EDIT(sourceOrderNumber, trailerInId));
    } else if (action === POActions.PUT_ON_HOLD) {
      navigate(PAGE_URLS.PO_PUT_ON_HOLD(sourceOrderNumber, trailerInId));
    } else if (action === POActions.REMOVE) {
      navigate(PAGE_URLS.PO_REMOVE(sourceOrderNumber, trailerInId));
    }
  };

  const handleTableRowClick = (clickedRow: SortRowsParam) => {
    // Split row id to get source order number & trailer in id
    const ids = clickedRow.id.split('-');

    const sourceOrderNumber = ids[0];
    const trailerInId = ids[1];

    navigate(PAGE_URLS.PO_DETAILS(sourceOrderNumber, trailerInId));
  };

  const addArrivalTimeDividers = (trailerOrders: PODashboardDataRowType[]) => {
    const data = [...trailerOrders];
    const currentTime = new Date();
    const grouped: Record<string, Array<PODashboardDataRowType>> = {};
    data.forEach((object) => {
      const timeDifference = currentTime.getTime() - object.arrivalTime.getTime();
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      let group;
      if (hoursDifference <= 24) {
        group = '24 Hours';
      } else if (hoursDifference <= 48) {
        group = '48 Hours';
      } else if (hoursDifference <= 72) {
        group = '72 Hours';
      } else {
        group = '72+ Hours';
      }
      if (!grouped[group]) {
        grouped[group] = [];
      }
      grouped[group].push(object);
    });
    const dataWithDivider: PODashboardDividerRowType[] = Object.keys(grouped)
      .sort((a, b) => -a.localeCompare(b))
      .map((key) => {
        if (Object.keys(grouped).length <= 1) {
          return [...grouped[key].map((item) => ({ dividerLabel: undefined, ...item }))];
        }
        return [
          { ...DIVIDER_LABELS[key] },
          ...grouped[key].map((item) => ({ dividerLabel: undefined, ...item })),
        ];
      })
      .flat();
    setPOData(dataWithDivider);
  };

  /*  Hooks */
  useEffect(() => {
    if (trailerOrdersData) {
      addArrivalTimeDividers(mapTrailerOrders(trailerOrdersData.content));
    }
  }, [trailerOrdersData, isLoading]);

  useEffect(() => {
    if (trailerOrdersSearchData) {
      if (searchValue && searchValue?.length === 8) {
        if (!trailerOrdersSearchData.empty) {
          const trailerOrderData = trailerOrdersSearchData.content[0].trailerOrder;

          navigate(
            PAGE_URLS.PO_DETAILS(trailerOrderData.sourceOrderNbr, trailerOrderData.trailerInId)
          );
        } else {
          handleNotification(
            NOTIFICATION_TYPES.ERROR,
            t('PODashboard.Notification.SearchTrailerOrder')
          );
        }
      }
    }
  }, [trailerOrdersSearchData, isSearchLoading, searchValue, handleNotification, t, navigate]);

  return (
    <>
      <View direction="column" height="100%" className={styles['po-dashboard']}>
        <MasterTitle
          title={t('PODashboard.Title')}
          // TODO: Add functionality to save page to favorites column
          titleActionProps={{
            label: 'Favorite',
            handleClick: () => {
              return;
            },
          }}
          statusBadgeProps={{
            variant: statusToBadgeVariant('COMPLETED'),
            text: t('PODashboard.Stats.HoursLeft', { count: 4 }),
          }}
          subtitle={
            <View direction="row" gap={4}>
              <View.Item>
                <Text color="600">{t('PODashboard.Stats.Shift', { shift: '2nd' })}</Text>
              </View.Item>
              <View.Item>
                <Text color="600">{t('PODashboard.Stats.Receivers', { count: 10 })}</Text>
              </View.Item>
            </View>
          }
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item columns={5}>
              <POSearch
                isSearchLoading={isSearchLoading}
                label={t('PODashboard.Search.NonFinalizedPOSearch')}
                onItemSearch={handleItemSearch}
              />
            </View.Item>
            <View.Item>
              <Button size="large" onClick={() => setShowAddPOModal(true)}>
                <View direction="row" align="center" justify="center" gap={2}>
                  <Icon svg={Add} color="on-primary" />
                  <Text>{t('PODashboard.AddAPurchaseOrder')}</Text>
                </View>
              </Button>
            </View.Item>
          </View>
        </MasterTitle>

        <View
          direction="row"
          justify="center"
          padding={[4, 6]}
          backgroundColor="secondary"
          className={styles['po-dashboard__statistics-section']}
        >
          <View.Item grow>
            <View
              direction="row"
              className={styles['po-dashboard__statistics-section__statistics']}
            >
              <Stat title={t('PODashboard.Stats.TotalRemaining')} primaryText="76" width="160px" />
              <Stat title={t('PODashboard.Stats.RegularPOsRemaining')} primaryText="58" />
              <Stat title={t('PODashboard.Stats.DSDPOsRemaining')} primaryText="18" width="160px" />
              <Stat title={t('PODashboard.Stats.LTDPOsRemaining')} primaryText="0" width="160px" />
            </View>
          </View.Item>
        </View>

        <View
          direction="row"
          padding={[4, 4]}
          backgroundColor="secondary"
          className={styles['po-dashboard__filters-section']}
        >
          <View.Item>
            <View justify="center" align="start">
              <ListKanbanToggle onToggle={onListKanbanToggle} />
            </View>
          </View.Item>
          <View.Item>
            <View width="240px">
              <Dropdown>
                <Dropdown.Button label={poCategory ? poCategory.label : 'All POs'} />
                <Dropdown.Content>
                  <View gap={1}>
                    {PO_CATEGORIES.map((category) => (
                      <Dropdown.Item
                        label={category.label}
                        onClick={() => handlePOCategoryFilterChange(category)}
                      />
                    ))}
                  </View>
                </Dropdown.Content>
              </Dropdown>
            </View>
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={200}
              name="arrivalTimeFilter"
              label="All Arrival Time"
              value={arrivalTimeFilters}
              options={ARRIVAL_TIMES}
              onChange={handleArrivalFilterChange}
              onReset={() => handleArrivalFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="poTypes"
              label="All PO Types"
              value={poTypes}
              options={
                PO_TYPES
                  ? PO_TYPES.map((type) => ({
                      label: `${type.configCd} (${type.configValue})`,
                      value: type.configCd,
                    }))
                  : []
              }
              onChange={handlePOTypeFilterChange}
              onReset={() => handlePOTypeFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="commodityTypes"
              label="All Commodity Types"
              searchable={true}
              value={commodityTypes}
              options={
                COMMODITY_TYPES
                  ? COMMODITY_TYPES.map((item) => ({
                      label: `${item.configCd} (${item.configValue})`,
                      value: item.configCd,
                    }))
                  : []
              }
              onChange={handleCommodityTypeFilterChange}
              onReset={() => handleCommodityTypeFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="poStatuses"
              label="All Statuses"
              value={poStatuses}
              options={
                PO_STATUES
                  ? PO_STATUES.map((status) => ({
                      label: `${status.configValue}`,
                      value: status.configValue,
                    }))
                  : []
              }
              onChange={handleStatusFilterChange}
              onReset={() => handleStatusFilterChange([])}
            />
          </View.Item>
          <View.Item
            attributes={{
              style: { height: '100%' },
            }}
          >
            <View height="100%" justify="center">
              <Actionable onClick={onClearAll}>
                <Text
                  className={styles['po-dashboard__actionable-text']}
                  size="087"
                  weight="medium"
                  variant="text-link"
                >
                  {t('PODashboard.ClearAll')}
                </Text>
              </Actionable>
            </View>
          </View.Item>
        </View>

        <View direction="row" padding={[2, 6]} backgroundColor="secondary">
          {poCategory && (
            <View className={styles['po-dashboard__filter-item']}>
              <Text size="087">{poCategory.label}</Text>
              <Actionable onClick={() => handlePOCategoryFilterChange(undefined)}>
                <Icon svg={Close} size={4} />
              </Actionable>
            </View>
          )}
          {arrivalTimeFilters.length > 0 &&
            arrivalTimeFilters.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(arrivalTimeFilters, handleArrivalFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {poTypes.length > 0 &&
            poTypes.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(poTypes, handlePOTypeFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {commodityTypes.length > 0 &&
            commodityTypes.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(commodityTypes, handleCommodityTypeFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {poStatuses.length > 0 &&
            poStatuses.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(poStatuses, handleStatusFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
        </View>

        {currentView === LIST && (
          <View padding={[4, 6]}>
            <Table
              columns={PO_DASHBOARD_TABLE_COLUMNS}
              // rows={mapPODashboardTableRows(poData, onActionMenuClick)}
              rows={mapPODashboardTableAgeBreakdownRows(poData, onActionMenuClick)}
              isPaginated={true}
              isCheckboxDisabled={false}
              isApiLoadedData={true}
              pageSize={PAGE_SIZE}
              defaultPage={DEFAULT_PAGE}
              isCreditItem={false}
              isCheckboxTable={false}
              styleVariant={TableStylingVariants.DETAILS}
              totalPages={Math.ceil(
                trailerOrdersData ? trailerOrdersData.totalElements / PAGE_SIZE : 0
              )}
              // TODO: implement sorting from the BFF
              onPageChange={(_pageNumber) => {
                setCurrentPage(_pageNumber);
              }}
              onSort={(_sorting, _columnId) => {
                _sorting.forEach((sorting) => {
                  if (sorting.id === _columnId) {
                    setSortBy(_columnId);
                    setDirection(sorting.direction);
                  }
                });
              }}
              onRowAction={(clickedRow) => handleTableRowClick(clickedRow)}
            />
          </View>
        )}

        {currentView === KANBAN && (
          <View padding={[4, 6]} align="center">
            <Text size="250">{t('KanbanView')}</Text>
          </View>
        )}
      </View>

      <AddPOModal isOpen={showAddPOModal} onClose={onCloseAddPOModal} />
    </>
  );
};
