/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]+$/;
export const ALPHA_NUMERIC_WITH_SPACE_REGEX = /^[a-zA-Z0-9\s]+$/;
export const CAMEL_CASE_REGEX = /([a-z0-9])([A-Z])/g;
export const NUMERIC_REGEX = /^[0-9]+$/;
