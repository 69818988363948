/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { GetLayoutParams } from '@mdm/types/types.ts';
import { useEffect } from 'react';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { consolidationLocationService } from '@mdm/services/consolidationLocationService.ts';

export const useGetConsolidationLocations = (options: GetLayoutParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: locationsData,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidationLocations', options],
    queryFn: () => consolidationLocationService.getLocations(options),
    enabled: isEnabled,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (isEnabled && isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.ConsolidationLocations.Description'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { locationsData, isFetching, isError, refetch };
};
