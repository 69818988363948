/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { LayoutRequest } from '@mdm/types/types.ts';
import { apiService } from '@mdm/services/apiService.ts';
import { LAYOUT_ENDPOINTS } from '@shared/constants/routes.ts';
import { parseAndLog } from '@mdm/utils/utils.ts';
import { LayoutSchema } from '@mdm/schemas/layoutSchema.ts';

export const layoutService = {
  createLayout: async (layoutRequest: LayoutRequest) => {
    const resp = await apiService.post(LAYOUT_ENDPOINTS.CREATE_LAYOUT, layoutRequest);
    return parseAndLog(LayoutSchema, resp.data);
  },
  deleteLayout: async (layoutKey: string) => {
    const resp = await apiService.put(LAYOUT_ENDPOINTS.UPDATE_LAYOUT(layoutKey), {
      isActive: false,
    });
    return parseAndLog(LayoutSchema, resp.data);
  },
  updateLayout: async (layoutKey: string, layoutRequest: LayoutRequest) => {
    const payload = { ...layoutRequest };
    // eslint-disable-next-line no-prototype-builtins
    if (payload.hasOwnProperty('active')) {
      payload.isActive = payload.active;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete payload.active; // get request returns active field while update request expects isActive, so adding this line to remove active field until backend update it
    }
    const resp = await apiService.put(LAYOUT_ENDPOINTS.UPDATE_LAYOUT(layoutKey), payload);
    return parseAndLog(LayoutSchema, resp.data);
  },
};
