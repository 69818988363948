/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const UserSchema = z.object({
  employeeId: z.string(),
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  statusCd: z.string(),
  preferredLanguage: z.string(),
  hireDate: z.string(),
  wageCd: z.string(),
});

export const RoleSchema = z.object({
  roleId: z.string(),
  locale: z.string(),
  roleDesc: z.string(),
});

export const DeptSchema = z.object({
  deptId: z.string(),
  locale: z.string(),
  deptDesc: z.string(),
});

export const ShiftSChema = z.object({
  shiftId: z.string(),
  locale: z.string(),
  deptDesc: z.string(),
  fromTime: z.string(),
  toTime: z.string(),
  dayOfWeek: z.string(),
});

export const UserSearchSchema = z.object({
  users: UserSchema,
  role: RoleSchema.optional().nullable(),
  dept: DeptSchema.optional().nullable(),
  shift: ShiftSChema.optional().nullable(),
});

export const UsersSearchListSchema = z.array(UserSearchSchema);
