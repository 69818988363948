/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import axios from 'axios';

import {
  ACCEPT_LANGUAGE,
  FACILITY_ID,
  Language,
  ORGANISATION_ID,
} from '@shared/constants/constants';
import { LOCAL_LANGUAGE } from '@shared/constants/storageConstants';

import { LogError } from '@ofm/classes/LogError.ts';
import { formatErrorLog } from '@ofm/utils/utils.ts';
import { ErrorLogType } from '@ofm/constants/constants.ts';

const apiService = axios.create({
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiService.interceptors.request.use(
  (config) => {
    config.headers['Org-Id'] = ORGANISATION_ID;
    config.headers['Facility-Id'] = FACILITY_ID;
    config.headers['Accept-Language'] =
      ACCEPT_LANGUAGE[(localStorage.getItem(LOCAL_LANGUAGE) as Language) || Language.ENGLISH];

    return config;
  },
  (error) => {
    throw new LogError(formatErrorLog(ErrorLogType.AXIOS, error, error?.response?.status));
  }
);

apiService.interceptors.response.use(
  (response) => response,
  (e) => {
    const error = e?.response?.data?.apierror
      ? e.response.data.apierror
      : { message: ErrorLogType.AXIOS };
    return Promise.reject(error);
  }
);

export { apiService };
