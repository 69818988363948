/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { INBOUND_RECEIVING_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@inbound/services/apiService.ts';
import { TrailerOrderListSchema } from '@inbound/schemas/trailerOrderSchema';
import { GetTrailerOrdersParams } from '@inbound/types/service.type';
import { paginatedParseAndLog } from '@inbound/utils/service/serviceUtils';

export const trailerOrderService = {
  getTrailerOrders: async (options?: GetTrailerOrdersParams) => {
    const payload = {
      searchCriteria: {
        ...options?.searchCriteria,
      },
      searchPage: {
        page: options?.searchPage.currentPage,
        ...(options?.searchPage.pageSize && { size: options.searchPage.pageSize }),
        ...(options?.searchPage.sortBy && { sortBy: options.searchPage.sortBy }),
        ...(options?.searchPage.direction && {
          direction: options.searchPage.direction.toUpperCase(),
        }),
      },
    };

    const response = await apiService.post(INBOUND_RECEIVING_ENDPOINTS.GET_TRAILER_ORDERS, payload);

    return paginatedParseAndLog(TrailerOrderListSchema, response.data);
  },
};
