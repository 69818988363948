/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const EMPTY_RECORD_COUNT = 0;
export const WAREHOUSE_CONFIGURATION_TABLE_PAGE_SIZE = 1;
export const DEFAULT_CONSOLIDATION_STATUS = 'AVAILABLE';
export const SEARCH_MENU_MAX_HEIGHT = 300;
export const DEFAULT_DIMENSION_UNIT = 'in';
export const DEFAULT_DETAIL_VIEW_LOADING_COUNT = 10;
export const FIELD_EVENT = {
  CHANGE: 'change',
};

export const LAYOUT_ENTITY_CODE = {
  SUBZONE: 'SZ',
  ZONE: 'ZN',
  LOCATION: 'LC',
};

export const LAYOUT_ENTITY_TYPE_CODE = {
  DEFAULT: 'DEFAULT',
  CONSOLIDATED: 'CONSOLIDATED',
  VIRTUAL: 'VIRTUAL',
};

export const LOCATION_FLAG_TYPES = [
  {
    label: 'Random',
    value: 'RANDOM',
  },
  {
    label: 'Dedicated',
    value: 'DEDICATED',
  },
  {
    label: 'Dynamic',
    value: 'DYNAMIC',
  },
];

export const CONSOLIDATION_LOCATION_TYPES = [
  {
    value: 'CONVEYOR',
    label: 'Conveyor',
  },
  {
    value: 'CONV_SORT',
    label: 'Conveyor Sort',
  },
  {
    value: 'DOCK',
    label: 'Dock',
  },
  {
    value: 'MRG_ORD',
    label: 'Merge Order',
  },
  {
    value: 'PACK',
    label: 'Pack',
  },
  {
    value: 'PAL_POS',
    label: 'Pallet Position',
  },
  {
    value: 'RECV_DOCK',
    label: 'Receiving Dock',
  },
  {
    value: 'RETURNS',
    label: 'Returns',
  },
  {
    value: 'SHIP_NCF',
    label: 'Ship NCF',
  },
  {
    value: 'STAGE',
    label: 'Stage',
  },
  {
    value: 'STAGE_PICK',
    label: 'Stage Pick',
  },
];

export const LOCATION_TYPES = [
  {
    value: 'RSRV',
    label: 'Reserve location',
  },
  {
    value: 'SLOT',
    label: 'Slot Pick location',
  },
];

export const CONSOLIDATION_STATUS_OPTIONS = [
  {
    label: 'Available',
    value: 'AVAILABLE',
  },
  {
    label: 'Mixed',
    value: 'MIXED',
  },
  {
    label: 'Single',
    value: 'SINGLE',
  },
];

export const ALLOW_ONLY_POSITIVENUMBER = 'allowOnlyPositiveNumber';
export const ATTRIBUTE_FIELD = {
  LOCATION: {
    CHECK_DIGIT: 'CHECK_DIGIT',
    STOCKROOM: 'STOCKROOM',
  },
};

export const VALID_LOCATION_SKU = ['RESLOTTED', '000000000', '999999999'];

export const LOCATION_NAME_REGEX = /^[A-Z]{3}\d{4}[A-Z]{1}$/;

export const MAX_LENGTH_FIELD = {
  SUB_ZONE_CATEGORY_LABEL: 25,
  ZONE_MAP_SEQUENCE: 3,
  ZONE_NAME: 4,
  ZONE_DESCRIPTION: 25,
  CHECK_DIGIT: 2,
  LOCATION_SKU: 9,
  LOCATION_NAME: 8,
  LOCATION_LOT: 18,
  LOCATION_MINIMUM: 8,
  LOCATION_MAXIMUM: 8,
  LOCATION_SLOT_FRONT: 6,
  LOCATION_SLOT_LAYER: 6,
  LOCATION_SLOT_DEEP: 6,
  LOCATION_CURRENT_WIDTH: 9,
};

export const SUBZONE_PACK_METHOD_OPTIONS = [
  {
    label: 'Standard',
    value: 'STANDARD',
  },
  {
    label: 'Will Call',
    value: 'WILLCALL',
  },
];

export const SUBZONE_PICK_METHOD_OPTIONS = [
  {
    label: 'GLS Standard Method',
    value: 'STD',
  },
  {
    label: 'Pick To Light',
    value: 'PTL',
  },
  {
    label: 'Pick To Container',
    value: 'PTC',
  },
  {
    label: 'Advance Pick List',
    value: 'APL',
  },
  {
    label: 'Batch Merge',
    value: 'BM',
  },
  {
    label: 'Pick and Pack',
    value: 'PAP',
  },
  {
    label: 'Oil Picking',
    value: 'OP',
  },
  {
    label: 'Bucket Brigade',
    value: 'BB',
  },
  {
    label: 'Voice',
    value: 'VOI',
  },
];

export const SUBZONE_LABEL_SORT_OPTIONS = [
  {
    label: 'GLS Default Pick Route',
    value: 'STANDARD_PICK_ROUTE',
  },
  {
    label: 'Pick down left side, then up right side',
    value: 'DOWN_LEFT_UP_RIGHT',
  },
  {
    label: 'Side-to-Side picking',
    value: 'SIDE_TO_SIDE',
  },
  {
    label: 'Z Picking',
    value: 'ZPICK',
  },
  {
    label: 'Aisle Sweep',
    value: 'AISLE_SWEEP',
  },
];

export const SUBZONE_SKU_PICK_CLASS_OPTIONS = [
  {
    label: 'Broken-Case',
    value: 'BROKEN_CASE',
  },
  {
    label: 'Case',
    value: 'CASE',
  },
  {
    label: 'Pallet',
    value: 'PALLET',
  },
];

export const SUBZONE_CONTAINER_TYPE_OPTIONS = [
  {
    label: 'Battery Pallet',
    value: 'BATTERY_PALLET',
  },
  {
    label: 'Black Tote',
    value: 'BLACK_TOTE',
  },
  {
    label: 'CD2',
    value: 'CD2',
  },
  {
    label: 'Core Bin',
    value: 'CORE_BIN',
  },
  {
    label: 'Gaylord Tote',
    value: 'GAYLORD_TOTE',
  },
  {
    label: 'Gray Tote',
    value: 'GRAY_TOTE',
  },
  {
    label: 'Jack',
    value: 'JACK',
  },
  {
    label: 'Orange Tote',
    value: 'ORANGE_TOTE',
  },
  {
    label: 'Regular Pallet',
    value: 'REGULAR_PALLET',
  },
  {
    label: 'Trailer',
    value: 'TRAILER',
  },
];

export const SUBZONE_TYPE_OPTIONS = [
  {
    label: 'Long Tail Distribution',
    value: 'LTD',
  },
  {
    label: 'Forward Pick',
    value: 'FWP',
  },
  {
    label: 'Prime',
    value: 'PRM',
  },
  {
    label: 'Random',
    value: 'RND',
  },
  {
    label: 'Reserve',
    value: 'RSV',
  },
  {
    label: 'Bulk',
    value: 'BLK',
  },
  {
    label: 'Dead Stock',
    value: 'DST',
  },
  {
    label: 'Non Conforming',
    value: 'NCF',
  },
  {
    label: 'Junk',
    value: 'JNK',
  },
  {
    label: 'Pick To Cart',
    value: 'PTK',
  },
  {
    label: 'Shuttle',
    value: 'AUT',
  },
];

export const LOCATION_FIELD = {
  LOCATION_TYPE: 'LOCATION_TYPE',
  CHECK_DIGIT: 'CHECK_DIGIT',
  SKU: 'SKU',
  LOT: 'LOT',
  QUANTITY_UNITS: 'QUANTITY_UNITS',
  QUANTITY_RESERVED: 'QUANTITY_RESERVED',
  MINIMUM: 'MINIMUM',
  MAXIMUM: 'MAXIMUM',
  PACK_SIZE: 'PACK_SIZE',
  LOCATION_FLAG: 'LOCATION_FLAG',
  LOCATION_LOCK: 'LOCATION_LOCK',
  SLOT_FRONTS: 'SLOT_FRONTS',
  SLOT_LAYERS: 'SLOT_LAYERS',
  SLOT_DEEPS: 'SLOT_DEEPS',
  SLOT_WIDTH: 'SLOT_WIDTH',
  FULL_PALLET: 'FULL_PALLET',
};

export const CONSOLIDATION_LOCATION_FIELD = {
  ZONE_NAME: 'zoneName',
  LOCATION_TYPE: 'CONS_LOCATION_TYPE',
  STATUS: 'STATUS',
  HEIGHT: 'HEIGHT',
  WIDTH: 'WIDTH',
  DEPTH: 'DEPTH',
  ORDER: 'ORDER',
  DOC_PRINTER_LOC: 'DOC_PRINTER_LOC',
  LABEL_PRINTER_LOC: 'LABEL_PRINTER_LOC',
};

export const SUBZONE_FIELD = {
  LABEL_SORT: 'LABEL_SORT',
  PICK_PRIORITY: 'PICK_PRIORITY',
  SUBZONE_TYPE: 'SUBZONE_TYPE',
  MEZZANINE: 'MEZZANINE',
  CONVEYOR_FLAG: 'CONVEYOR_FLAG',
  MULT_PART_ALLOW_LOC: 'MULT_PART_ALLOW_LOC',
  PICK_DROP_LOCATION: 'PICK_DROP_LOCATION',
  PUT_DROP_LOCATION: 'PUT_DROP_LOCATION',
  SKU_PICK_CLASS: 'SKU_PICK_CLASS',
  ITEM_DESC_PROMPT: 'ITEM_DESC_PROMPT',
  PUT_PRIORITY: 'PUT_PRIORITY',
  CATEGORY_LABEL: 'CATEGORY_LABEL',
  BIDIRECTIONAL_PICK: 'BIDIRECTIONAL_PICK',
  PICK_METHOD: 'PICK_METHOD',
  PACK_METHOD: 'PACK_METHOD',
  ITEM_VERIFY: 'ITEM_VERIFY',
  CONTAINER_TYPE: 'CONTAINER_TYPE',
};

export const ZONE_FIELD = {
  MAP_SEQUENCE: 'MAP_SEQUENCE',
};

export const ENTITY_ASSOCIATIONS = {
  ATTRIBUTES: 'ATTRIBUTES',
  CHILDREN: 'CHILDREN',
};

export const LOCATION_FLAG_DYNAMIC = 'DYNAMIC';
