/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { USER_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@shared/services/apiService.ts';
import { UsersSearchListSchema } from '@shared/schemas/usersSearchSchema';
import { UsersSearchParams } from '@shared/types/service.type';
import { parseAndLog } from '@shared/utils/service/serviceUtils';

export const userService = {
  usersSearch: async (options?: UsersSearchParams) => {
    const payload = {
      ...(options?.userId && { userId: options.userId }),
      ...(options?.firstName && { firstName: options.firstName }),
      ...(options?.lastName && { lastName: options.lastName }),
      ...(options?.roleId && { roleId: options.roleId }),
      ...(options?.deptId && { deptId: options.deptId }),
    };

    const response = await apiService.post(USER_ENDPOINTS.USERS_SEARCH, payload);

    return parseAndLog(UsersSearchListSchema, response.data);
  },
};
