/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import { TagItemsProps } from '@taskManagement/components/TaskManagerCard/TaskManagerCard.types';

const TASK_USER_DATA = [
  {
    id: 1,
    name: 'Kboman',
    userName: 'Kboman',
    department: 'Inbound',
    assigned: true,
    clocked_in: false,
    lastTransaction: 'Last transaction 21 min ago',
    workingDepartment: false,
    userStatus: 'Not Active',
  },
  {
    id: 2,
    name: 'bbernard',
    userName: 'bbernard',
    department: 'Inbound',
    vendorName: 'Lexical Technologies',
    priority: true,
    subZone: 'BAB1',
    tagItems: [
      {
        variant: 'order',
        text: 'DSD',
      },
    ] as TagItemsProps[],
    piecesData: {
      value: 59,
      max: 120,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '20730091',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Active',
  },
  {
    id: 3,
    name: 'Kwheeler',
    userName: 'Kwheeler',
    department: 'Inbound',
    assigned: true,
    workingDepartment: false,
    clocked_in: false,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
  {
    id: 4,
    name: 'abond',
    userName: 'abond',
    department: 'Inbound',
    assigned: false,
    workingDepartment: false,
    clocked_in: false,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
  {
    id: 5,
    name: 'Sammy',
    userName: 'Sammy',
    department: 'Inbound',
    assigned: true,
    workingDepartment: true,
    clocked_in: false,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
  {
    id: 6,
    name: 'Kjohn',
    userName: 'Kjohn',
    department: 'Inbound',
    vendorName: 'Ring & Pinion Service Inc.',
    priority: true,
    subZone: 'BAB1',
    tagItems: [
      {
        variant: 'order',
        text: 'DSD',
      },
      {
        variant: 'order',
        text: 'OIL',
      },
    ] as TagItemsProps[],
    piecesData: {
      value: 77,
      max: 150,
      label: 'Done',
    },
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007893',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    userStatus: 'Active',
  },
  {
    id: 7,
    name: 'jjohnSton',
    userName: 'jjohnSton',
    department: 'Inbound',
    vendorName: '3 Outlet Wall Tap',
    priority: true,
    subZone: 'FMB2',
    statusBadge: {
      variant: StatusVariants.IN_PROGRESS,
      text: '7005',
    },
    piecesData: {
      value: 40,
      max: 86,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '20730091',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Active',
  },
  {
    id: 9,
    name: 'mtania',
    userName: 'mtania',
    department: 'Inbound',
    assigned: true,
    workingDepartment: false,
    clocked_in: false,
    lastTransaction: 'Last transaction 11 min ago',
    userStatus: 'Not Active',
  },
  {
    id: 8,
    name: 'Merry',
    userName: 'Merry',
    department: 'Inbound',
    assigned: false,
    workingDepartment: false,
    clocked_in: true,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
  {
    id: 10,
    name: 'pturner',
    userName: 'pturner',
    department: 'Inbound',
    assigned: true,
    vendorName: 'Crismon Mechanical LLC.',
    priority: true,
    subZone: 'BAB2',
    tagItems: [
      {
        variant: 'order',
        text: 'LTD',
      },
    ] as TagItemsProps[],
    piecesData: {
      value: 11,
      max: 86,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007811',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Active',
  },
  {
    id: 11,
    name: 'dfred',
    userName: 'dfred',
    department: 'Inbound',
    vendorName: 'Motorcar Parts of America',
    priority: true,
    subZone: 'CRAC',
    piecesData: {
      value: 80,
      max: 86,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007891',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Almost Done',
  },
  {
    id: 12,
    name: 'akarrison',
    userName: 'akarrison',
    department: 'Inbound',
    vendorName: 'Motorcar Parts of America',
    priority: true,
    subZone: 'CRAC',
    piecesData: {
      value: 80,
      max: 86,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007891',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Almost Done',
  },
  {
    id: 13,
    name: 'kleanna',
    userName: 'kleanna',
    department: 'Inbound',
    vendorName: 'Duralast Brake Pads',
    priority: false,
    subZone: 'CRAC',
    piecesData: {
      value: 80,
      max: 86,
      label: 'Done',
    },
    statusBadge: {
      variant: StatusVariants.READY_FOR_ACTION,
      text: '7000',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007891',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Almost Done',
  },
  {
    id: 14,
    name: 'ddouglas',
    userName: 'ddouglas',
    department: 'Inbound',
    vendorName: 'Borg Propulsion Systems',
    priority: false,
    subZone: 'CRAC',
    piecesData: {
      value: 80,
      max: 86,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007891',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Almost Done',
  },
  {
    id: 15,
    name: 'Gorge',
    userName: 'Gorge',
    department: 'Inbound',
    assigned: false,
    workingDepartment: false,
    clocked_in: true,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
  {
    id: 16,
    name: 'klauren',
    userName: 'klauren',
    department: 'Inbound',
    vendorName: 'Borg Propulsion Systems',
    priority: false,
    subZone: 'CRAC',
    piecesData: {
      value: 80,
      max: 86,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007891',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Almost Done',
  },
  {
    id: 17,
    name: 'fellany',
    userName: 'fellany',
    department: 'Inbound',
    vendorName: 'Borg Propulsion Systems',
    priority: false,
    subZone: 'CRAC',
    piecesData: {
      value: 80,
      max: 86,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    poNumber: '2007891',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Almost Done',
  },
  {
    id: 18,
    name: 'Bruce',
    userName: 'Bruce',
    department: 'Inbound',
    assigned: false,
    workingDepartment: false,
    clocked_in: true,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
  {
    id: 20,
    name: 'fernandez',
    userName: 'fernandez',
    department: 'Inbound',
    assigned: false,
    workingDepartment: false,
    clocked_in: true,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
  {
    id: 21,
    name: 'Leesa',
    userName: 'Leesa',
    department: 'Inbound',
    assigned: true,
    vendorName: 'Crismon Mechanical LLC.',
    priority: true,
    poNumber: '2007811',
    subZone: 'BAB2',
    tagItems: [
      {
        variant: 'order',
        text: 'LTD',
      },
    ] as TagItemsProps[],
    piecesData: {
      value: 72,
      max: 100,
      label: 'Done',
    },
    arrivalDate: '03/06/23 13:32',
    rcNumber: '08',
    gate: 6,
    aisle: 10,
    users: [
      {
        id: 1,
        firstName: 'Aaqram',
        lastName: 'Aikey',
      },
    ],
    linesData: {
      label: 'Lines',
      count: 99,
      total: 99,
    },
    userStatus: 'Active',
  },
  {
    id: 22,
    name: 'Amanda Bond',
    userName: 'abond',
    department: 'Inbound',
    assigned: false,
    workingDepartment: false,
    clocked_in: true,
    lastTransaction: 'No last transaction',
    userStatus: 'Not Active',
  },
];

const SEARCH_PROPERTIES = {
  USER: {
    queryParam: 'user',
    search: 'search',
  },
};

export { TASK_USER_DATA, SEARCH_PROPERTIES };
