/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { inboundOrderService } from '@inbound/services/inboundOrderService';

export const useGetInboundOrderBySourceAndTrailerInId = (
  params: { sourceOrderNumber: string; trailerInId: string },
  isEnabled = true
) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: inboundOrderData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['sourceOrderNumber', params],
    queryFn: () =>
      inboundOrderService.getInboundOrderBySourceOrderNumberAndTrailerInId(
        params.sourceOrderNumber,
        params.trailerInId
      ),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isEnabled && isError) {
      handleNotification(
        NOTIFICATION_TYPES.ERROR,
        t('PODashboard.Notification.GetInboundOrderBySourceNumber')
      );
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { inboundOrderData, isLoading, isError, refetch };
};
