/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useSessionStorage } from '@shared/hooks/useStorage.ts';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants.ts';
import { z } from 'zod';
import { LocationSchema } from '@mdm/schemas/locationSchema.ts';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import {
  Checkbox,
  FormControl,
  Link,
  Loader,
  Radio,
  Select,
  Text,
  TextField,
  View,
} from '@az/starc-ui';
import styles from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationDetail.module.scss';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { displayAttributeFieldValidationMessage } from '@mdm/utils/form/validationUtils.tsx';
import {
  ATTRIBUTE_FIELD,
  DEFAULT_DIMENSION_UNIT,
  FIELD_EVENT,
  LOCATION_FIELD,
  LOCATION_FLAG_DYNAMIC,
  MAX_LENGTH_FIELD,
} from '@mdm/constants/constants.ts';
import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from '@mdm/types/types.ts';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { CONFIG, DEFAULT_PAGE } from '@shared/constants/constants.ts';
import { getFormInputError } from '@ofm/utils/utils.ts';

type Props = {
  locationId?: string;
  selectedSubzoneId?: string;
};

export const LocationDetailForm = ({ locationId, selectedSubzoneId }: Props) => {
  /* State variables */
  const [disabledMinMaxAttribute, setDisabledMinMaxAttribute] = useState<boolean>(false);
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);
  const [stockroomOptions, setStockroomOptions] = useState<SelectOption[]>([]);
  const [locationTypeOptions, setLocationTypeOptions] = useState<SelectOption[]>([]);
  const [locationFlagOptions, setLocationFlagOptions] = useState<SelectOption[]>([]);

  /* Constants */
  type FormData = z.infer<typeof LocationSchema>;
  const { t } = useTranslation();

  const { subzonesData, isFetching: isSubzoneLoading } = useGetSubzones({
    currentPage: DEFAULT_PAGE - 1,
    entityAssociations: [],
  });

  const { configsData, isLoading: isConfigDataLoading } = useGetFacilityConfig({
    domainCd: CONFIG,
  });

  const {
    control,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useFormContext<FormData>();

  /* Hooks */
  const populateDropdownOptions = useCallback(() => {
    const locationTypes = (configsData || [])
      .filter((config) => config.subDomainCd === LOCATION_FIELD.LOCATION_TYPE)
      .map((config) => ({
        label: config.configValue,
        value: config.configCd,
      }));
    setLocationTypeOptions(locationTypes);

    const locationFlags = (configsData || [])
      .filter((config) => config.subDomainCd === LOCATION_FIELD.LOCATION_FLAG)
      .map((config) => ({
        label: config.configValue,
        value: config.configCd,
      }));

    setLocationFlagOptions(locationFlags);
  }, [configsData]);

  const setStockOnSubzoneChange = useCallback(
    (subzoneId: string) => {
      setStockroomOptions([]);
      const selectedSubzone = (subzonesData?.content || []).find(
        (subzone) => subzone.layout.layoutDistinctName === subzoneId
      );

      if (selectedSubzone) {
        let stockroom = '';
        const foundStockroomAttribute = selectedSubzone.layout.attributes
          ? selectedSubzone.layout.attributes.find(
              (attribute) => attribute.name === ATTRIBUTE_FIELD.LOCATION.STOCKROOM
            )
          : null;

        if (foundStockroomAttribute) {
          stockroom = foundStockroomAttribute.value;
          setStockroomOptions([{ label: stockroom, value: stockroom }]);
        }
        const stockroomIndex = getFormFieldIndexByKey(
          getValues()['attributes'],
          ATTRIBUTE_FIELD.LOCATION.STOCKROOM
        );
        setValue(`attributes.${stockroomIndex}`, {
          name: ATTRIBUTE_FIELD.LOCATION.STOCKROOM,
          value: stockroom,
        });
      }
    },
    [getValues, setValue, subzonesData?.content]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // watch for subzone change and set stockroom
      if (
        name === 'parentLayoutDistinctName' &&
        type === FIELD_EVENT.CHANGE &&
        value.parentLayoutDistinctName
      ) {
        setStockOnSubzoneChange(value.parentLayoutDistinctName);
      }

      const locationFlagAttributeIndex = getFormFieldIndexByKey(
        getValues()['attributes'],
        LOCATION_FIELD.LOCATION_FLAG
      );

      if (
        locationFlagAttributeIndex !== -1 &&
        name === `attributes.${locationFlagAttributeIndex}.value` &&
        type === FIELD_EVENT.CHANGE
      ) {
        const attributes = getValues()['attributes'];
        if (attributes && attributes[locationFlagAttributeIndex].value === LOCATION_FLAG_DYNAMIC) {
          setDisabledMinMaxAttribute(true);
          const minimumAttributeIndex = getFormFieldIndexByKey(
            getValues()['attributes'],
            LOCATION_FIELD.MINIMUM
          );
          if (minimumAttributeIndex !== -1) {
            setValue(`attributes.${minimumAttributeIndex}.value`, '0');
          }

          const maximumAttributeIndex = getFormFieldIndexByKey(
            getValues()['attributes'],
            LOCATION_FIELD.MAXIMUM
          );

          if (maximumAttributeIndex !== -1) {
            setValue(`attributes.${maximumAttributeIndex}.value`, '0');
          }
        } else {
          setDisabledMinMaxAttribute(false);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setStockOnSubzoneChange, setValue, watch]);

  useEffect(() => {
    if (selectedSubzoneId) {
      setValue('parentLayoutDistinctName', selectedSubzoneId);
    }
  }, [selectedSubzoneId, setValue]);

  useEffect(() => {
    populateDropdownOptions();
  }, [configsData, populateDropdownOptions]);

  return (
    <View>
      <form>
        <View direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('Warehouse')} isRequired={true} />
              <FormControl>
                <Select
                  label={t('Warehouse')}
                  name="warehouse"
                  variant="no-label"
                  multiSelect={false}
                  defaultValue={{
                    label: 'DC ' + selectedDC?.toString(),
                    value: 'DC ' + selectedDC?.toString(),
                  }}
                  options={[]}
                  disabled
                />
              </FormControl>
              <Text weight="regular" color="600" size="087">
                {t('LocationDetails.WarehouseInstruction')}
              </Text>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'subzone-name',
              }}
              gap={2}
            >
              <FormLabel text={t('LocationDetails.SubzoneName.Label')} isRequired={true} />
              <FormControl hasError={!!errors.parentLayoutDistinctName}>
                <Controller
                  control={control}
                  name="parentLayoutDistinctName"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isSubzoneLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('LocationDetails.SubzoneName.Placeholder')}
                          variant="no-label"
                          options={(subzonesData?.content || []).map((row) => ({
                            label: row.layout.layoutName,
                            value: row.layout.layoutDistinctName.toString(),
                          }))}
                          value={{
                            label:
                              (subzonesData?.content || []).find(
                                (option) => option.layout.layoutDistinctName === value
                              )?.layout.layoutName || '',
                            value: value || '',
                          }}
                          disabled={!!selectedSubzoneId}
                          inputAttributes={{ onBlur }}
                          onValueChange={(subzone) => onChange(subzone?.value)}
                        />
                      )}
                    </>
                  )}
                />

                {errors.parentLayoutDistinctName && (
                  <View direction="row" justify="space-between">
                    <FormControl.Error>
                      {getFormInputError(errors.parentLayoutDistinctName.type)}
                    </FormControl.Error>
                  </View>
                )}
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'stockroom',
              }}
              gap={2}
            >
              <FormLabel text={t('LocationDetails.Stockroom.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], 'stockroom')
                    )
                  )
                }
              >
                <Select
                  label={t('LocationDetails.Stockroom.Placeholder')}
                  variant="no-label"
                  options={stockroomOptions}
                />

                {errors.attributes &&
                  displayAttributeFieldValidationMessage(
                    errors,
                    getFormFieldIndexByKey(getValues()['attributes'], 'stockroom')
                  ) && (
                    <View direction="row" justify="space-between">
                      <FormControl.Error>
                        {displayAttributeFieldValidationMessage(
                          errors,
                          getFormFieldIndexByKey(getValues()['attributes'], 'stockroom')
                        )}
                      </FormControl.Error>
                    </View>
                  )}
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Location.Label')} isRequired={true} />
              <FormControl hasError={!!errors.layoutName}>
                <TextField
                  attributes={{
                    'data-testid': 'name',
                  }}
                  inputAttributes={{
                    placeholder: t('LocationDetails.Location.Placeholder'),
                    ...register('layoutName'),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_MINIMUM,
                  }}
                  defaultValue=""
                  disabled={!!locationId}
                />

                {errors.layoutName && (
                  <View direction="row" justify="space-between">
                    <FormControl.Error>{errors.layoutName.message}</FormControl.Error>
                  </View>
                )}
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'location-type',
              }}
              gap={2}
            >
              <FormLabel text={t('LocationDetails.LocationType.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_TYPE
                      )
                    )
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.LOCATION_TYPE
                  )}.value`}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isConfigDataLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('LocationDetails.LocationType.Placeholder')}
                          variant="no-label"
                          options={locationTypeOptions}
                          value={{
                            label:
                              locationTypeOptions.find((option) => option.value === value)?.label ||
                              '',
                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(LOCATION_TYPE) => onChange(LOCATION_TYPE?.value)}
                        />
                      )}
                    </>
                  )}
                />

                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_TYPE
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.CheckDigit.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.CHECK_DIGIT)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'check-digit',
                  }}
                  placeholder={t('LocationDetails.CheckDigit.Placeholder')}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.CheckDigit.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.CHECK_DIGIT
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.CHECK_DIGIT,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.CHECK_DIGIT)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Sku.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SKU)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'sku',
                  }}
                  placeholder={t('LocationDetails.Sku.Placeholder')}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.Sku.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.SKU
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_SKU,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SKU)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={`${t('LocationDetails.Lot.Label')} (${t('Optional')})`} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.LOT)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'lot',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.Lot.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOT
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_LOT,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.LOT)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.QuantityUnits.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_UNITS
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'quantity-units',
                  }}
                  defaultValue=""
                  disabled={true}
                  inputAttributes={{
                    placeholder: t('LocationDetails.QuantityUnits.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_UNITS
                      )}.value`
                    ),
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_UNITS
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.QuantityReserved.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_RESERVED
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'quantity-reserved',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.QuantityUnits.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_RESERVED
                      )}.value`
                    ),
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_RESERVED
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Minimum.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.MINIMUM)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'minimum',
                  }}
                  defaultValue=""
                  disabled={
                    (getValues()['attributes']
                      ? getValues()['attributes']?.[
                          getFormFieldIndexByKey(
                            getValues()['attributes'],
                            LOCATION_FIELD.LOCATION_FLAG
                          )
                        ]['value'] === LOCATION_FLAG_DYNAMIC
                      : '') || disabledMinMaxAttribute
                  }
                  inputAttributes={{
                    placeholder: t('LocationDetails.Minimum.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.MINIMUM
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_MINIMUM,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.MINIMUM)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Maximum.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.MAXIMUM)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'maximum',
                  }}
                  defaultValue=""
                  disabled={
                    (getValues()['attributes']
                      ? getValues()['attributes']?.[
                          getFormFieldIndexByKey(
                            getValues()['attributes'],
                            LOCATION_FIELD.LOCATION_FLAG
                          )
                        ]['value'] === LOCATION_FLAG_DYNAMIC
                      : '') || disabledMinMaxAttribute
                  }
                  inputAttributes={{
                    placeholder: t('LocationDetails.Maximum.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.MAXIMUM
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_MAXIMUM,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.MAXIMUM)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={`${t('LocationDetails.PackSize.Label')} (${t('Optional')})`} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.PACK_SIZE)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'pack-size',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.PackSize.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.PACK_SIZE
                      )}.value`
                    ),
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.PACK_SIZE)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'location-flag',
              }}
              gap={2}
            >
              <FormLabel text={`${t('LocationDetails.LocationFlag.Label')} (${t('Optional')})`} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_FLAG
                      )
                    )
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.LOCATION_FLAG
                  )}.value`}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isConfigDataLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('LocationDetails.LocationFlag.Placeholder')}
                          variant="no-label"
                          options={locationFlagOptions}
                          value={{
                            label:
                              locationFlagOptions.find((option) => option.value === value)?.label ||
                              '',

                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(LOCATION_FLAG) => onChange(LOCATION_FLAG?.value)}
                        />
                      )}
                    </>
                  )}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_FLAG
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 4 }}>
            <View
              attributes={{
                'data-testid': 'location-lock',
              }}
              gap={2}
            >
              <FormLabel text={`${t('LocationDetails.LocationLock.Label')} (${t('Optional')})`} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_LOCK
                      )
                    )
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.LOCATION_LOCK
                  )}.value`}
                  render={({ field: { onChange, value } }) => (
                    <View direction="row" gap={4}>
                      <Radio
                        value="true"
                        label={t('LocationDetails.Locked')}
                        checked={value === 'true'}
                        onChange={(isMultiplePartsAllowedWithinLocation) =>
                          onChange(isMultiplePartsAllowedWithinLocation)
                        }
                      />
                      <Radio
                        value="false"
                        label={t('LocationDetails.Unlocked')}
                        checked={value === 'false'}
                        onChange={(isMultiplePartsAllowedWithinLocation) =>
                          onChange(isMultiplePartsAllowedWithinLocation)
                        }
                      />

                      <View>
                        <Link onClick={() => onChange('false')}>{t('Clear')}</Link>
                      </View>
                    </View>
                  )}
                />

                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_LOCK
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>
        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.SlotFronts.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_FRONTS)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'slot-fronts',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.SlotFronts.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.SLOT_FRONTS
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_FRONT,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_FRONTS)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.SlotLayers.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_LAYERS)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'slot-layers',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.SlotLayers.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        'SLOT_LAYERS'
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_LAYER,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_LAYERS)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.SlotDeeps.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_DEEPS)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'slot-deeps',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.SlotDeeps.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.SLOT_DEEPS
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_DEEP,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_DEEPS)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.CurrentWidth.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_WIDTH)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'slot-width',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.CurrentWidth.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.SLOT_WIDTH
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_CURRENT_WIDTH,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_WIDTH)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View
          attributes={{
            'data-testid': 'full-pallet',
          }}
          className={styles['form__field-row']}
        >
          <FormControl
            hasError={
              !!(
                errors.attributes &&
                errors.attributes[
                  getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.FULL_PALLET)
                ]
              )
            }
          >
            <Controller
              control={control}
              name={`attributes.${getFormFieldIndexByKey(
                getValues()['attributes'],
                LOCATION_FIELD.FULL_PALLET
              )}.value`}
              render={({ field: { onChange, value, ref } }) => (
                <Checkbox
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.FULL_PALLET
                  )}.value`}
                  value={value}
                  checked={value.toString() === 'true'}
                  inputAttributes={{ ref }}
                  label={t('LocationDetails.FullPallet.Label')}
                  onChange={(event) => onChange(event)}
                />
              )}
            />
            {errors.attributes &&
              errors.attributes[
                getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.FULL_PALLET)
              ] && (
                <View direction="row" justify="space-between">
                  <FormControl.Error>
                    {
                      errors.attributes[
                        getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.FULL_PALLET
                        )
                      ]?.message
                    }
                  </FormControl.Error>
                </View>
              )}
          </FormControl>
        </View>
      </form>
    </View>
  );
};
