/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

export const LayoutParentSchema = z.object({
  layoutName: z.string(),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().nullable(),
  parentLayoutDistinctName: z.string().nullable(),
});

export const LayoutChildrenSchema = z.object({
  layoutName: z.string(),
  layoutNameDesc: z.string().nullable().default(''),
  childLayoutDistinctName: z.string(),
});

export const LayoutListSchema = z.object({
  layout: z.object({
    layoutKey: z.string(),
    layoutName: z.string(),
    layoutNameDesc: z.string().nullable().default(''),
    layoutDistinctName: z.string(),
    entityCd: z.string(),
    entityTypeCd: z.string(),
    active: z.boolean(),
    attributes: z.array(AttributeSchema).nullable(),
  }),
  parent: LayoutParentSchema.nullable().optional(),
  children: z.array(LayoutChildrenSchema).nullable().optional(),
});

export const LayoutSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z.string(),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().nullable().default(''),
  entityCd: z.string(),
  entityTypeCd: z.string(),
  active: z.boolean().default(true),
  attributes: z.array(AttributeSchema).nullable(),
});
