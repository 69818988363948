/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { GetTrailerOrdersParams } from '@inbound/types/service.type';
import { trailerOrderService } from '@inbound/services/trailerOrderService';

export const useGetTrailerOrders = (options?: GetTrailerOrdersParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: trailerOrdersData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['trailerOrders', options],
    queryFn: () => trailerOrderService.getTrailerOrders(options),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('PODashboard.Notification.GetTrailerOrders'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { trailerOrdersData, isLoading, isError, refetch };
};
