/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Actionable, classNames, Dropdown, Icon, Text, View } from '@az/starc-ui';

import { Ellipses } from '@shared/assets/icons';

import { ActionMenuProps } from '@mdm/components/ActionMenu/ActionMenu.types';
import styles from '@mdm/components/ActionMenu/ActionMenu.module.scss';

export const ActionMenu = ({ menus }: ActionMenuProps) => {
  return (
    <View>
      <Dropdown
        width={250}
        className={styles['action-menu']}
        placement="bottom-end"
        closeOnScroll={true}
      >
        <Dropdown.Button
          className={classNames(styles['action-menu__button'], styles['action-menu__button-icon'])}
        >
          <Icon svg={Ellipses} />
        </Dropdown.Button>
        <Dropdown.Content>
          <View padding={[1, 0]}>
            {menus.map((menu, index) => (
              <View.Item key={index}>
                <View padding={[3, 4]}>
                  <Actionable fullWidth onClick={menu.clickHandler}>
                    <View direction="row" gap={2}>
                      <Text color={menu.labelColor}>{menu.label}</Text>
                      {menu.icon && <Icon svg={menu.icon} />}
                    </View>
                  </Actionable>
                </View>
              </View.Item>
            ))}
          </View>
        </Dropdown.Content>
      </Dropdown>
    </View>
  );
};
