/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const difHeaderData = [
  {
    id: '21',
    groupBy: 'DC',
    pallets: 18,
    pieces: '825 / 3,349',
    stores: [
      '0097301',
      '0097302',
      '0097303',
      '0097304',
      '0097305',
      '0097306',
      '0097307',
      '0097308',
    ],
  },
];

export const difMatrixData = [
  {
    location: 'Aerosol',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '4/4', assigned: null, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Battery',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '4/4', assigned: null, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Hard Parts',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Mezzanine',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '4/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '4/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Oil',
    tiles: [
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Reserves',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '4/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Pick to Cart',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
    ],
  },
  {
    location: 'LTD',
    tiles: [
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
    ],
  },
];
