/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { AxiosResponse } from 'axios';

import { z } from 'zod';

import { LogError } from '@ofm/classes/LogError.ts';
import { ErrorLogType } from '@ofm/constants/constants.ts';
import { formatErrorLog } from '@ofm/utils/utils.ts';

import { PaginatedResponseSchema } from '@inbound/schemas/paginatedResponseSchema';

import { PaginatedResponse } from '@inbound/types/service.type';

export const parseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): z.infer<T> | undefined => {
  const parsedData = schema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as z.infer<typeof schema> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // eslint-disable-next-line no-console
      console.log('ZOD ERROR', parsedData);
    }
    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};

export const paginatedParseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): PaginatedResponse<T> | undefined => {
  const paginatedSchema = PaginatedResponseSchema(schema);
  const parsedData = paginatedSchema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as PaginatedResponse<T> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // eslint-disable-next-line no-console
      console.log('ZOD ERROR', parsedData);
    }
    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};
