/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { t } from 'i18next';

import { Button, SearchBar, Store, Text, View } from '@az/starc-ui';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';

import { DisplayOptions } from './DisplayOptions';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants';
import styles from './ControlDesk.module.scss';
import { useAtom } from 'jotai';
import { useStoreSearch } from '@ofm/services/useStoreSearch';
import { warehouseAtom } from '@ofm/atoms/warehouse/warehouseAtom';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { EmptySuggestions } from '@shared/components/EmptySuggestions/EmptySuggestions';
import {
  inLaneConfirmationAtom,
  laneConfirmationItemAtom,
  replenishedAtom,
  selectedStoresAtom,
  selectedSubzonesAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';

import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { displayOptionsAtom } from '@outbound/atoms/outboundMatrix/outboundMatrixAtoms';
import { ConfirmationModal } from '@outbound/components/Modal/ConfirmationModal';
import { OrderTabs } from './OrderTabs';
import { Overview } from './Overview';

export const ControlDesk = () => {
  /* Atoms */
  const [warehouse] = useAtom(warehouseAtom);
  const [selectedStores] = useAtom(selectedStoresAtom);
  const [selectedSubzones] = useAtom(selectedSubzonesAtom);
  const [inLaneConfirmation, setInLaneConfirmation] = useAtom(inLaneConfirmationAtom);
  const [displayOptions, setDisplayOptions] = useAtom(displayOptionsAtom);
  const [replenishedStores] = useAtom(replenishedAtom);
  const [selectedItem] = useAtom(laneConfirmationItemAtom);

  /* State variables */
  const [storeOrderSearch, setStoreOrderSearch] = useState('');
  const [showLaneConfirmation, setShowLaneConfirmation] = useState(false);

  /* Constants */
  const navigate = useNavigate();
  const location = useLocation();
  const { handleNotification } = useNotificationHandler();
  const isOrderReleasePage = location.pathname.includes(ROUTES.ORDER_RELEASE);
  const isTransferOrderTab = location.pathname.includes(ROUTES.Transfer);
  const isDifOrderTab = location.pathname.includes(ROUTES.DIF);

  const pageTitle = isOrderReleasePage
    ? inLaneConfirmation
      ? 'OutboundMatrix.OrderRelease.LaneConfirmation'
      : 'OutboundMatrix.OrderRelease.Title'
    : 'MasterTitle.OutboundControlDesk';

  /* Queries */
  const { matchingStores, hasNoResults, invalidId } = useStoreSearch(
    storeOrderSearch,
    warehouse.id
  );

  /* Functions */
  const setSearchParam = (param: string) => {
    navigate(PAGE_URLS.STORE_ORDER_DETAILS(param));
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      if (inputValue) {
        setSearchParam(inputValue);
      }
    }
  };
  const onSelectionChange = (value: string) => {
    if (value !== undefined) {
      setSearchParam(value);
    }
  };

  /* Functions */
  const onDisplayOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setDisplayOptions((options) => ({
      ...options,
      [target.name]: target.name === 'sortBy' ? target.value : target.checked,
    }));
  };

  const getOrderReleasePathName = () => {
    const tab = location.pathname.split('/').at(-1);
    return tab ? location.pathname.replace(tab, `order-release/${tab}`) : location.pathname;
  };

  const onProceedToLaneConfirmation = () => {
    if (
      (isTransferOrderTab || isDifOrderTab) &&
      !selectedStores.every((element) => replenishedStores.includes(element))
    ) {
      setShowLaneConfirmation(true);
    } else {
      setInLaneConfirmation(true);
    }
  };

  const onNavigateToControlDesk = () => {
    const selectedTabRoute = location.pathname.split('/').at(-1);
    navigate(`${PAGE_URLS.OUTBOUND_CONTROL_DESK}/${selectedTabRoute}`);
  };

  const onRelease = () => {
    handleNotification(
      NOTIFICATION_TYPES.SUCCESS,
      t('OutboundMatrix.OrderRelease.OrderReleased', {
        //Regular expression to split store numbers by comma and 'and' in notification (Remove after api integration)
        orders: selectedStores.join(', ').replace(/, ([^,]*)$/, ` ${t('OutboundMatrix.And')} $1`),
      })
    );
    onNavigateToControlDesk();
  };

  return (
    <View direction="column" height="100%">
      <MasterTitle
        title={t(pageTitle)}
        // TODO: Add functionality to save page to favorites column
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        titleActionProps={{ label: 'Favorite', handleClick: () => {} }}
        subtitle={
          <View direction="row" gap={4}>
            <Text>
              {t('OutboundMatrix.OrderRelease.ShiftGoalStoresReleased', { count: 15, max: 80 })}
            </Text>
          </View>
        }
        {...(isOrderReleasePage
          ? {
              breadcrumbProps: {
                data: [
                  {
                    label: t('Sidenav.OutboundControlDesk'),
                    onClick: onNavigateToControlDesk,
                  },
                  { label: t('OutboundMatrix.OrderRelease.Title'), onClick: () => void 0 },
                ],
              },
            }
          : {})}
      >
        <View direction="row" justify="end" align="center" gap={4}>
          {!isOrderReleasePage && (
            <>
              <View.Item columns={4}>
                <SearchBar
                  value={storeOrderSearch}
                  className={styles['search-bar']}
                  onValueChange={setStoreOrderSearch}
                  suggestions={hasNoResults ? <EmptySuggestions /> : matchingStores}
                  label={t('Search.StoreNumber')}
                  onSelectionChange={onSelectionChange}
                  inputAttributes={{ onKeyDown: onKeyDown }}
                  maxMenuHeight={300}
                />
              </View.Item>
              <View.Item>
                <DisplayOptions options={displayOptions} onChange={onDisplayOptionsChange} />
              </View.Item>
            </>
          )}
          <View.Item>
            {isOrderReleasePage ? (
              inLaneConfirmation ? (
                <Button
                  size="large"
                  variant="primary"
                  disabled={!selectedStores.length && !selectedSubzones?.length}
                  onClick={onRelease}
                >
                  <View direction="row" align="center" justify="center" gap={2}>
                    <Text>{t('OutboundMatrix.OrderRelease.Release')}</Text>
                  </View>
                </Button>
              ) : (
                <Button
                  size="large"
                  variant="primary"
                  disabled={!selectedStores.length && !selectedSubzones?.length}
                  onClick={onProceedToLaneConfirmation}
                >
                  <View direction="row" align="center" justify="center" gap={2}>
                    <Text>{t('OutboundMatrix.OrderRelease.ProceedToLaneConfirmation')}</Text>
                  </View>
                </Button>
              )
            ) : (
              <Button
                size="large"
                variant="primary"
                onClick={() => navigate(getOrderReleasePathName())}
              >
                <View direction="row" align="center" justify="center" gap={2}>
                  <Text>{t('MasterTitle.ReleaseOrders')}</Text>
                </View>
              </Button>
            )}
          </View.Item>
        </View>
      </MasterTitle>
      <>
        {hasNoResults ? (
          <View height="100%">
            <EmptyState
              svg={Store}
              subtitle={t('Empty.Search.Subtitle')}
              text={t('Empty.Search.Text', {
                value: invalidId,
              })}
            />
          </View>
        ) : (
          <View direction="column" height="100%" width="100%">
            <OrderTabs />
          </View>
        )}
      </>
      {!isOrderReleasePage && <Overview />}
      {showLaneConfirmation && (
        <ConfirmationModal
          title={t('ConfirmationComment.LaneConfirmation')}
          isCancelBtn={true}
          open={showLaneConfirmation}
          onClose={() => setShowLaneConfirmation(false)}
          onSuccess={() => (setInLaneConfirmation(true), setShowLaneConfirmation(false))}
          primaryBtnText={t('ConfirmationComment.YesProceed')}
        >
          <View wrap={true}>
            <Text className={styles['confirmation-text']}>
              {selectedItem &&
              selectedStores.length > 0 &&
              selectedStores.length === selectedItem?.stores?.length
                ? t('ConfirmationComment.LaneConfirmationSubtitle', { id: selectedItem.id })
                : t('ConfirmationComment.LaneConfirmationOrdersSubtitle', {
                    id: selectedStores.filter((store) => !replenishedStores.includes(store)),
                  })}
            </Text>
          </View>
        </ConfirmationModal>
      )}
    </View>
  );
};
