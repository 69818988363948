/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, classNames } from '@az/starc-ui';

import styles from './PODetailsSection.module.scss';
import * as T from './PODetailsSection.types';

export const PODetailsSection = ({ rows }: T.DetailsSectionProps) => {
  return (
    <View direction="column" padding={[6, 0, 0, 0]} className={styles['po-details-section']}>
      {rows.map((row) => (
        <View.Item key={row.label}>
          <View
            className={styles['po-details-section__item']}
            direction="row"
            width="100%"
            key={row.label}
          >
            <View.Item grow columns={6}>
              <Text
                color="primary"
                size="087"
                weight="medium"
                className={classNames(styles['po-details-section__text'])}
              >
                {row.label}
              </Text>
            </View.Item>
            <View.Item grow columns={6}>
              {row.text && (
                <Text
                  color="primary"
                  size="087"
                  weight="regular"
                  className={styles['po-details-section__text']}
                >
                  {row.text}
                </Text>
              )}

              {row.children && row.children}
            </View.Item>
          </View>
        </View.Item>
      ))}
    </View>
  );
};
