/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Divider, Badge, Button, ActionDelete, Icon, Add } from '@az/starc-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stat } from '@shared/components/Stat/Stat';
import { TASK_USER_DATA } from '@taskManagement/constants/dataConstants';
import { UserStatus } from '@taskManagement/constants/constants';
import {
  ActiveTaskProps,
  OverviewProps,
} from '@taskManagement/components/AssignTaskDrawer/UserTabs/Overview/Overview.types';
import { MasterCard } from '@inbound/components/MasterCard/MasterCard';
import styles from './Overview.module.scss';

export const Overview = (userId: OverviewProps) => {
  const { t } = useTranslation();

  const [ActiveUserData, setActiveUserData] = useState<ActiveTaskProps>();
  const fetchActiveUserDetails = useCallback(() => {
    if (userId) {
      const UserActiveData = TASK_USER_DATA.find((user) => user.id === userId.userId);
      if (UserActiveData) {
        setActiveUserData(UserActiveData);
      }
    }
    return null;
  }, [userId, setActiveUserData]);

  useEffect(() => {
    if (userId) {
      fetchActiveUserDetails();
    }
  }, [fetchActiveUserDetails, userId]);

  return (
    <View gap={3} padding={6} className={styles['overview-content']}>
      <View direction="row" gap={2}>
        <View>
          <Stat
            title={t('OverviewUserTab.CompletionRate')}
            primaryText={t('OverviewUserTab.CompletionRateValue', {
              value: 97.6,
            })}
            secondaryTextProps={{ secondaryText: 'test' }}
          />
        </View>
        <View>
          <Stat
            title={t('OverviewUserTab.TransactionTime')}
            primaryText="3.25"
            secondaryTextProps={{ secondaryText: 'min ago' }}
          />
        </View>
        <View>
          <Stat
            title={t('OverviewUserTab.LoadedLabor')}
            primaryText={t('OverviewUserTab.LoadedLaborValue', { value: 6.04 })}
            secondaryTextProps={{ secondaryText: '30 sec' }}
          />
        </View>
        <View>
          <Stat
            title={t('OverviewUserTab.CurrentVehicle')}
            primaryText="FMD2"
            secondaryTextProps={{ secondaryText: '' }}
          />
        </View>
      </View>

      {ActiveUserData?.userStatus === t(UserStatus.NOT_ACTIVE) ? (
        <View direction="row" width="100%" align="center" justify="start">
          <Icon svg={ActionDelete} size={7} color="error" />
          <Text
            size="087"
            weight="bold"
            color="error"
            className={styles['overview-content__remove-text']}
          >
            {t('OverviewUserTab.RemoveUserFromShift')}
          </Text>
        </View>
      ) : (
        <View
          direction="row"
          width="100%"
          align="center"
          justify="start"
          attributes={{
            style: { cursor: 'not-allowed', opacity: '0.5' },
          }}
        >
          <Icon svg={ActionDelete} />
          <Text
            size="100"
            weight="bold"
            color="primary"
            className={styles['overview-content__remove-text_']}
          >
            {t('OverviewUserTab.RemoveUserFromShift')}
          </Text>
        </View>
      )}

      <Divider className={styles['overview-content__bottom-divider']} />

      <View gap={4} direction="row" justify="space-between">
        <Text size="100" weight="bold" color="primary">
          {t('OverviewUserTab.ActiveTask')}
        </Text>
        <Text size="100" weight="bold" color="primary" textCase="uppercase">
          <Badge
            variant="status"
            text={t('OverviewUserTab.AlmostDone')}
            variantOptions={{
              backgroundColor: 'blue-300',
              borderColor: 'blue-700',
              textColor: 'blue-700',
            }}
          />
        </Text>

        {ActiveUserData && ActiveUserData?.userStatus !== t(UserStatus.NOT_ACTIVE) && (
          <MasterCard
            title={ActiveUserData.vendorName}
            priority={ActiveUserData.priority}
            tagItems={ActiveUserData.tagItems}
            label={t('PoTask.Arrival', { date: ActiveUserData.arrivalDate })}
            detailsData={{
              data: [
                {
                  label: t('PoTask.PO', { poNumber: ActiveUserData.poNumber }),
                },
                {
                  label: t('PoTask.RC', { rcNumber: ActiveUserData.rcNumber }),
                },
                {
                  label: t('PoTask.Gate', { gateNumber: ActiveUserData.gate }),
                },
                {
                  label: t('PoTask.Aisle', { aisleNumber: ActiveUserData.aisle }),
                },
              ],
            }}
            {...(ActiveUserData?.users
              ? {
                  avatarGroupData: {
                    users:
                      ActiveUserData?.users && ActiveUserData?.users.length > 0
                        ? ActiveUserData?.users
                        : [],
                    size: 'small',
                    maxDisplay: 2,
                  },
                }
              : {})}
            {...(ActiveUserData?.piecesData && {
              piecesData: {
                count: ActiveUserData.piecesData?.value,
                total: ActiveUserData.piecesData?.max,
                label: ActiveUserData.piecesData?.label,
              },
            })}
            linesData={ActiveUserData?.linesData}
            isActionMenu={true}
          />
        )}
      </View>

      <Divider className={styles['overview-content__bottom-divider']} />

      <View gap={4} direction="row" justify="space-between">
        <Text size="100" weight="bold" color="primary">
          {t('OverviewUserTab.UpcomingTasks', { count: 0 })}
        </Text>
        <View.Item>
          <Button
            variant="pill"
            attributes={{ style: { width: 'fit-content' } }}
            startIcon={<Icon svg={Add} color="secondary" size={5} />}
          >
            {t('OverviewUserTab.AddTasks')}
          </Button>
        </View.Item>
      </View>
      <View gap={4}>
        <Text>{t('OverviewUserTab.NoTaskMessage')} </Text>
      </View>
    </View>
  );
};
